import React, { Component } from 'react';
import Slider from "react-slick";
import './client.scss';

import uaePass from '../../images/billers-logos/uaepass.png';
import aadcLogo from '../../images/billers-logos/aadc.png';
import playStationLogo from '../../images/billers-logos/911PlayStation.png';
import roblox from '../../images/billers-logos/roblox.png';
import Three360 from '../../images/billers-logos/360.png';
import tra from '../../images/billers-logos/tra.png';
import aLogo from '../../images/billers-logos/a.png';
import bLogo from '../../images/billers-logos/b.png';
import steam from '../../images/billers-logos/steam.png';
import airarabia from '../../images/billers-logos/airarabia.png';
import ajmanPay from '../../images/billers-logos/ajman-pay.png';
import amazonLogo from '../../images/billers-logos/amazon-logo-png.png';
import appleMusic from '../../images/billers-logos/apple-music.png';
import awqaf from '../../images/billers-logos/awqaf.png';
import beit from '../../images/billers-logos/beit.png';
import leageOfLegends from '../../images/billers-logos/leageOfLegends.png';
import dcCash from '../../images/billers-logos/dcCash.png';
import du from '../../images/billers-logos/du.png';
import dubaiPolice from '../../images/billers-logos/dubai-poilice-logo.png';
import eWallet from '../../images/billers-logos/e-wallet.png';
import eDirhar from '../../images/billers-logos/edirham.jpg';
import cLogo from '../../images/billers-logos/c.png';
import dLogo from '../../images/billers-logos/d.png';
import fiveMobile from '../../images/billers-logos/five-mobile.png';
import flyDubai from '../../images/billers-logos/fly-dubai.png';
import freeFire from '../../images/billers-logos/free-fire-logo.png';
import googlePlay from '../../images/billers-logos/google-play.png';
import eLogo from '../../images/billers-logos/e.png';
import imvuLogo from '../../images/billers-logos/imvu.png';
import ista from '../../images/billers-logos/ista.png';
import iTunes  from '../../images/billers-logos/itunes.png';
import mobileLegends  from '../../images/billers-logos/mobileLegends.png';
import fLogo  from '../../images/billers-logos/f.png';
import loothLogo  from '../../images/billers-logos/lootah.png';
import gLogo  from '../../images/billers-logos/g.png';
import hLogo  from '../../images/billers-logos/h.png';
import iLogo  from '../../images/billers-logos/i.png';
import mcafee  from '../../images/billers-logos/mcafee.png';
import miniCraft  from '../../images/billers-logos/minecraft.png';
import nbcLogo  from '../../images/billers-logos/nbc.png';
import netFlix  from '../../images/billers-logos/netflix.png';
import jLogo  from '../../images/billers-logos/j.png';
import noiLogo  from '../../images/billers-logos/nol.png';
import kLogo  from '../../images/billers-logos/k.png';
import ppdLogo  from '../../images/billers-logos/ppd.png';
import pubgLogo  from '../../images/billers-logos/pubg.png';
import rakLogo  from '../../images/billers-logos/rak.png';
import lLogo  from '../../images/billers-logos/l.png';
import mLogo  from '../../images/billers-logos/m.png';
import salik  from '../../images/billers-logos/salik.png';
import nLogo  from '../../images/billers-logos/n.png';
import oLogo  from '../../images/billers-logos/o.png';
import spotify  from '../../images/billers-logos/spotiry.png';
import pLogo  from '../../images/billers-logos/p.png';
import qLogo  from '../../images/billers-logos/q.png';
import rLogo  from '../../images/billers-logos/r.png';
import vipLogo  from '../../images/billers-logos/vip.png';
import xbox  from '../../images/billers-logos/xbox.png';

import bein  from '../../images/billers-logos/bein.png';
import bigo  from '../../images/billers-logos/bigo.png';
import crunchyroll  from '../../images/billers-logos/crunchyroll.png';
import ded  from '../../images/billers-logos/DED.png';
import hafilat  from '../../images/billers-logos/hafilat.png';
import likee  from '../../images/billers-logos/likee.png';
import mahzooz  from '../../images/billers-logos/mahzooz.png';
import sewa  from '../../images/billers-logos/SEWA.png';
import startplay  from '../../images/billers-logos/starzplay.png';
import stoon  from '../../images/billers-logos/stoon-go.png';
import tango  from '../../images/billers-logos/tango.png';
import vip  from '../../images/billers-logos/vip-baloot.png';
import yalla  from '../../images/billers-logos/yalla-live.png';


export default class ClientSlider extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}


	render() {
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 7,
			slidesToScroll: 1,
			autoplay: true,
			speed: 2000,
			autoplaySpeed: 2000,
			cssEase: "linear",
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 7,
						slidesToScroll: 1,
						infinite: true,
						dots: false
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						initialSlide: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		};
		return (
			<div style={{marginTop: '50px'}}>
				{/* <div className="d-flex justify-content-between align-items-center hidd-xs">
					<span className="slickbtn prev" onClick={this.previous}>
						<img src={prev_arrow} alt="prev"/>
					</span>
					<span className="slickbtn next" onClick={this.next}>
						<img src={next_arrow} alt="next"/>
					</span>
				</div> */}
				<Slider ref={c => (this.slider = c)} {...settings}>

					<div className="mbme_clients">
						<img alt="client1" src={aadcLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={playStationLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={roblox} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={uaePass} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client3" src={Three360} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client1" src={tra} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={aLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={bLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client3" src={steam} className="client-image" />
					</div>


					<div className="mbme_clients">
						<img alt="client1" src={airarabia} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={ajmanPay} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client3" src={amazonLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={appleMusic} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client6" src={awqaf} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client1" src={beit} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={leageOfLegends} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={dcCash} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={du} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={dubaiPolice} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={eWallet} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client1" src={eDirhar} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client2" src={cLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={dLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client4" src={fiveMobile} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={flyDubai} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={freeFire} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={googlePlay} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={eLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={imvuLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={ista} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={iTunes} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={mobileLegends} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={fLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={loothLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={gLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={hLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={iLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={mcafee} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={miniCraft} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={nbcLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={netFlix} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={jLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={noiLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={kLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={ppdLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={pubgLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={rakLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={lLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={mLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={salik} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={nLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={oLogo} className="client-image" />
					</div>

					<div className="mbme_clients">
						<img alt="client5" src={spotify} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={pLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={qLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={rLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={vipLogo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={xbox} className="client-image" />
					</div>
					
					<div className="mbme_clients">
						<img alt="client5" src={bein} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={bigo} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={crunchyroll} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={ded} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={hafilat} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={likee} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={mahzooz} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={sewa} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={startplay} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={stoon} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={tango} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={vip} className="client-image" />
					</div>
					<div className="mbme_clients">
						<img alt="client5" src={yalla} className="client-image" />
					</div>


				</Slider>
			</div>
		);
	}
}






