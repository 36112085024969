import React, { Component } from "react";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./client.scss";

import uaePass from "../../images/billers-logos/uaepass.png";
import pyyplLogo from "../../images/billers-logos/pyypl.png";
import aadcLogo from "../../images/billers-logos/aadc.png";
import amrLogo from "../../images/billers-logos/amr.png";
import addcLogo from "../../images/billers-logos/addc.png";
import playStationLogo from "../../images/billers-logos/911PlayStation.png";
import roblox from "../../images/billers-logos/roblox.png";
import Three360 from "../../images/billers-logos/360.png";
import tra from "../../images/billers-logos/tra.png";
import aLogo from "../../images/billers-logos/a.png";
import bLogo from "../../images/billers-logos/b.png";
import steam from "../../images/billers-logos/steam.png";
import airarabia from "../../images/billers-logos/airarabia.png";
import ajmanPay from "../../images/billers-logos/ajman-pay.png";
import amazonLogo from "../../images/billers-logos/amazon-logo-png.png";
import appleMusic from "../../images/billers-logos/apple-music.png";
import awqaf from "../../images/billers-logos/awqaf.png";
import beit from "../../images/billers-logos/beit.png";
import leageOfLegends from "../../images/billers-logos/leageOfLegends.png";
import dcCash from "../../images/billers-logos/dcCash.png";
import du from "../../images/billers-logos/du.png";
import dubaiPolice from "../../images/billers-logos/dubai-poilice-logo.png";
import eWallet from "../../images/billers-logos/e-wallet.png";
import eDirhar from "../../images/billers-logos/edirham.png";
import cLogo from "../../images/billers-logos/c.png";
import dLogo from "../../images/billers-logos/d.png";
import fiveMobile from "../../images/billers-logos/five-mobile.png";
import flyDubai from "../../images/billers-logos/fly-dubai.png";
import freeFire from "../../images/billers-logos/free-fire-logo.png";
import googlePlay from "../../images/billers-logos/google-play.png";
import eLogo from "../../images/billers-logos/e.png";
import imvuLogo from "../../images/billers-logos/imvu.png";
import ista from "../../images/billers-logos/ista.png";
import iTunes from "../../images/billers-logos/itunes.png";
import mobileLegends from "../../images/billers-logos/mobileLegends.png";
import fLogo from "../../images/billers-logos/f.png";
import loothLogo from "../../images/billers-logos/lootah.png";
import gLogo from "../../images/billers-logos/g.png";
import hLogo from "../../images/billers-logos/h.png";
import iLogo from "../../images/billers-logos/i.png";
import mcafee from "../../images/billers-logos/mcafee.png";
import miniCraft from "../../images/billers-logos/minecraft.png";
import nbcLogo from "../../images/billers-logos/nbc.png";
import netFlix from "../../images/billers-logos/netflix.png";
import jLogo from "../../images/billers-logos/j.png";
import noiLogo from "../../images/billers-logos/nol.png";
import kLogo from "../../images/billers-logos/k.png";
import ppdLogo from "../../images/billers-logos/ppd.png";
import pubgLogo from "../../images/billers-logos/pubg.png";
import rakLogo from "../../images/billers-logos/rak.png";
import lLogo from "../../images/billers-logos/l.png";
import mLogo from "../../images/billers-logos/m.png";
import salik from "../../images/billers-logos/salik.png";
import nLogo from "../../images/billers-logos/n.png";
import oLogo from "../../images/billers-logos/o.png";
import spotify from "../../images/billers-logos/spotiry.png";
import pLogo from "../../images/billers-logos/p.png";
import qLogo from "../../images/billers-logos/q.png";
import rLogo from "../../images/billers-logos/r.png";
import vipLogo from "../../images/billers-logos/vip.png";
import xbox from "../../images/billers-logos/xbox.png";
import helloLogo from "../../images/billers-logos/hello.png";

import bein from "../../images/billers-logos/bein.png";
import bigo from "../../images/billers-logos/bigo.png";
import crunchyroll from "../../images/billers-logos/crunchyroll.png";
import ded from "../../images/billers-logos/DED.png";
import hafilat from "../../images/billers-logos/hafilat.png";
import likee from "../../images/billers-logos/likee.png";
import mahzooz from "../../images/billers-logos/mahzooz.png";
import sewa from "../../images/billers-logos/SEWA.png";
import startplay from "../../images/billers-logos/starzplay.png";
import stoon from "../../images/billers-logos/stoon-go.png";
import tango from "../../images/billers-logos/tango.png";
import vip from "../../images/billers-logos/vip-baloot.png";
import yalla from "../../images/billers-logos/yalla-live.png";

import mawaqif from "../../images/billers-logos/mawaqif.png";
import nationalBonds from "../../images/billers-logos/national-bonds.png";
import etisalat from "../../images/billers-logos/etisalat.png";
import darb from "../../images/billers-logos/darb.png";
import ewe from "../../images/billers-logos/ewe.png";
import sergas from "../../images/billers-logos/sergas.png";
import rakProperties from "../../images/billers-logos/rak-properties.png";
import eNMoney from "../../images/billers-logos/e&money.png";
import interntlTopup from "../../images/billers-logos/intl-top-up.png";
import millionaireAisle from "../../images/billers-logos/millionaire-aisle.png";
import dreamIsland from "../../images/billers-logos/dream-island.png";
import paysafe from "../../images/billers-logos/paysafe.png";

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.timer = null;
    this.state = {
      selectedIndex: 0,
    };
  }
  componentDidUpdate (prevProps, prevState) {
    if( this.timer ) clearTimeout(this.timer)
    if(this.state.selectedIndex ===1){
      this.timer = setTimeout(() => {
        this.goToTabFunc(2);
        },3000);
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  selectTab = (index) => {
    this.setState({ selectedIndex: index });
  };

  goToTabFunc = (goToTab) => {
    this.setState({ selectedIndex: goToTab });
  };

  showGreeting() {
    this.timer = setTimeout( this.goToTabFunc(2), 3000); 
 }
 
  render() {
    const govtSliderSettings = {
      dots: false,
      infinite: true,
      //slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 0,
      cssEase: "linear",
      variableWidth: true,
      afterChange: (current) => {
        if (current === 13) {
          this.goToTabFunc(1);
        }
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };

    const semiGovtSliderSettings = {
      dots: false,
      infinite: false,
      slidesToScroll: true,
      slidesToShow: 2,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1,
      cssEase: "linear",
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    const otherSliderSettings = {
      infinite: true,
      //slidesToShow: 7,
      //slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 0,
      cssEase: "linear",
      variableWidth: true,
      afterChange: (current) => {
        if (current === 30) {
          this.goToTabFunc(0);
        }
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 15,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          //breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
      ],
    };

    return (
      <div style={{ marginTop: "50px" }}>
        <Tabs
          selectedIndex={this.state.selectedIndex}
          onSelect={this.selectTab}
        >
          <TabList>
            <Tab>Government</Tab>
            <Tab>Semi Government</Tab>
            <Tab>Others</Tab>
          </TabList>
          <TabPanel>
            <Slider ref={(c) => (this.slider = c)} {...govtSliderSettings}>
              <div className="mbme_clients">
                <img alt="client1" src={aadcLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={addcLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={ajmanPay} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client6" src={awqaf} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={darb} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={ded} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={dubaiPolice} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={eDirhar} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={etisalat } className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={ewe } className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={hafilat} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={mawaqif} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={nationalBonds} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={noiLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={salik} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={sewa} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={tra} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={rakLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={uaePass} className="client-image" />
              </div>
            </Slider>
          </TabPanel>
          <TabPanel>
            <Slider  className="semi-govt-slider" ref={(c) => (this.slider = c)} {...semiGovtSliderSettings}>
            <div className="mbme_clients">
                <img alt="client1" src={du} className="du-image" style={{width:"100px", height:"74px"}} />
              </div>
              <div className="mbme_clients">
                <img alt="client2" src={addcLogo} className="addc-image"  style={{width:"100px", height:"74px"}}/>
              </div>
            </Slider>
          </TabPanel>
          <TabPanel>
            <Slider ref={(c) => (this.slider = c)} {...otherSliderSettings}>
           
            <div className="mbme_clients">
                <img alt="client1" src={sergas} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={rakProperties} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={eNMoney} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={interntlTopup  } className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={millionaireAisle  } className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={dreamIsland} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={paysafe} className="client-image" />
              </div>




              <div className="mbme_clients">
                <img alt="client1" src={airarabia} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client3" src={amazonLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={appleMusic} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client1" src={beit} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client4" src={fiveMobile} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={flyDubai} className="client-image" />
              </div>
              
              <div className="mbme_clients">
                <img alt="client1" src={helloLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={ista} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={iTunes} className="client-image" />
              </div>
              
              <div className="mbme_clients">
                <img alt="client5" src={loothLogo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={mahzooz} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={pyyplLogo} className="client-image" />
              </div>

              

             

              
           

           

              <div className="mbme_clients">
                <img alt="client5" src={xbox} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={pubgLogo} className="client-image" />
              </div>

             

              <div className="mbme_clients">
                <img alt="client5" src={googlePlay} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={spotify} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={freeFire} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={imvuLogo} className="client-image" />
              </div>


              <div className="mbme_clients">
                <img
                  alt="client1"
                  src={leageOfLegends}
                  className="client-image"
                />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={steam} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={startplay} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img
                  alt="client5"
                  src={mobileLegends}
                  className="client-image"
                />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={miniCraft} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={roblox} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={mcafee} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={likee} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={tango} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={bein} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={crunchyroll} className="client-image" />
              </div>

              <div className="mbme_clients">
                <img alt="client5" src={bigo} className="client-image" />
              </div>
              <div className="mbme_clients">
                <img alt="client5" src={stoon} className="client-image" />
              </div>
            </Slider>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}
