import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Button } from 'react-bootstrap';
import {mob_banner} from '../../images/index';
import './home.scss';

class Banner extends Component {
  render() {
    return (
      <Row>
        <div className="mob-banner hidd-larg">
          <img src={mob_banner} alt="mob-banner"/>
        </div>
        <Col md={5} xs={12} className="lf-blk">
          <h4 data-aos="fade-right" data-aos-duration="800">
          <p>Secure Payments <br/> for a  Secure Lifestyle</p>
          </h4>
          <p className="content" data-aos="fade-left" data-aos-duration="800">Protect every transaction with our anti-fraud system and enjoy convenient payments with the largest services provider in the UAE and the region</p>
          <Button type="submit" className="mbme-btn button-big" onClick={event => window.open('https://mbmepay.com/', '_blank')}>Let's Do it</Button> 
          {/* <NavLink  to="/coming-soon" ><Button type="button" className="mbme-btn button-big" >Let's Do it </Button></NavLink> */}
        </Col>
        <Col md={7} xs={12} className="rg-blk position-relative">
        </Col>
      </Row>
    );
  }
}

export default Banner;
