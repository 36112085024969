import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Image } from 'react-bootstrap';
import { hand, dottedrepeat } from '../../images/index';
import './home.scss';

class Partner extends Component {
    render() {
        return (
            <div>
                <Row>
                    <h5 className="space-letter" data-aos="fade-down" data-aos-delay="1500" data-aos-duration="1000">Join us</h5>
                </Row>

                <Row className="align-items-center">
                    <Col md={5} xs={12} className="no-padd">
                        <h4 className="my-4 " data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1000">
                            <p> Want to become a Partner with MBME Pay?  </p>
                        </h4>
                        <p className="content" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1000">Become a part of the largest payment services provider and bill aggregator in the UAE and the region. Partner with MBME Pay today! </p>
                    </Col>
                    <Col md={5} xs={12} className="no-padd ml-4 butt-blk" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1000">
                        <div className="d-flex align-items-center flex-column bg-repeat">
                            <div className="ech-part-btn my-3">

                                <NavLink to="/partners">
                                    <span className="change-link">
                                        Become a Partner
                                        <Image src={hand} alt="hand" fluid className="ml-3" />
                                    </span>
                                </NavLink>

                            </div>

                            <div className="ech-part-btn my-3">
                                <NavLink to="/retailers">
                                    <span className="change-link">
                                        Become a Retailer
                                    </span>
                                </NavLink>
                            </div>
                            <Image src={dottedrepeat} alt="dottedrepeat" className="dottedimg" fluid />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Partner;
