import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { interactive_1, invoice_1, speech_bubble_1, megaphone_1 } from '../../../images/index';
import '../retailer.scss';

class Support extends Component {
  
  render() {
    return (
      <div className="tabData self-service">
        <div className="each-block">
          <div className="content mb-5"> Running a MBME Pay marketing campaign can help increase activity on kiosks. It is important to let your customers know about the payment services options you provide and the benefits. </div>
          <h4 className="head-medium-normal">WHAT HAS WORKED?</h4>
          <div className="content"> MBME Pay runs regular marketing campaigns with businesses and this has provided insights into what campaigns work best. Here are our recommendations for running a best practice MBME Pay marketing campaign. </div>
          <Row className="mt-4 data-card">
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={interactive_1} fluid alt="interactive_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Multi-channel approach</h4>
                  <p className="content m-0">There are many channels of communication that can be utilised to reach your customer base, and many of these are already managed by your businesses such as emails, website, flyers inserted with your bill. By covering multiple channels, you are better able to reach your customers and gain traction.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={invoice_1} fluid alt="invoice_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Use your bills</h4>
                  <p className="content m-0">Marketing with your bills, whether they are electronic or paper will have the best results. Payments are generally a low involvement category for your customers, so receiving a message within or with the bill is more likely to elicit a response.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={speech_bubble_1} fluid alt="speech_bubble_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Personalized Communications</h4>
                  <p className="content m-0">Where possible it is always best to take the existing MBME Pay material and add small personalized elements, such as including your company logo and your customer’s details. You can do this by downloading the customizable versions of the MBME Pay material and having your design team add these elements.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={megaphone_1} fluid alt="megaphone_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Multiple Campaigns</h4>
                  <p className="content m-0">We suggest that if possible you should look to run multiple campaigns to ensure that your customers have clearly seen the messages and are reminded to act upon them.</p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="data-card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block my-3">
              <h4 className="head-medium-normal">MBME Pay MATERIAL AVAILABLE</h4>
              <p className="content"> MBME Pay provides marketing materials for all of the MBME Pay services for you to download to run your own campaign. The key to a successful marketing campaign is to use a multichan nel approach, therefore MBME Pay provides specific material for various channels. </p>
              <p className="content">
                Marketing Materials MBME Pay provides:
              </p>
              <div className="styled-ul kiosk-features">
                <span className="content dot my-2"> Flyers </span>
                <span className="content dot my-2"> Posters </span>
                <span className="content dot my-2"> Web banners </span>
                <span className="content dot my-2"> Statement messaging </span>
                <span className="content dot my-2"> Social media assets </span>
                <span className="content dot my-2"> Newsletter assets </span>
              </div>
            </Col>
          </Row>

          <Row className="data-card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block">
              <h4 className="head-medium-normal">TERMS & CONDITIONS</h4>
              <div className="styled-ul kiosk-features">
                <span className="content dot my-2"> To utilize our materials email us at marketing@mbmepay.ae and request for the material you would like to download. </span>
                <span className="content dot my-2"> The items marked as Print Ready – can be given straight to your printer for distribution or used directly on your website. </span>
                <span className="content dot my-2"> The items marked as Customizable – are able to be edited by a graphic designer to include customizations such as including your business name and logo. The text in cased in the can be altered. Please note that if any material is edited it will need to be sent to the MBME Pay marketing department for approval before distribution marketing@mbmepay.ae </span>
                <span className="content dot my-2"> When you request for material you would like to download you will be asked for contact details. These details are only used in order to keep track of who is using MBME Pay marketing material and to provide information around any relevant upcoming campaign activity. Please note that your business will be responsible for the printing of the artwork supplied by MBME Pay. </span>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default Support;
