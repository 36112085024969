import React, { useState } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { uaePassImg, case_2 } from '../../../images/index';
import '../retailer.scss';

import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="card-header" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

const CaseStudy = () => {
  const [activeKey, setActiveKey] = useState(1);

  const data = [
    { header: "", content: 0 }, //Don't write your first accordian here
    { header: "What is a smart kiosk?", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What are the benefits of having an MBME Pay kiosk?", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "Do you manufacture the kiosks?", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What benefits do you offer telecom operators? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What are the benefits of having an MBME Pay kiosk? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "Do you have a minimum order for a kiosk? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What is the lead time for a kiosk to be installed at my location? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What if I want a customised look and specifications, different from your standard kiosks? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "How can I make my kiosks look unique? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What warranty do you provide for the kiosk? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "What component can I get in my kiosk? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "Does MBME Pay provide software? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
    { header: "I have my own software; can I get it installed on the kiosk? ", content: "An smart kiosk is a computer terminal that provides people with an interactive self-service access to products and services. Kiosks are typically placed in retail stores, groceries, shopping malls, airports, hospitals and corporate spaces." },
  ];
  return (
    <div className="tabData self-service">
      <Container className="each-block p-0">
        <h4 className="head-medium-normal">Partnerships</h4>
        <Row className="mt-4 data-card">
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <Image src={uaePassImg} fluid alt="case one" />
          </Col>
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
          <h4 className="head-medium-normal m-0 mb-3">UAE PASS | MBME Pay partners with SDG to launch a unified <br /> payment platform offered to residents of the UAE</h4>
          <p className="content"> UAE PASS, which provides each person with unified identity numbers, can be used to facilitate the electronical transactions through all government federal and local portals. The majority of online government e-services require an individual login and password, UAE PASS revolutionizes the industry by making it a one stop platform for all government services. Utilizing just one username and password access to all related government e-services are available with ease. MBME Pay users can create and access their UAE PASS accounts with simple and quick registration with the UAE PASS application, accessible at more than 300 kiosks across UAE, fully equipped with EID card reader and biometric reader. </p>
          </Col>
        </Row>
        <Row className="mt-4 data-card">
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <Image src={case_2} fluid alt="case one" />
          </Col>
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            <h4 className="head-medium-normal m-0 mb-3">Ferrari World | MBME Pay partners with Ferrari World to <br />bring fully customized kiosk for entry into the venue.</h4>
            <p className="content"> Ferrari World in Abu Dhabi located on Yas Island, is the first Ferrari-branded theme park in the world and has the record for the largest space frame structure ever built, as well as the world’s fastest roller coaster. </p>
            <p className="content"> The park was founded in 2007, and was officially opened in 2010. In 2015, the park was named “Middle-East’s Leading Tourist Attraction”.</p>
            <p className="content"> MBME Pay offers Ferrari world visitors: • Fully customized kiosks that are conveniently located to allow access in and out of the park. • Hardware systems that are supported by Best Union’s Software. </p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-3 mt-5">
            <h4 className="head-medium-normal m-0">FREQUENTLY ASKED QUESTIONS</h4>
            <p className="content">Our team's always here to talk it out, just reach out by phone or email</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="case-accordian">
            <Accordion defaultActiveKey={1} activeKey={activeKey}>
              {data.map((item, index) => (

                <Card key={index} className={activeKey === index ? 'active' : ''}>
                  <CustomToggle
                    as={Card.Header}
                    eventKey={index}
                    handleClick={() => {
                      if (activeKey === index) {
                        setActiveKey(null);
                      } else {
                        setActiveKey(index);
                      }
                    }}
                  >
                    <span className="accord-head m-0">
                      {item.header}
                    </span>
                    <div className="icon">
                      {activeKey === index ? "-" : "+"}
                    </div>

                  </CustomToggle>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>{item.content}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
      {/* <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="position-relative mt-5">
          <div className="service-reg-block">
            <Container>
              <div className="butt-block">
                <h3 className="my-4">Sign up to be a Partner</h3>
                <Button type="submit" className="mbme-menu-btn">Register</Button>
              </div>
            </Container>
          </div>
        </Col>
      </Row> */}

    </div>
  );
};
export default CaseStudy;
