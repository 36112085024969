import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { platform, experience, portfolio, left_line, right_line, machinepic, machinepic_two, machine_three, ultraWhiteKiosk, KioskCompactWht, KioskAmericanoWhite } from '../../../images/index';
import '../solution.scss';
import '../kiosk.scss';

class Kiosk extends Component {
    render() {
        return (
            <div className="kiosk-wrapper">
                <Container className="kiosk">
                    <Row>
                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="padd-top-3">
                                <Col md={12} className="text-center">
                                    <h4 className="head-medium-block">MBME Pay ULTRA</h4>
                                    <span className="content"> All that you need </span>
                                </Col>
                            </Row>
                            <Row className="ultra padd-top-3">
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={experience} fluid /></div>
                                    <h4 className="p-bold"> 13 Years UAE Experience </h4>
                                    <span className="content"> We have the proven technical knowledge and expertise in the self-service and smart automation industry to create innovative kiosk solutions. </span>
                                </Col>
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={platform} fluid /> </div>
                                    <h4 className="p-bold"> Full Service Platform </h4>
                                    <span className="content"> Get access to complete smart kiosk solutions with state-of-the-art software taking you where you’ve never thought possible. </span>
                                </Col>
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={portfolio} fluid /> </div>
                                    <h4 className="p-bold"> Largest Payment Portfolio </h4>
                                    <span className="content"> We have ready-to-run, smart self-service solutions for a diverse range of business sectors.</span>
                                </Col>
                            </Row>
                            <Row className="padd-top-3">
                                <Col md={12} xs={12} className="position-relative">
                                    <div className="mach-block text-center">
                                        <Image src={ultraWhiteKiosk} fluid />
                                    </div>
                                    <div className="field-mention">
                                        
                                        <div className="item-two">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Alarms </h5>
                                                <p className="content m-0"> Tilt & Door Alarm System</p>
                                            </div>
                                        </div>
                                        <div className="item-three">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Reciept Printer </h5>
                                                <p className="content m-0">Printer Model Ithaca 950</p>
                                            </div>
                                        </div>
                                        <div className="item-four">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Safe </h5>
                                                <p className="content m-0"> Independent Locks Ensure Cash is Always Safe </p>
                                            </div>
                                        </div>

                                        <div className="item-five">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Media Player </h5>
                                                <p className="content m-0"> A Dedicated Media Player for Your Convenience</p>
                                            </div>
                                        </div>
                                        <div className="item-six">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Touch Screen </h5>
                                                <p className="content m-0"> 17” LCD Color Touch Screen</p>
                                            </div>
                                        </div>
                                        <div className="item-seven">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Bank Note Acceptor</h5>
                                                <p className="content m-0"> Accepts and Dispenses 5, 10, 20, 50, 100, 200, 500, and 1000</p>
                                            </div>
                                        </div>
                                        <div className="item-eight">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Router </h5>
                                                <p className="content m-0"> Includes an Industrial Router </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <Row className="field-mention justify-content-between">
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> Chip & Pin </h5>
                                                <p className="content m-0"> D200 Wireless POS Terminal </p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> Information Monitor </h5>
                                                <p className="content m-0"> 15” Upper Screen </p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> Industrialized PC </h5>
                                                <p className="content m-0"> Built-in Lenovo Small Form Factor PC </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>


                <div className="kiosk-two">
                    <Container>
                        <Row>
                            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <h4 className="head-medium-block">MBME Pay AMERICANO</h4>
                                        <span className="content"> All that you need </span>
                                    </Col>
                                </Row>
                                <Row className="ultra padd-top-3">
                                    <Col md={4} xs={12} className="text-center">
                                        <div className="wrap-img"><Image src={experience} fluid /> </div>
                                        <h4 className="p-bold"> 13 Years of UAE Experience </h4>
                                        <span className="content"> We have the proven technical knowledge and expertise in the self-service and smart automation industry to create innovative kiosk solutions. </span>
                                    </Col>
                                    <Col md={4} xs={12} className="text-center">
                                        <div className="wrap-img"><Image src={platform} fluid /> </div>
                                        <h4 className="p-bold"> Full Service Platform </h4>
                                        <span className="content">Get access to complete smart kiosk solutions with state-of-the-art software taking you where you’ve never thought possible.</span>
                                    </Col>
                                    <Col md={4} xs={12} className="text-center">
                                        <div className="wrap-img"><Image src={portfolio} fluid /></div>
                                        <h4 className="p-bold"> Largest Payment Portfolio </h4>
                                        <span className="content"> We have ready-to-run, smart self-service solutions for a diverse range of business sectors.</span>
                                    </Col>
                                </Row>
                                <Row className="padd-top-3">
                                    <Col md={12} xs={12} className="position-relative">
                                        <div className="mach-block text-center">
                                            <Image src={KioskAmericanoWhite} fluid />
                                        </div>
                                        <div className="field-mention">
                                            <div className="item-two">
                                                <Image src={left_line} fluid />
                                                <div className="card-item">
                                                    <h5> Alarms </h5>
                                                    <p className="content m-0">Tilt & Door Alarm System</p>
                                                </div>
                                            </div>
                                            <div className="item-three">
                                                <Image src={left_line} fluid />
                                                <div className="card-item">
                                                    <h5> Reciept Printer </h5>
                                                    <p className="content m-0"> Printer Model Ithaca 950</p>
                                                </div>
                                            </div>
                                            <div className="item-four">
                                                <Image src={left_line} fluid />
                                                <div className="card-item">
                                                    <h5> Safe </h5>
                                                    <p className="content m-0"> Independent Locks Ensure Cash is Always Safe </p>
                                                </div>
                                            </div>

                                            <div className="item-five">
                                                <Image src={right_line} fluid />
                                                <div className="card-item">
                                                    <h5> Media Player</h5>
                                                    <p className="content m-0"> A Dedicated Media Player for Your Convenience </p>
                                                </div>
                                            </div>
                                            <div className="item-six">
                                                <Image src={right_line} fluid />
                                                <div className="card-item">
                                                    <h5> Touch Screen </h5>
                                                    <p className="content m-0"> 19” LCD Color Touch Screen</p>
                                                </div>
                                            </div>
                                            <div className="item-seven">
                                                <Image src={right_line} fluid />
                                                <div className="card-item">
                                                    <h5> Bank Note Acceptor </h5>
                                                    <p className="content m-0"> Accepts & Dispenses 5, 10, 20, 50, 100, 200, 500, and 1000</p>
                                                </div>
                                            </div>
                                            <div className="item-eight">
                                                <Image src={right_line} fluid />
                                                <div className="card-item">
                                                    <h5> Router </h5>
                                                    <p className="content m-0"> Includes an Industrial Router </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={{ span: 8, offset: 2 }}>
                                        <Row className="field-mention justify-content-between mt-3">
                                            <Col md={3} xs={12}>
                                                <div className="card-item">
                                                    <h5> Chip & Pin </h5>
                                                    <p className="content m-0"> D200 Wireless POS Terminal </p>
                                                </div>
                                            </Col>
                                            <Col md={3} xs={12}>
                                                <div className="card-item">
                                                    <h5> Information Monitor </h5>
                                                    <p className="content m-0"> 19” or 22” Upper Screen  </p>
                                                </div>
                                            </Col>
                                            <Col md={3} xs={12}>
                                                <div className="card-item">
                                                    <h5> Industrialized PC </h5>
                                                    <p className="content m-0">Built-in Lenovo Small Form Factor PC </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="kiosk-three">
                    <Row>
                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                            <Row className="padd-top-3">
                                <Col md={12} className="text-center">
                                    <h4 className="head-medium-block">MBME Pay COMPACT</h4>
                                    <span className="content"> All that you need </span>
                                </Col>
                            </Row>
                            <Row className="ultra padd-top-3">
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={experience} fluid /></div>
                                    <h4 className="p-bold"> 13 Years of UAE Experience </h4>
                                    <span className="content"> We have the proven technical knowledge and expertise in the self-service and smart automation industry to create innovative kiosk solutions. </span>
                                </Col>
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={platform} fluid /></div>
                                    <h4 className="p-bold"> Full Service Platform </h4>
                                    <span className="content"> Get access to complete smart kiosk solutions with state-of-the-art software taking you where you’ve never thought possible. </span>
                                </Col>
                                <Col md={4} xs={12} className="text-center">
                                    <div className="wrap-img"><Image src={portfolio} fluid /></div>
                                    <h4 className="p-bold"> Largest Payment Portfolio </h4>
                                    <span className="content"> We have ready-to-run, smart self-service solutions for a diverse range of business sectors. </span>
                                </Col>
                            </Row>
                            <Row className="padd-top-3">
                                <Col md={12} xs={12} className="position-relative">
                                    <div className="mach-block text-center padd-top-3">
                                        <Image src={KioskCompactWht} fluid />
                                    </div>
                                    <div className="field-mention">
                                        <div className="item-one">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Touch Screen</h5>
                                                <p className="content m-0">19’ LCD Color Touch Screen</p>
                                            </div>
                                        </div>

                                        <div className="item-three">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Alarms </h5>
                                                <p className="content m-0"> Tilt & Door Alarm System </p>
                                            </div>
                                        </div>
                                        <div className="item-four">
                                            <Image src={left_line} fluid />
                                            <div className="card-item">
                                                <h5> Bank Note Acceptor</h5>
                                                <p className="content m-0">Accepts & Dispenses 5, 10, 20, 50, 100, 200, 500, and 1000</p>

                                            </div>
                                        </div>

                                        <div className="item-five">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Industrialized PC </h5>
                                                <p className="content m-0">Built-in Lenovo Small Form Factor PC</p>
                                            </div>
                                        </div>

                                        <div className="item-seven">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Chip & Pin </h5>
                                                <p className="content m-0"> D200 Wireless POS Terminal </p>
                                            </div>
                                        </div>
                                        <div className="item-eight">
                                            <Image src={right_line} fluid />
                                            <div className="card-item">
                                                <h5> Reciept Printer </h5>
                                                <p className="content m-0">Printer Model Ithaca 950 </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={{ span: 8, offset: 2 }} className="mt-5">
                                    <Row className="field-mention justify-content-between mt-2">
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> SAFE </h5>
                                                <p className="content m-0"> Independent Locks Ensure Cash is Always Safe </p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> Information Monitor </h5>
                                                <p className="content m-0"> 15” Upper Screen </p>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={12}>
                                            <div className="card-item">
                                                <h5> Router </h5>
                                                <p className="content m-0"> Includes an Industrial Router </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Kiosk;