import React, { Component } from 'react';
import { Container, Form, Col, Row } from 'react-bootstrap';
import './admin.scss';
import API from '../../api';

class AdminLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: false,
      buttonDisable: true
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "* Username address Cannot be empty";
    }

    if (typeof fields["username"] !== "undefined") {
      let lastAtPos = fields["username"].lastIndexOf('@');
      let lastDotPos = fields["username"].lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["username"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["username"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["username"] = "* Email is not valid";
      }
    }
    /* Password Validation */
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "* Password Cannot be empty";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  loginSubmit(e) {
    e.preventDefault();
    this.setState({ loader: true });
    if (this.handleValidation()) {
      API.post(`/admin/authenticate`, this.state.fields)
        .then(res => {
          localStorage.setItem('usertoken', res.data.data.token);
          this.resetForm();
          this.props.history.push("/admin/contact");
        }, (error) => {
          if (error.response) {
            console.log('error ' + error.response.data.message);
            this.setState({
              message: 'show',
              responseMessage: error.response.data.message,
              popup: 'error',
              loader: false
            });
          }
          else {
            this.setState({
              message: 'show',
              responseMessage: 'Something went wrong. Please try again',
              popup: 'error',
              loader: false
            });
          }
        });
    }
  }

  resetForm = () => {
    this.refs.username.value = '';
    this.refs.password.value = '';
    this.setState({
      fields: {},
    })
  }


  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
      buttonDisable: false
    });
  }

  render() {
    return (
      <div>
        <div className={"loader" + this.state.loader}>
          <div className="loader-show"></div>
        </div>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} sm={12} xs={12} >
              <div className="form-contact my-5">
                <h4 className="m-0">Login</h4>
                <span className="content">For accessing dashboard</span>
                <p className="error">{this.state.responseMessage}</p>
                <Form className="mbme-form mt-4  position-relative" onSubmit={this.loginSubmit.bind(this)}>
                  <Form.Group controlId="loginForm.username" className="mb-3">
                    <Form.Control type="text" placeholder="Username/Email" ref="username" onChange={this.handleChange.bind(this, "username")} value={this.state.fields["username"] || ""} />
                    <span className="error">{this.state.errors["username"]}</span>
                  </Form.Group>
                  <Form.Group controlId="contactForm.EmailAddress" className="mb-3" >
                    <Form.Control type="password" placeholder="Password" ref="password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"] || ""} />
                    <span className="error">{this.state.errors["password"]}</span>
                  </Form.Group>
                  <div className="login-button">
                    <button className={this.state.buttonDisable === true ? 'disabled' : 'enable'} id="submit" value="Submit">
                      Login
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AdminLogin;
