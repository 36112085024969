import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { browserName, browserVersion } from "react-device-detect";
import  { Redirect } from 'react-router-dom';
import './about.scss';
import PageBanner from './banner';
import ClientSlider from '../common/client-slider';
import { about_our_mission, about_our_vision, lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';
import Timeline from './timeline';



class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.myRefOne = React.createRef();
    this.myRefTwo = React.createRef();
  }

  componentDidMount() {
    if (this.props.location.pathname === '/about-us/mission') {
      if(browserName ==="Safari" || browserName ==="Trident" ){
        this.props.history.push('/mission');
      }
      window.scrollTo({
        top: 1400,
        left: 100,
         behavior: 'smooth'
      });
    }
    else if (this.props.location.pathname === '/about-us/vision') {
      if(browserName ==="Safari" || browserName ==="Trident" ){
        this.props.history.push('/vision');
      }
      window.scrollTo({
        top: 900,
        left: 100,
        behavior: 'smooth'
      });
    }
  }


  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner />
        <div className="position-block position-relative">
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <Container className="pageview">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Row className="field-padd-2">
                  <Col md={12} xs={12}>
                    <div className="values-block">
                      <p className="content m-0">
                      Since 1997, the MBME Pay’s core business has been making payments simple, secure, and fast. Since launching, we’ve become the largest payment services provider, bill aggregator, kiosk manufacturer, and software developer in the UAE and the region. We are currently an acknowledged leader in developing innovative payment solutions for partners, retailers, and customers. These come in the form of Generic kiosks, Customized kiosks, Service extensions, and App development. 
                      </p>
                      <p className="content mt-3">
                      Through our services, customers now have a convenient platform to:
                        <ul>
                        <li>Top-Up</li>
                        <li>Pay Bills</li>
                        <li>Transfer Funds to Family and Friends</li>
                        <li>Donate to Charity</li>
                        <li>Leverage on a Range of Smart Services Through Self-Service Kiosks</li>
                        <li>Wallets</li>
                        <li>Purchase Vouchers</li>
                        <li>Registration</li>
                        </ul>
                      </p>
                      
                      <p className="content mt-3">
                      We strive to deliver excellent, high-class services to every client using our global experience and expertise in kiosks, payments, security, and user acceptance. To us, service delivery goes beyond convenience as we aim to make each platform as innovative and technologically advanced as possible. 
                      </p>

                      <p className="content mt-3">
                      Building strong business relationships with our clients allows us to establish a clear understanding of each unique requirement in order to deliver custom services. This means providing interactive and intuitive solutions to enhance your business goals.
                      </p>

                      <p className="content mt-3">
                      As a technologically-oriented platform, our mission is to always learn, adapt, and be at the forefront of technological innovations. With that in mind, MBME Pay can always offer proven, tailored services to each individual businesses’ requirements. 
                      </p>

                      <p className="content mt-3">
                      As such, we go above and beyond to meet the needs of our local partners and retailers so they can operate at maximum efficiency and drive their business growth to new heights. 
                      </p>

                      <p className="content mt-3">
                      With a strong foundation based in Abu Dhabi, MBME Pay is a local services provider with international standards. As such, we operate across all seven Emirates and have over 60 staff members representing more than 10 nationalities. 
                      </p>


                    </div>
                  </Col>
                </Row>

                <Row className="img-with-data field-padd-2" id="one">

                  <Col md={5} xs={12} className="position-relative">
                    <h5 className="head-no-bold mb-0 mt-3">OUR</h5>
                    <h4 className="head-bold mb-4 mt-0">VISION</h4>

                    <span className="quotes">"</span>
                    <p className="content vision-content">
                    MBME Pay’s vision is to not only stay the largest payment services provider, bill aggregator, kiosk manufacturer, and software developer in the UAE, but to become a leader in payment services solutions and the first choice for all associations. Not only do we envision a more innovative future, but also a more convenient one for both businesses and consumers.
                            </p>
                  </Col>

                  <Col md={7} xs={12} className="position-relative">
                    <Image src={about_our_vision} fluid alt="our-vision" />
                  </Col>

                </Row>

                <Row className="img-with-data field-padd-2" id="two">
                  <Col md={7} xs={12} className="position-relative">
                    <Image src={about_our_mission} fluid alt="our-mission" />
                  </Col>

                  <Col md={5} xs={12} className="position-relative">
                    <h5 className="head-no-bold mb-0 mt-3">OUR</h5>
                    <h4 className="head-bold mb-4 mt-0">MISSION</h4>

                    <span className="quotes">"</span>
                    <p className="content vision-content">
                    Our mission at MBME Pay is to stay at the forefront of pioneering technological advancements and to always provide our clients with class-leading solutions for optimal convenience, efficiency, security, and trust. We strive to always deliver above and beyond our customers’ expectations. 
                            </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div className="timeline position-relative">
            <div className="line-anim-right">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
              <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
              <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
              <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
            </div>
            <Timeline />
          </div>
          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <ClientSlider />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default AboutUs;
