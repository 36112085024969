import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import './solution.scss';
import PageBanner from './banner';
import SolutionsView from './solution';
import ClientSlider from '../common/client-slider';
import { lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';
import Client from '../home/client';


class Solutions extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      titleData: 'Self Service Solutions',
      titleDescription: 'All You Need at Your Fingertips'
    };
  };

  callbackFunction = (childData) => {
    if (childData === 'one')
      this.setState({
        titleData: 'Self Service Solutions',
        titleDescription: 'All You Need at Your Fingertips',
      })
    if (childData === 'two')
      this.setState({
        titleData: 'Smart Government',
        titleDescription: 'Smart Government',
      })
    if (childData === 'three')
      this.setState({
        titleData: 'Kiask Models',
        titleDescription: 'Kiask Models',
      })
    if (childData === 'four')
      this.setState({
        titleData: 'MBME Software',
        titleDescription: 'MBME Software',
      })
    if (childData === 'five')
      this.setState({
        titleData: 'Managed Services',
        titleDescription: 'Managed Services',
      })
    // if (childData === 'six')
    //   this.setState({
    //     titleData: 'Govera App',
    //     titleDescription: 'Govera App',
    //   })
  }

  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner title={this.state.titleData} description={this.state.titleDescription} />
        <div className="pageview position-relative">
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <SolutionsView parentCallback={this.callbackFunction} />
        </div>
        <Container className="field-padd">
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
              <Client />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Solutions;
