import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './home.scss';

class Customer extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div className="text-center" >
                            <h4 className="my-4" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="1000">
                            <p>Welcome to the Largest Payment Services Provider, Bill Aggregator, Kiosk Manufacturer & Software Developer in the UAE</p>    
                            </h4>
                            <p className="content" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="1000">Make your business operations and customer experiences easier, more efficient, and more secure with state-of-the-art technology and equipment. Take your business to the next level with optimized MBME Pay platforms. </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="customer-view mt-4">
                            <div className="ech-count text-center" data-aos="fade-up" data-aos-duration="1200">
                                <h4>16 M+</h4>
                                <p>Annual Transactions</p>
                            </div>
                            <div className="ech-count text-center" data-aos="fade-up" data-aos-duration="1200">
                                <h4>2.9 B+</h4>
                                <p>Annual Revenue</p>
                            </div>
                            <div className="ech-count text-center" data-aos="fade-up" data-aos-duration="1200">
                                <h4>3.1 M+</h4>
                                <p>Users</p>
                            </div>
                            <div className="ech-count text-center" data-aos="fade-up" data-aos-duration="1200">
                                <h4>2.1 M+</h4>
                                <p>Monthly Views</p>
                            </div>
                            <div className="ech-count text-center" data-aos="fade-up" data-aos-duration="1200">
                                <h4>13+</h4>
                                <p>Industry Year History</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Customer;
