import React, { Component } from 'react';
import { Col, Row, Image, Tab } from 'react-bootstrap';
import './terms.scss';
import { mastercard, mastercardsecurecode, visa, verifiedVisa } from '../../images/index';


class TermsCondition extends Component {
    render() {
        return (
            <div>
                <Row className="field-padd-2">
                    <Col md={12} xs={12}>
                        <div className="values-block">
                            <h3 className="head-medium-block mb-4 mt-0">Terms of service</h3>
                            <p className="content m-0 mb-3">We are committed to transparency and fairness, so we have provided our Terms and Conditions below. When you use our services at MBMEPAY Platforms, you agree to follow these rules that guarantee a hassle-free and safe service to all users.</p>
                            <p className="content m-0"> Our main aim is your trust and satisfaction, which means that by using our service, you become part of an outstanding community.</p>
                        </div>
                    </Col>
                </Row>

                <Tab.Container id="privacy-left-tab" defaultActiveKey="one">
                    <Row className="privacy-tab field-padd-3">
                        <Col sm={12}>
                            <div>

                                {/* <h5 className="head-medium-two mb-3">About Us </h5> */}
                                <p>
                                    The MBMEPAY Platform is owned and operated by MBME Pay Payment Services Provider L.L.C. with
                                    MaxBox Establishment date 2009 (the “Company”), with its registered office at 1405, Prism Tower,
                                    Business Bay, Dubai, UAE. The Company has been formed pursuant to law no. 16 of 2005 with limited
                                    liability. United Arab Emirates is our country of domicile.
                                </p>
                                <p>
                                    MBMEPAY Platform is the largest payment and government services platform in the UAE, we have been
                                    making payments simple, secure and quick since 1997. We are an acknowledged UAE leader in developing
                                    payment solutions for our partners, retailers and customers. We offer customers a convenient way to top
                                    up, pay bills, transfer money to family and friends, donate to charity and utilize a range of smart
                                    services at our self-service kiosks. Our global experience and knowledge of kiosks, payment, security,
                                    and user acceptance are pivotal in our deliverance of high class service to our clients. We work closely
                                    with our customers to ensure that we understand and can deliver on their individual business needs; so
                                    as to provide an interactive and intuitive solution that enhances their business goals.
                                </p>
                                <p>
                                    All the services provided by the Company to you by means of your registration on the MBMEPAY Platform
                                    or a guest user, are hereinafter referred to as the “Services”. Any reference to “you” or “your” or
                                    “user” refers to you as a User of the MBMEPAY Platform and the Services and any reference to “we”, “our”
                                    and “us” shall refer to the Company as the provider of the Services.
                                </p>

                                <h5 className="head-medium-two mb-3">Payments & Recharges</h5>

                                <p>
                                    The Company is not a warrantor, insurer, or guarantor of the services to be provided by the Telcos
                                    or any 3rd party. Recharge or vouchers sold by us to you are sold without recourse against us for
                                    any breach of contract by the Telco or other 3rd parties. Any disputes regarding the quality, minutes
                                    provided, cost, expiration, or other terms of the Recharge/Payments purchased must be handled directly
                                    between you (or the recipient of the Recharge) and the Telco or 3rd parties.
                                </p>
                                <p>
                                    It is your responsibility to ensure the DTH connection to which recharge is being sent is valid. You must not
                                    attempt to recharge any illegal connection. No refund will be processed for invalid or illegal DTH recharge
                                    transactions.
                                </p>
                                <p>
                                    The Recharge/Payments is sent instantly by us to the appropriate 3rd party upon successful payment by you.
                                    Occasionally, there may be a short delay before the relevant 3rd party applies the recharge to the mobile phone
                                    number or the DTH connection.
                                </p>

                                <h5 className="head-medium-two mb-3">MBME Pay Fees</h5>

                                <p>The company reserves the right to add or amend fees at its discretion.</p>
                                <p>
                                    For overseas transactions, where an exchange rate has been applied, the actual amount that you are charged
                                    may vary as our payment processors FX rate may be slightly different at the time of settlement.
                                </p>
                                <p>
                                    The total amount (inclusive of all applicable taxes and charges) that you will be required to pay will
                                    be displayed clearly before you are asked to confirm your transaction.
                                </p>

                                <h5 className="head-medium-two mb-3">Changes to these Terms and Services</h5>

                                <p>
                                    We may revise these Terms at any time by amending this page. Please check this page from time to time to
                                    take notice of any changes we made, as they are binding on you.
                                </p>
                                <p>
                                    We may, without prior notice, change the Services; add or remove functionalities or features; stop
                                    providing the Services or features of the Services, to you or to Users generally; or create usage limits for the
                                    Services.
                                </p>


                                <h5 className="head-medium-two mb-3">Changes to the MBME Pay Platform</h5>

                                <p>
                                    We may update the MBMEPAY Platform from time to time, and may change the Content at any time. In these Terms,
                                    the term “Content” also means any information, text, graphics, or other materials uploaded (including any
                                    features or functionalities) by the Users of the MBMEPAY Platform, including personal information uploaded by
                                    the donors, and which may appear on the MBMEPAY Platform for other Users to access. However, please note that
                                    any of
                                    the Content on the MBMEPAY Platform may be out of date at any given time and we are under no obligation to
                                    update it.
                                </p>

                                <p>
                                    We do not guarantee that the MBMEPAY Platform, or any Content on it, will be free from errors or omissions.
                                    We encourage our Users to report any errors by writing to us from the Support section on the MBMEPAY Platform.
                                </p>


                                <h5 className="head-medium-two mb-3">Accessing the MBME Pay Platform</h5>

                                <p>
                                    We do not guarantee that your use of the MBMEPAY Platform, or any Content on it, will always be available
                                    or be uninterrupted. Access to the MBMEPAY Platform is permitted on a temporary basis. We may suspend, withdraw,
                                    discontinue or change all or any part of the MBMEPAY Platform without notice. We will not be liable to you if
                                    for
                                    any reason the MBMEPAY Platform is unavailable at any time or for any period.
                                </p>
                                <p>
                                    You are also responsible for ensuring that all persons who access the MBMEPAY Platform through your Internet
                                    connection
                                    are aware of these Terms and other applicable terms and conditions, and that they comply with them.
                                </p>

                                <h5 className="head-medium-two mb-3">Your Account and Password</h5>

                                <p>
                                    If you choose to transact on the MBMEPAY Platform, an account will be created for your use ("Account") and you
                                    will be provided with the required Account Information to enable your access to the Account. The term "Account
                                    Information"
                                    refers to a User identification code, password or any other piece of information which may be provided to you as
                                    part of
                                    our security procedures. If you access the MBMEPAY Platform via any third party website like Facebook, Twitter
                                    or Gmail,
                                    the login information of such third party account, as the case may be, shall be considered as the Account
                                    Information.
                                    You must always treat Account Information as confidential and must not disclose it to any third party. Any
                                    access to the
                                    MBMEPAY Platform through your Account shall be considered an access by you or on your behalf and you shall be
                                    solely responsible
                                    for any activity carried out in, by or through your Account either on the MBMEPAY Platform or any other website
                                    accessed by you
                                    through the MBMEPAY Platform.
                                </p>
                                <p>
                                    You represent and warrant that you are an individual of age as required to enter into a binding contract as is
                                    prescribed under the laws of the country in which you reside. Any person under the age of eighteen (18) years
                                    accessing
                                    the MBMEPAY Platform should do so only under parental guidance. If we change the eligibility criteria to be
                                    registered with
                                    the MBMEPAY Platform and you no longer comply with the new eligibility criteria, as determined by us in our sole
                                    discretion,
                                    you accept that we may close your Account without any liability for us. You are solely responsible for ensuring
                                    that these Terms are
                                    in compliance with all laws, rules and regulations applicable to you and the right to access the Services is
                                    revoked where these Terms or
                                    use of the Services is prohibited and, in such circumstances, you agree not to use or access the MBMEPAY
                                    Platform or Services in any way.
                                </p>
                                <p>
                                    We have the right to disable your use of the Account Information or any part of it, whether chosen by you or
                                    allocated by us,
                                    at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms.
                                </p>
                                <p>
                                    If you know or suspect that anyone, other than you, knows or has unauthorized access to your Account Information
                                    or any part of it,
                                    you must promptly notify us via ‘Contact Us’ on the MBMEPAY Platform. We are not liable for any losses or other
                                    consequences of
                                    unauthorized use of your account.
                                </p>


                                <h5 className="head-medium-two mb-3">User Responsibilities</h5>

                                <p>These Terms govern your behavior on the MBMEPAY Platform and set forth your obligations. You agree and confirm
                                    to the following responsibilities:</p>
                                <ul>
                                    <li>
                                        <p> shall comply with all the obligations set forth in these Terms.</p>
                                    </li>
                                    <li>
                                        <p>
                                            You will use the Services rendered by us for lawful purposes only and comply with these Terms and all
                                            applicable laws,
                                            statutes, by-laws, acts of legislature or parliament, rules, regulations, orders, ordinances, protocols,
                                            codes, guidelines,
                                            or policies of any governmental authority; and all applicable judicial orders and precedent Applicable Laws
                                            while using
                                            and transacting on the MBMEPAY Platform.
                                        </p>
                                    </li>

                                    <li>
                                        <p>Creation and maintenance of all Content in your account shall be your sole responsibility.</p>
                                    </li>

                                    <li>
                                        <p>
                                            You are responsible for safeguarding the password that you use as a part of your Account Information to
                                            access the Services
                                            and for any activities or actions under your Account. We encourage you to use “strong” passwords preferably
                                            using a
                                            combination of upper and lower case letters, numbers and symbols with your Account. The Company will not be
                                            liable for
                                            any loss or damage arising from your failure to comply with this instruction.
                                        </p>
                                    </li>

                                    <li>
                                        <p>Provide us with only such information that is true and accurate to the best of your knowledge.</p>
                                    </li>

                                    <li>
                                        <p>
                                            The Service shall be utilized by you for your personal, non-commercial use only. You shall not use the
                                            Service for any
                                            commercial endeavors without express prior written consent of the Company</p>
                                    </li>

                                    <li>
                                        <p>
                                            Complaints (if any) regarding the Service availed by the User must be reported to us via ‘Support’ on the
                                            MBMEPAY
                                            Platform within two days from the date of payment. The Service shall be marked as closed if no such
                                            complaint is raised
                                            by the User within the said period.</p>
                                    </li>

                                    <li>
                                        <p>You must retain a copy of your transaction records and these policies and rules.</p>
                                    </li>

                                </ul>


                                <h5 className="head-medium-two mb-3">Prohibited Conduct</h5>

                                <p>By using the MBMEPAY Platform you agree not to:</p>

                                <ul>
                                    <li>
                                        <p>
                                            impersonate any person or entity, falsely claim or otherwise misrepresent an affiliation with any person or
                                            entity,
                                            or access the accounts of others without permission, forge another persons’ digital signature, misrepresent
                                            the source,
                                            identity, or content of information transmitted via the Services, perform any other similar fraudulent
                                            activity or
                                            otherwise purchase Recharge with what we reasonably believe to be potentially fraudulent funds
                                        </p>
                                    </li>

                                    <li>
                                        <p>infringe our or any third party’s intellectual property rights, rights of publicity or privacy</p>
                                    </li>
                                    <li>
                                        <p>post or transmit any message which is libelous, defamatory or which discloses private or personal matters
                                            concerning any person</p>
                                    </li>
                                    <li>
                                        <p>post or transmit any message, data, image or program which is pornographic in nature</p>
                                    </li>
                                    <li>
                                        <p>refuse to cooperate in an investigation or provide confirmation of your identity or any other information
                                            you provide to MBMEPAY</p>
                                    </li>
                                    <li>
                                        <p>
                                            remove, circumvent, disable, damage or otherwise interfere with security-related features of the MBMEPAY
                                            Platform
                                            or features that enforce limitations on the use of the services
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Services or
                                            any part thereof, except and only to the extent that such prohibition is expressly prohibited by applicable
                                            law
                                            notwithstanding this limitation
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            use the Services in any manner that could damage, disable, overburden, or impair it, including, without
                                            limitation,
                                            using the Services in an automated manner
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            modify, adapt, translate or create derivative works based upon the MBMEPAY Platform or any part thereof,
                                            except and
                                            only to the extent that such prohibition is expressly prohibited by applicable law notwithstanding this
                                            limitation
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            intentionally interfere with or damage operation of the services or any other user’s enjoyment of it, by any
                                            means,
                                            including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code or
                                            file with
                                            contaminating or destructive features
                                        </p>
                                    </li>
                                    <li>
                                        <p>use any robot, spider, other automatic device, or manual process to monitor the MBMEPAY Platform without
                                            prior written permission</p>
                                    </li>
                                    <li>
                                        <p>interfere or disrupt the MBMEPAY Platform or connected networks</p>
                                    </li>
                                    <li>
                                        <p>take any action that imposes an unreasonably or disproportionately large load on our infrastructure/network
                                        </p>
                                    </li>
                                    <li>
                                        <p>use any device, software or routine to bypass, or interfere or attempt to interfere, with the Services</p>
                                    </li>
                                    <li>
                                        <p>
                                            forge headers or manipulate identifiers or other data in order to disguise the origin of any content
                                            transmitted through
                                            the MBMEPAY Platform or to manipulate your presence on the platform
                                        </p>
                                    </li>
                                    <li>
                                        <p>sell the Services, information, or software associated with or derived from it</p>
                                    </li>
                                    <li>
                                        <p>
                                            use the facilities and capabilities of the MBMEPAY Platform to conduct any activity or solicit the
                                            performance of any
                                            illegal activity or other activity which infringes the rights of others
                                        </p>
                                    </li>
                                    <li>
                                        <p>breach this Agreement or any other MBMEPAY agreement or policy</p>
                                    </li>
                                    <li>
                                        <p>provide false, inaccurate or misleading information</p>
                                    </li>
                                    <li>
                                        <p>
                                            use the MBMEPAY Platform to collect or obtain personal information, including without limitation, financial
                                            information, about other users of the platform
                                        </p>
                                    </li>
                                    <li>
                                        <p>purchase Recharge with what MBMEPAY reasonably believes to be potentially fraudulent funds</p>
                                    </li>
                                    <li>
                                        <p>
                                            use the Services in a manner that results in or may result in complaints, disputes, reversals, chargebacks,
                                            fees,
                                            fines, penalties and other liability to MBMEPAY , a third party or you
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            use the Services in a manner that MBMEPAY or any payment card network reasonably believe to be an abuse of
                                            the
                                            payment card system or a violation of payment card network rules
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            take any action that may cause MBMEPAY to lose any of the Services from its service providers, Telcos,
                                            payment processors or other suppliers
                                        </p>
                                    </li>
                                    <li>
                                        <p>send automated request of any kind to the MBMEPAY Platform without express permission in advance from
                                            MBMEPAY .</p>
                                    </li>
                                </ul>


                                <h5 className="head-medium-two mb-3">Payments</h5>

                                <p>
                                    We accept Visa and MasterCard cards and Internet banking as modes of payments which are processed through
                                    our payment gateway partners (“Payment Gateways”). When you make a payment for products and services on
                                    our platform, the details you are asked to submit will be provided directly to our payment gateway via a
                                    secured connection. The Company has chosen to work with established and certified Payment Gateways and is as
                                    such not liable for any potential lapse or breach at the Payment Gateway.
                                </p>


                                <h5 className="head-medium-two mb-3">Intellectual Pproperty Right</h5>

                                <p>
                                    We own all right, title and interest, including, but not limited to all copyright, trademark, patent, trade
                                    secret,
                                    source code, interface software, routing mechanism or other proprietary rights (“IP Rights”) of the MBMEPAY
                                    Platform.
                                    You shall not reproduce, distribute, transmit, modify, create derivative works, display, perform or otherwise
                                    use the MBMEPAY
                                    Platform or any of the IP Rights, or attempt to reverse engineer, decompile, disassemble, or derive the source
                                    code or the
                                    interface software of the MBMEPAY Platform. Unauthorized use is strictly prohibited. All rights are expressly
                                    reserved.
                                </p>


                                <h5 className="head-medium-two mb-3">Disclaimer of warranties</h5>

                                <p>
                                    You expressly acknowledge and agree that use of the Services and the MBMEPAY Platform is at your sole risk.
                                    The Services and the MBMEPAY Platform are provided on an “as is” and “as available” basis. Although we make best
                                    efforts to offer high quality Services to all our Users, to the fullest extent allowed by law, we expressly
                                    disclaim all
                                    warranties and conditions of any kind, whether express or implied, including, but not limited to the warranties
                                    of
                                    merchantability or fitness for a particular purpose. The contents of the Services or the MBMEPAY Platform may
                                    contain bugs,
                                    errors, problems or other limitations.
                                </p>
                                <p>
                                    We make no warranty that the Services or MBMEPAY Platform will meet your requirements or that the Services
                                    or your access to the MBMEPAY Platform will be uninterrupted, timely, accurate or reliable; nor do we make any
                                    warranty as to any information that may be obtained through the Services or MBMEPAY Platform. In case there is
                                    any defect in any software being used for the provision of the Services, we do not make any warranty that
                                    defects
                                    in such software will be corrected. You understand and agree that any material and/or data downloaded or
                                    otherwise
                                    obtained through use of the Services or MBMEPAY Platform is done at your own discretion and risk and you will be
                                    solely responsible for any damage to your computer system or loss of data that results from the download of such
                                    material or data.
                                </p>


                                <h5 className="head-medium-two mb-3">Viruses</h5>

                                <p>
                                    You will be liable in case you introduce any viruses, Trojans, worms, logic bombs or other material that is
                                    malicious
                                    or technologically harmful. You must not attempt to gain unauthorized access to the MBMEPAY Platform, the server
                                    on
                                    which the MBMEPAY Platform is stored or any server, computer or database connected to the MBMEPAY Platform. By
                                    breaching
                                    this provision, you would commit a criminal offence under the Applicable Law. We will report any such breach to
                                    the relevant
                                    law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them.
                                    In the event
                                    of such a breach, your right to use the MBMEPAY Platform will cease immediately.
                                </p>
                                <p>
                                    We will not be liable for any loss or damage caused by a virus, denial-of-service attack, distributed
                                    denial-of-service
                                    attack, or other technologically harmful material that may infect your computer equipment, computer programs,
                                    data or other
                                    proprietary material due to your use of the MBMEPAY Platform or on any website linked to it.
                                </p>


                                <h5 className="head-medium-two mb-3">Limitation of our liability</h5>

                                <p>
                                    To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may
                                    apply to the MBMEPAY Platform or any content on it including any information related to the Services or the
                                    MBMEPAY
                                    Platform itself, whether express or implied.
                                </p>
                                <p>We will not be liable to any User for any direct, indirect or consequential loss or damage, whether in
                                    contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising
                                    under or in connection with:</p>
                                <ul>
                                    <li>
                                        <p>use of, or inability to use, the MBMEPAY Platform;</p>
                                    </li>
                                    <li>
                                        <p>use of or reliance on any content displayed on the MBMEPAY Platform; and</p>
                                    </li>
                                    <li>
                                        <p>use of the services provided through the MBMEPAY Platform</p>
                                    </li>
                                </ul>
                                <p>Information we collect about you. We will use this information:</p>
                                <ul>
                                    <li>
                                        <p>to administer the MBMEPAY Platform and for internal operations, including troubleshooting, data analysis,
                                            testing, research, statistical and survey purposes</p>
                                    </li>
                                    <li>
                                        <p>to improve the MBMEPAY Platform to ensure that content is presented in the most effective manner for you
                                            and for your computer or smartphone</p>
                                    </li>
                                    <li>
                                        <p>to allow you to participate in interactive features of our Services, if any, when you choose to do so;</p>
                                    </li>
                                    <li>
                                        <p>to publish in social media or on MBMEPAY Platform for the promotion of the Company or the MBMEPAY Platform
                                        </p>
                                    </li>
                                    <li>
                                        <p>as part of our efforts to keep the MBMEPAY Platform safe and secure</p>
                                    </li>
                                    <li>
                                        <p>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver
                                            relevant advertising to you</p>
                                    </li>
                                    <li>
                                        <p>to make suggestions and recommendations to you and other Users of the MBMEPAY Platform about Services that
                                            may interest you or them</p>
                                    </li>
                                </ul>
                                <p>
                                    Please note that we only provide the MBMEPAY Platform for individual use. You agree not to use the MBMEPAY
                                    Platform for
                                    any commercial or business purposes without obtaining a legally valid license to do so in accordance with these
                                    Terms.
                                </p>
                                <p>
                                    To the maximum extent permitted by law, or maximum aggregate liability to you for any causes whatsoever, and
                                    regardless
                                    to the form of action, will at all times be limited to the value of your transaction or AED Five Hundred (AED
                                    500), whichever is lesser.
                                </p>


                                <h5 className="head-medium-two mb-3">Electronic communications</h5>

                                <p>
                                    When you visit the MBMEPAY Platform, call or write to us, you are communicating with us electronically. You
                                    agree that all
                                    agreements, notices, disclosures and other communications that we provide to you electronically satisfy any
                                    legal requirement that
                                    such communications be in writing. You agree that in case there are any changes in any information provided by
                                    you to us, including your
                                    e-mail address and other contact details, you will be solely responsible to update them regularly.
                                </p>
                                <p>
                                    If we send any communication by e-mail, it shall be sent to your e-mail address available on the records created
                                    by you
                                    on the MBMEPAY Platform and it shall be deemed to have been received by you once it is reflected as sent in the
                                    outbox of our e-mail ID.
                                </p>
                                <p>
                                    You consent to receive communications from us electronically, including but not limited to email, SMS, MMS, and
                                    telephonic calls.
                                    You also agree to receive communication from us based on usage history via SMS, email &amp; phone call. This
                                    includes offers, discounts
                                    and general information. You can choose to optout as per modes advised in the communication.
                                </p>
                                <p>
                                    You acknowledge that any communication by us may be susceptible to error, omission and/ or inaccuracy. You agree
                                    that you will not
                                    hold us liable for any loss, damages, claim, expense including legal cost that may be incurred/ suffered by you
                                    on account of our communication.
                                </p>


                                <h5 className="head-medium-two mb-3">Indemnification</h5>

                                <p>
                                    To the maximum extent permitted by Applicable Law, you shall indemnify and hold harmless the Company, its
                                    owners, licensee,
                                    affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and
                                    employees, from any
                                    claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due
                                    to or arising out of your
                                    breach of these Terms, or your violation of any law, rules or regulations or the rights (including infringement
                                    of intellectual property rights)
                                    of a third party.
                                </p>


                                <h5 className="head-medium-two mb-3">Release and Waiver</h5>

                                <p>
                                    To the maximum extent permitted by Applicable Law, you hereby release and waive all claims against the Company,
                                    and its subsidiaries, affiliates, officers, agents, licensors, co-branders or other partners, and employees from
                                    any and all liability for claims, damages (actual and/or consequential), costs and expenses (including
                                    litigation costs and attorneys’
                                    fees) of every kind and nature, arising from or in any way related to your use of the MBMEPAY Platform, content
                                    or use of the Services.
                                    You understand that any fact relating to any matter covered by this release may be found to be other than now
                                    believed to be true
                                    and you accept and assume the risk of such possible differences in fact. In addition, you expressly waive and
                                    relinquish any and
                                    all rights and benefits which you may have under any other state or federal statute or common law principle of
                                    similar effect,
                                    to the fullest extent permitted by law.
                                </p>
                                <p>
                                    If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our
                                    rights
                                    against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will
                                    not mean
                                    that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in
                                    writing, and that
                                    will not mean that we will automatically waive any later default by you.
                                </p>

a
                                <h5 className="head-medium-two mb-3">Applicable LAW</h5>

                                <p>
                                    Please note that these Terms, its subject matter and its formation, are governed by the laws of the United Arab
                                    Emirates.
                                    The courts of Emirate of Dubai will have exclusive jurisdiction to deal with any dispute arising out of or in
                                    connection with these Terms
                                    or any other terms and conditions made applicable on you by us and you consent to the jurisdiction and venue of
                                    such courts and waive any
                                    objection as to inconvenient forum.
                                </p>
                                <p>This website/URL will not provide any services or products to any OFAC sanctioned countries in accordance with
                                    the laws of UAE.</p>


                                <h5 className="head-medium-two mb-3">Termination</h5>

                                <p>
                                    We reserve the right to refuse to continue to provide you with access to the MBMEPAY Platform if we discover
                                    that you are
                                    incompetent to contract by virtue of your age or otherwise under applicable law. The MBMEPAY Platform is not
                                    available to persons
                                    whose membership has been suspended or terminated by us for any reason whatsoever. In case, you choose to access
                                    the MBMEPAY Platform
                                    from any jurisdiction not governed by the laws of the United Arab Emirates, you are solely responsible for
                                    compliance with local laws
                                    of that jurisdiction and all applicable laws.
                                </p>


                                <h5 className="head-medium-two mb-3">General Terms</h5>

                                <p>
                                    If any provision of the Terms is found by a court of competent jurisdiction to be invalid, other provisions of
                                    such the Terms shall remain in full force and effect.
                                </p>


                                <h5 className="head-medium-two mb-3">Refund Policy</h5>

                                <p>
                                    All sales of Recharge are final with no refund or exchange permitted. You are responsible for the mobile
                                    number you purchase Recharge for and all charges that result from those purchases. We are not responsible for
                                    any purchase
                                    of Recharge for an incorrect mobile number. However, if in a transaction performed by you on the MBMEPAY
                                    Platform, money has
                                    been charged to your card or bank account and a Recharge is not delivered please inform us at the earliest but
                                    no later than
                                    24 hours from completion of the transaction via the Support page. Please include the following details – the
                                    mobile number,
                                    operator name, Recharge value, Transaction date and Order Number. We will investigate the incident and if it is
                                    found that
                                    money was indeed charged to your card or bank account without delivery of the Recharge then you will be refunded
                                    the money
                                    within 7 working days from the date of receipt of your message. All Refunds will be credited to your card or
                                    bank account used to
                                    initiate the transaction. It may take 3-21 days for the money to show in your bank account depending on your
                                    bank’s policy.
                                </p>
                                <p>No refunds will be processed for DTH recharge under any circumstances.</p>
                                <p>The policy's applicability is based on the products or services offered by the MBME Pay.</p>

                                <h5 className="head-medium-two mb-3"> Governing Law and Jurisdiction </h5>
                                <p className="content">In any situation, users can rest assured that our commitment extends to upholding the laws of the UAE,
                                    ensuring a secure and legally compliant environment for all interactions</p>

                                <h5 className="head-medium-two mb-3"> Deliver, Shipping and Services policy </h5>
                                <p className="content">These policies are tailored to the specific products or services available on MBME Pay.
                                    MBME Pay will not engage in any dealings with OFAC sanctions countries, aligning with UAE law.</p>

                                {/* <h5 className="head-medium-two mb-3"> Refund & Return policy </h5>
                                <p className="content">Users should be aware that refunds will be processed exclusively through the original mode of payment.</p> */}

                                <h5 className="head-medium-two mb-3"> Cancellation & Replacement policy </h5>
                                <p className="content">The policy's applicability is based on the products or services offered by the MBME Pay.</p>

                                <h5 className="head-medium-two mb-3"> Address & Contact </h5>
                                <h6>MBME Pay Dubai Office</h6>
                                <p className="content">Office 207, Bahwan Plaza, Sheikh Zayed Road, <br /> Umm Al Sheif, Next to
                                    Lamborghini Showroom, Dubai. PO Box 450389,<br /> Tel:(+971) 04 382 9999</p>

                                <h6>MBME Pay Abu Dhabi Office</h6>
                                <p className="content">M21, Plot 18, Musaffah, Abu Dhabi. P.O. Box 26448, <br />
                                    Tel: (+971) 02 443 2150</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>We Accept</h5>
                            <p className="text-muted">Secure Payment Methods</p>
                        </Col>
                        <Col className="text-center">
                            <Image className="mr-3" src={visa} alt="page-banner" />
                            <Image className="mr-3" src={mastercard} alt="page-banner" />
                            <Image className="mr-3" src={verifiedVisa} alt="page-banner" />
                            <Image className="mr-3" src={mastercardsecurecode} alt="page-banner" />
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default TermsCondition;
