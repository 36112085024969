import React, { Component } from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Newsletter from './newsletter';
import { dark_hash_left, light_hash_left, dark_hash_right, light_hash_right, facebook, linkedin, instagram, bell, watsapp, logoImg } from '../../images/index';
import { createBrowserHistory } from "history";

class Footer extends Component {
  render() {
    const history = createBrowserHistory();
    const currentPage = history.location.pathname;
    let footer;

    if ( currentPage === '/admin/change/password' || currentPage === '/admin/newsletter' || currentPage === '/admin/contact' || currentPage === '/admin/partner' || currentPage === '/admin/retailer' || currentPage === '/newsletter' || currentPage === '/admin-login') {
      footer = <div></div>
    } else {
      footer =
        <div className="position-relative">
          <Image className="foot-1" fluid src={dark_hash_left} alt="hash-lf" />
          <Image className="foot-2" fluid src={light_hash_left} alt="hash-lf" />
          <Image className="foot-3" fluid src={dark_hash_right} alt="hash-lf" />
          <Image className="foot-4" fluid src={light_hash_right} alt="hash-lf" />

          <Container className="no-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <div className="news-letter-main">
                  <Newsletter />
                </div>
                <Row className="menu-section">
                  <Col md={12} xs={12}> 
                    <img className="footlogo" src={logoImg} />
                  </Col>
                  <Col md={4} xs={12}>
                    <h4 className="head-medium-two mb-4">About Company</h4>
                    <p className="content-hash">MBME Pay is the largest Payment Services Provider, Bill Aggregator, Kiosk Manufacturer &amp; Software Developer in the UAE</p>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="footmenu">
                      <span className="head-medium-two">
                        <NavLink to="/about-us"> About Us</NavLink>
                      </span>
                      <span className="head-medium-two">
                        <NavLink to="/retailers">For Retailers</NavLink>
                      </span>
                      <span className="head-medium-two">
                        <NavLink to="/contact-us">Contact US </NavLink>
                      </span>
                      <span className="head-medium-two">
                        <NavLink to="/partners">For Partners </NavLink>
                      </span>
                      <span className="head-medium-two">
                        <NavLink to="/solutions">Solutions</NavLink>
                      </span>
                      <span className="head-medium-two">
                        <NavLink to="/careers">Careers</NavLink>
                      </span>
                    </div>
                  </Col>

                  <Col md={4} xs={12} className="my-2 social-block">
                    <div className="head-medium-two mb-2 mt-1 text-right">Stay Social With US</div>
                    <div className="icon-block">
                      <ul className="socialmedia">
                        <li>
                          <a href="https://www.facebook.com/mbme.uae/" target="_blank">
                            <Image src={facebook} alt="facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="https://instagram.com/mbmepayae" target="_blank">
                            <Image src={instagram} alt="instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/mbmepay" target="_blank">
                            <Image src={linkedin} alt="linkedin" />
                          </a>
                        </li>
                        <li>
                          <a href="https://wa.me/971563393699" target="_blank">
                            <Image src={watsapp} alt="watsapp" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="py-3 mx-1 foot-end">
                  <Col md={4} xs={12} className="text-left xs-align-center">
                    <span className="common-item">All rights reserved | Copyright 2022</span>
                  </Col>
                  <Col md={4} xs={12} className="text-left">
                    <span className="common-item"></span>
                  </Col>
                  <Col md={4} xs={12} className="text-right xs-align-between">
                  <span className="common-item mr-3">
                      <NavLink to="/terms-condition">Terms & Condition</NavLink>
                    </span>
                    <span className="common-item mr-3">
                      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                    </span>
                    <span className="common-item">
                      <NavLink to="/help">Help & Support</NavLink>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
    }


    return (
      <div>
        {footer}
      </div>
    );
  }
}

export default Footer;
