import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle, notFound } from '../../images/index';

class ErrorPage extends Component {
  render() {
    return (
      <Container className="field-padd">
        <Row>
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
            <Image fluid src={notFound} className="mx-auto d-block" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ErrorPage;
