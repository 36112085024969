import React, { Component } from 'react';
import API from '../../api';
import { Button, Form, Col, Row, Image, Spinner } from 'react-bootstrap';
import './contact.scss';
import { map_marker, sendmsgblack, sad, smile } from '../../images/index';

class ContactForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loader: false,
			message: 'hide',
			responseMessage: '',
			popup: '',
			fields: {
			},
			errors: {}
		}
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["fullname"]) {
			formIsValid = false;
			errors["fullname"] = "* Full Name cannot be empty";
		}
		if (typeof fields["fullname"] !== "undefined") {
			if (!fields["fullname"].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors["fullname"] = "* Only letters";
			}
		}
		if (!fields["phone"]) {
			formIsValid = false;
			errors["phone"] = "* Phone cannot be empty";
		}
		if (!fields["message"]) {
			formIsValid = false;
			errors["message"] = "* Message cannot be empty";
		}
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "* Email address Cannot be empty";
		}
		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');
			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["email"] = "* Email is not valid";
			}
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}

	contactSubmit(e) {
		e.preventDefault();
		this.setState({
			loader: true,
		});
		
		if (this.handleValidation()) {
			this.state.fields.firstName = this.state.fields.fullname;
			this.state.fields.type = 1;
			this.state.fields.mobile = this.state.fields.phone;
			
			API.post(`/setEnquiry`, this.state.fields)
				.then(res => {
					this.setState({
						loader: false,
						responseMessage: res.data.message,
						message: 'show',
						popup: 'success'
					});
					this.resetForm();
				}, (error) => {
					this.setState({
						loader: false,
						responseMessage: error.message,
						message: 'show',
						popup: 'error'
					});
					this.resetForm();
				});
		} else {
			setTimeout(() => {
				this.setState({
					loader: false
				})
			}, 1000);
		}
	}

	resetForm = () => { 
		this.refs.fullname.value = '';
		this.refs.email.value = '';
		this.refs.phone.value = '';
		this.refs.message.value = '';
        this.setState({
            fields: {}
        })
    }

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({
			fields
		});
	}

	onHidePopup = () => {
		this.setState({
			message: 'hide'
		});
	}

	render() {
		return (
			<div>
				<div>
					<div className={"popup " + this.state.message + " " + this.state.popup}>
						<div className="pop-inner">
							<Image src={this.state.popup === 'success' ? smile : sad} />
							<h5>{this.state.popup === 'success' ? 'success' : 'error'}</h5>
							<p>{this.state.popup === 'success' ? this.state.responseMessage : 'Go Back to Resubmit'}</p>
							<Button onClick={this.onHidePopup} className={this.state.popup}>Ok</Button>
						</div>
					</div>
					<div className={"overlay " + this.state.message} onClick={this.onHidePopup}></div>
				</div>
				<Row className="field-padd-3 tab-butt-block">
					<Col md={5} xs={12}>
						<div className="loc-block mt-5 bord-bottom">
							<div className="my-3">
								<Image src={map_marker} fluid alt="map marker" />
								<span className="head-medium-block ml-3">MBME Pay Dubai Office</span>
							</div>
							<ul className="list-unstyled">
								<li className="content">Office 207, Bahwan Plaza, Sheikh Zayed Road, </li>
								<li className="content">Umm Al Sheif, Next to Lamborghini Showroom, </li>
								<li className="content">PO Box 450389, Dubai, UAE </li>
								<li className="content">Tel.: +971 04 382 9999 </li>
							</ul>
						</div>

						<div className="loc-block mb-5">
							<div className="my-3">
								<Image src={map_marker} fluid alt="map marker" />
								<span className="head-medium-block ml-3">MBME Pay Abu Dhabi Office</span>
							</div>
							<ul className="list-unstyled">
								<li className="content">M21, Plot 18, Musaffah, </li>
								<li className="content">P.O. Box 26448, </li>
								<li className="content">Abu Dhabi, United Arab Emirates </li>
								<li className="content">Tel: +971 02 443 2150 </li>
							</ul>
						</div>
					</Col>
					<Col md={7} xs={12}>
						<div className="form-contact">
							<h4 className="m-0">Write to us</h4>
							<span className="content">we are 24/7</span>
							<Form className="mbme-form mt-4  position-relative" onSubmit={this.contactSubmit.bind(this)}>
								<Form.Group controlId="contactForm.FirstName">
									<Form.Control type="text" placeholder="Full Name" ref="fullname" onChange={this.handleChange.bind(this, "fullname")} value={this.state.fields["fullname"] || ""} />
									<span className="error">{this.state.errors["fullname"]}</span>
								</Form.Group>
								<Form.Group controlId="contactForm.EmailAddress">
									<Form.Control type="email" placeholder="Email address" ref="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
									<span className="error">{this.state.errors["email"] || ""}</span>
								</Form.Group>
								<Form.Group controlId="contactForm.PhoneNumber">
									<Form.Control type="number" placeholder="Phone Number" ref="phone" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"]} />
									<span className="error">{this.state.errors["phone"] || ""}</span>
								</Form.Group>
								<Form.Group controlId="contactForm.Message">
									<Form.Control as="textarea" rows="3" placeholder="Message" ref="message" onChange={this.handleChange.bind(this, "message")} value={this.state.fields["message"]} />
									<span className="error">{this.state.errors["message"] || ""}</span>
								</Form.Group>
								{this.state.loader === true ? (<Spinner animation="border" size="sm" />) : ('')}
								<span className="send-button">
									<button className="btn btn-lg pro" id="submit" value="Submit">
										<Image src={sendmsgblack} fluid alt="send msg" className="sendico" />
									</button>
								</span>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ContactForm;
