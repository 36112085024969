import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { country_1, country_2, country_3, country_4, country_5, country_6, country_7, country_8, country_9 } from '../../../images/index';
import ukImg from '.././../../images/uk.png';
import '../partner.scss';

class Topup extends Component {

  render() {
    return (
      <div className="inner-tab-data">
        <Container className="p-0">
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="mb-5 p-0">
              <h4 className="head-medium-normal u-case"> INTERNATIONAL MOBILE RECHARGE </h4>
              <div className="icons-no-slider mb-4 d-flex justify-content-between align-items-center">
                <Image src={country_1} alt="country 1" fluid className="country-one " />
                <Image src={country_2} alt="country 2" fluid className="country-two " />
                <Image src={country_3} alt="country 3" fluid className="country-three " />
                <Image src={country_4} alt="country 4" fluid className="country-four " />
                <Image src={country_5} alt="country 5" fluid className="country-five " />
                <Image src={country_6} alt="country 5" fluid className="country-six " />
                <Image src={country_7} alt="country 5" fluid className="country-seven " />
                <Image src={country_8} alt="country 5" fluid className="country-eight " />
                <Image src={country_9} alt="country 5" fluid className="country-nine " />
                <Image src={ukImg} alt="country 6" fluid className="country-ten " />
              </div>
            </Col>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
              <p className="content">
              Flash is our latest and freshest International Mobile Recharge (IMR) service that allows simple and fast mobile top-ups for friends and family in over 100 countries. With services in all major networks, customers can now easily and conveniently send home a mobile recharge to connect with their families.
              </p>
              <p className="content">
              We monitor competitive rates against the markets to bring families who live apart closer together. 
              </p>
              <p className="content">
              In addition to promoting efficiency and convenience for all customers, our IMR service is our way of contributing toward the development of local and international communities. 
              </p>
            </Col>
          </Row>
        </Container>
        {/* <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="position-relative mt-5">
            <div className="service-reg-block">
              <Container>
                <div className="butt-block">
                  <h3 className="my-4">Sign up to be a Partner</h3>
                  <Button type="submit" className="mbme-menu-btn">Register</Button>
                </div>
              </Container>
            </div>
          </Col>

        </Row> */}
      </div>
    );
  }
}

export default Topup;
