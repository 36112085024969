import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { transport_1,transport_2, transport_3, transport_4, transport_5, transport_6, transport_7 } from '../../../images/index';
import nol from '../../../images/billers-logos/nol.png';
import edirham from '../../../images/billers-logos/edirham.png';
import iImg from '../../../images/billers-logos/i.png';
import dubaiPoiliceLogo from '../../../images/billers-logos/dubai-poilice-logo.png';
import '../partner.scss';

class Transport extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case mb-0">TRANSPORT & FINES</h4>
            
            <div className="content mb-5"> A Simpler Way to Manage Transport and Fines </div>
            <div className=" mb-4 d-flex mb-5  align-items-center">
              <Image src={dubaiPoiliceLogo} style={{maxHeight: '80px'}} alt="transport 1" fluid className="one"/>
              <Image style={{ marginRight: '25px', maxHeight: '80px'}} src={iImg} alt="transport 2" fluid className="two"/>
              <Image style={{ marginRight: '25px', maxHeight: '80px'}} src={nol} alt="transport 3" fluid className="three"/>
              <Image style={{ marginRight: '25px', maxHeight: '80px'}} src={transport_4} alt="transport 4" fluid className="four"/>
              <Image style={{ marginRight: '25px', maxHeight: '80px'}} src={transport_6} alt="transport 6" fluid className="six"/>
            </div>
            <p className="content"> MBME Pay offers an array of transport payment services to ensure processes and operations run as smoothly and effectively as possible. Our goal is to be the one-stop solution for all payments related to automobiles, public transport, and airline services. </p>
            <p className="content"> The result? Flexibility and convenience for every customer involved! </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case mb-0 mt-0">Road toll recharge & balances</h4>
            <p className="content"> Salik offers users the ability to check current balances and recharge accounts directly and instantly from the kiosk. Direct integration with the RTA system offers a simple, efficient, and branded service specifically designed for the UAE market. </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0 mt-0">Parking payments</h4>
            <p className="content"> Take care of all your parking needs in one place! MBME Pay kiosks offer a simpler way to use Mawaqif parking services, including fine and traffic violation (PVT) payments, permit renewals, and m-Mawaqif recharging. This enables you to have carefree and easy parking experiences wherever you are! 
            </p>
            <p className="content"> Direct integration with the Department of Transport’s authorization systems lets you use our kiosks as a quick and convenient one-stop solution to pay your bills. 
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Transport;
