import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './help.scss';
import PageBanner from './banner';
import Questions from './questions';
import ClientSlider from '../common/client-slider';
import {lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';


class Help extends Component {

  constructor(props) {
    super(props);
    this.myRefOne = React.createRef();
    this.myRefTwo = React.createRef();
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner />
        <div className="position-relative">
          <div>
            <Image fluid src={lf_dark_triangle} className="lff_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lff_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rgg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rgg_light_triangle" alt="right triangle 2" />
          </div>

          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <Container className="pageview">
            <Row className="hlp-card">
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Row className="mt-3 data-card">
                  <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
                    <div className="ech-item position-relative">
                      <div className="align-data">
                        <h4 className="head-medium-normal">GETTING STARTED</h4>
                        <p className="content m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
                    <div className="ech-item position-relative">
                      <div className="align-data">
                        <h4 className="head-medium-normal capital">TRANSACTIONS</h4>
                        <p className="content m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
                    <div className="ech-item position-relative">
                      <div className="align-data">
                        <h4 className="head-medium-normal capital">TOP UP</h4>
                        <p className="content m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                      </div>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
                    <div className="ech-item position-relative">
                      <div className="align-data">
                        <h4 className="head-medium-normal capital">CARD PAYMENT</h4>
                        <p className="content m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Questions />
              </Col>
            </Row>
          </Container>

          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <ClientSlider />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Help;
