import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const mapStyle = [
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#dceafa'
            }
        ]
    },
];
class KioskLocations extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    onMarkerClick = (props, marker, e) => {
        console.log(props);
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }


    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };



    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: mapStyle
        })
    }

    render() {

        return (
            <Map google={this.props.google}
                style={{ width: '80%', height: '80%', position: 'relative' }}
                className={'map'}
                zoom={14}
                initialCenter={{ lat: 24.46, lng: 54.60 }}
                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}>
                <Marker onClick={this.onMarkerClick}
                    title={'The marker`s title will appear as a tooltip.'}
                    name={'SOMA'}
                    position={{ lat: 24.466700, lng: 55.327778 }}
                    icon={{
                        url: "http://94.237.90.186:3001/favicon.png",
                        anchor: this.props.google.maps.Point(32, 32),
                        scaledSize: this.props.google.maps.Size(64, 64)
                    }}
                />

                <Marker onClick={this.onMarkerClick}
                    title={'The marker`s title will appear as a tooltip test.'}
                    name={'Dolores park'}
                    position={{ lat: 24.427635, lng: 54.644735 }}
                    icon={{
                        url: "http://94.237.90.186:3001/favicon.png",
                        anchor: this.props.google.maps.Point(32, 32),
                        scaledSize: this.props.google.maps.Size(64, 64)
                    }} />
            </Map>
        );
    }

}

export default GoogleApiWrapper({ apiKey: ('AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo') })(KioskLocations);
