import React, { Component } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { womenbg, about_triangle_1, about_triangle_2, gray_right, vilolet_right, light_hash_right, color_dark_right } from '../../images/index';
import './home.scss';
import Banner from './banner';
import Client from './client';
import About from './about-us';
import Weoffer from './we-offer';
import Soution from './solution';
import Cofort from './cofort';
import Customer from './customers';
import Partner from './partner';
//import WOW from 'wowjs';
import AOS from 'aos';
import 'aos/dist/aos.css';


class Home extends Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div className="home-block">

        <section id="banner" className="position-relative py-1">
          <div className="banner-video">
            <Image fluid src={womenbg} alt="mbmebanner" />
          </div>

          <Container className="no-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Banner />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="client" className="position-relative">
          <div>
            <Image fluid src={about_triangle_1} className="about-lf-image-f" alt="left triangle 1" />
            <Image fluid src={about_triangle_2} className="about-lf-image-s" alt="left triangle 2" />
          </div>
          <Container className="no-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Client />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="customer" className="position-relative field-padd">
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Container>
            <Customer />
          </Container>
        </section>

        <section id="about" className="position-relative field-padd">

          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Container className="no-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <About />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="weoffer" className="position-relative pt-5">
          <div className="line-anim-left">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={light_hash_right} className="about-rg-image-f" alt="right triangle 1" />
            <Image fluid src={color_dark_right} className="about-rg-image-s" alt="right triangle 2" />
          </div>
          <Container className="no-padd">
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Weoffer />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="solution" className="position-relative field-padd">
          <div>
            <Image fluid src={about_triangle_1} className="about-lf-image-f" alt="left triangle 1" />
            <Image fluid src={about_triangle_2} className="about-lf-image-s" alt="left triangle 2" />
          </div>
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Soution />
              </Col>
            </Row>
          </Container>
        </section>

        <section id="cofort" className="position-relative field-padd">
          <div className="line-anim-left">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={light_hash_right} className="about-rg-image-f" alt="right triangle 1" />
            <Image fluid src={color_dark_right} className="about-rg-image-s" alt="right triangle 2" />
          </div>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Cofort />
              </Col>
            </Row>
          </Container>
        </section>

       

        <section id="partner" className="position-relative field-padd">
          <div>
            <Image fluid src={gray_right} className="about-rg-image-f" alt="right triangle 1" />
            <Image fluid src={vilolet_right} className="about-rg-image-s" alt="right triangle 2" />
          </div>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Partner />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default Home;
