import React, { Component } from 'react';
import './admin.scss';
import { Container, Col, Row, Table } from 'react-bootstrap';
import API from '../../api';
import Pagination from "react-js-pagination";
import Moment from 'react-moment';

class Newsletter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			totalPages: 1,
			size: 10,
			total: 0,
			rows: [],
			loader: true
		};
	}

	componentWillMount() {
		this.getSubscriptionList();
	}

	handlePageChange(pageNumber) {
		this.setState({ loader: true });
		this.setState({ activePage: pageNumber });
		this.getSubscriptionList(pageNumber);
	}

	getSubscriptionList(page = 0) {
		const USER_TOKEN = localStorage.getItem('usertoken');
		const AuthStr = 'Bearer '.concat(USER_TOKEN);
		const data = {
			size: this.state.size,
			page: page ? page : this.state.activePage
		}

		API.post('/admin/getSubscriptionList', data, { headers: { Authorization: AuthStr } })
			.then(response => {
				console.log(response.data);
				let data = response.data.data;
				this.setState({ total: data.total, rows: data.data, loader: false });
			})
			.catch((error) => {
				console.log('error ' + error);
			});
	}

	render() {
		const { total, rows, activePage } = this.state;
		let noRecodrs = '';
		if (rows.length === 0) {
			noRecodrs = 'No records found';
		}

		return (
			<div>
				<div className={"loader" + this.state.loader}>
					<div className="loader-show"></div>
				</div>
				<Container>
					<Row>
						<Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="enquiry no-padd">
							<h5 className="head-bold"> Newsletter Subscriptions </h5>
							<Table striped responsive bordered hover>
								<thead>
									<tr>
										<th>Email Address</th>
										<th>Subscribed At</th>
									</tr>
								</thead>

								<tbody>
									{noRecodrs}
									{rows.map(item => <tr>
										<td>{item.email}</td>
										<td>
										<Moment parse="YYYY-MM-DD HH:mm">
											{item.createdAt}
										</Moment>
										</td>

									</tr>)}
								</tbody>
							</Table>
						</Col>
						<Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
							<div className="pagination d-flex align-items-center justify-content-center my-3">
								<Pagination
									activePage={activePage}
									itemsCountPerPage={10}
									totalItemsCount={total}
									pageRangeDisplayed={5}
									onChange={this.handlePageChange.bind(this)}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Newsletter;
