
import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import './careers.scss';
import { about_banner } from '../../images/index';

class PageView extends Component {
    render() {
        return (
            <div>
                <Row className="field-padd-2">
                    <Col md={12} xs={12}>
                        <div className="values-block">
                            <h3 className="head-medium-block mb-4 mt-0">OUR VALUES</h3>
                            <p className="content m-0">At MBME Pay, our priority is to ensure each customer is getting the right service they deserve. Likewise, we always want to ensure our employees get the respect and job satisfaction they deserve too. As a services provider, we want to exceed customer expectations while creating opportunities for employees to grow with the businesses we serve. We don’t just value our customers; we value each and every person who becomes a part of the MBME Pay family. </p>
                        </div>
                    </Col>
                </Row>
                <Row className="img-with-data field-padd-2">
                    <Col md={12} xs={12} className="position-relative">
                        <Image src={about_banner} fluid alt="about-bann" />
                        <div className="move-item text-center">
                            <h3>MBME PAY CULTURE</h3>
                            <p className="content m-0">Dream big, work hard, stay focused & surround yourself with good people.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="field-padd-2">
                    <Col md={{ span: 10, offset: 1 }}>
                    <h3 className="head-medium-block my-3">WHAT IT MEANS TO WORK HERE</h3>
                    <p className="content m-0 mb-3">Working at MBME Pay is not just about having a job. Every day, we strive toward excellence. Every second of the day is a chance to make life easier and more convenient for businesses and their customers alike.</p>
                    <p className="content m-0 mb-3"> Working at MBME Pay means you’re working toward something much bigger than yourself. It means you will be faced with satisfying challenges you can solve with a dedicated and friendly team. It means understanding different needs, requirements, and personalities. </p>
                    <p className="content m-0 mb-3"> The most important part? You’re a part of a team that is constantly hungry for success, that supports you in challenges, and that constantly pushes the boundaries to deliver the most innovative solutions.  </p>
                    <p className="content m-0 mb-2 text-center">  So, what does it mean to work here? </p>
                    <p className="content m-0 mb-1 text-center">  It means you’re changing the world of quick and easy payments.  </p>
                    <p className="content m-0 mb-3 text-center">  Welcome to the MBME Pay Family! </p>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default PageView;
