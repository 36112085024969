import React, { Component } from "react";
import InnerSlider from "react-slick";
import { Image } from "react-bootstrap";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  credit_icon,
  info_icon,
  kiosk_icon,
  web_icon,
  crm_icon,
  mediap_icon,
  webportl_icon,
  serviceExtn_icon,
  pos_icon,
  mobile_icon,
  website_icon,
} from "../../../images/index";
import "../solution.scss";

class SelfService extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  hadleButtonClick(event) {
    console.log(event);
    this.props.parentTabCallback(event);
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const service = {
      dots: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      speed: 1000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="tabData self-service">
        <div className="each-block">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="head-medium-block">OUR SOLUTIONS</h4>
              <span className="content">All You Need at Your Fingertips </span>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="slickbtn" onClick={this.previous}>
                <i className="arrow left"></i>
              </span>
              <span className="slickbtn" onClick={this.next}>
                <i className="arrow right"></i>
              </span>
            </div>
          </div>

          <InnerSlider ref={(c) => (this.slider = c)} {...service}>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={info_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 mb-0">
                    Information Kiosk
                  </h4>
                  <p className="content blk-content">
                    Our information kiosks are excellent for any industry where
                    customers need self-guidance into their services.{" "}
                  </p>
                  <div className="styled-ul kiosk-features">
                    <span className="content dot">Hotel information</span>
                    <span className="content dot">Production Information</span>
                    <span className="content dot">Credit Information</span>
                    <span className="content dot">Hospital information</span>
                    <span className="content dot">Way Finder Kiosk</span>
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 ">Best For</h4>
                  <div className="styled-ul kiosk-applications">
                    <span className="content dot">Hospital</span>
                    <span className="content dot">Airport</span>
                    <span className="content dot">Shopping mall</span>
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={crm_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 mb-0">
                    Customized CRM
                  </h4>
                  <p className="content blk-content">
                    No need to waste time on bringing your work from previous
                    tools. Import all your data in just a few clicks. Whether
                    you need to manage leads, sales pipelines, or onboard
                    customers, MBME CRM is totally customizable so your team can
                    get started instantly (no training necessary).
                  </p>
                  <div className="styled-ul kiosk-features">
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications"></div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={kiosk_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 mb-0">
                    Payment Kiosk
                  </h4>
                  <p className="content blk-content">
                    Our payment kiosks are safe, user-friendly, and provide a
                    convenient solution to manage funds and transfer money.{" "}
                  </p>
                  <div className="styled-ul kiosk-features">
                    <span className="content dot">Mobile Cash Accepting</span>
                    <span className="content dot">Mobile Cash Dispensing</span>
                    <span className="content dot">Cash Deposits</span>
                    <span className="content dot">Banking Services</span>
                    <span className="content dot">Cheque clearances</span>
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 ">Best For</h4>
                  <div className="styled-ul kiosk-applications">
                    <span className="content dot">
                      Utility Bills & Mobile Top up
                    </span>
                    <span className="content dot">Government Taxes</span>
                    <span className="content dot">Police Fines</span>
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={mediap_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 mb-0">Media Player</h4>
                  <p className="content blk-content">
                    Gain access to tools that bring your screens to life.
                    Securely display dashboards Create custom integrations and
                    apps you already use. Live Broadcast to every screen.{" "}
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={webportl_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 mb-0">Web Portal</h4>
                  <p className="content blk-content">
                    The leading integrated payments solution for unrivaled
                    performance all over UAE, Build the payments solution for
                    your business needs with MBMEpay. Unlike other online
                    payment platforms, it’s modular and customizable. Plus,
                    integrate once and be ready for the future in every market.
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={credit_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal m-0">
                    Card Issuance Kiosk
                  </h4>
                  <p className="blk-content"></p>
                  <div className="styled-ul kiosk-features">
                    <span className="content dot">Customer ID issuance</span>
                    <span className="content dot">Sim Vending</span>
                    <span className="content dot">Car Licenses</span>
                    <span className="content dot">Driving License</span>
                    <span className="content dot">Transport Card issuance</span>
                    <span className="content dot">
                      Wallet, Loyalty Card Issuance
                    </span>
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 ">Best For</h4>
                  <div className="styled-ul kiosk-applications">
                    <span className="content dot">Transport</span>
                    <span className="content dot">Telecom</span>
                    <span className="content dot">Government</span>
                    <span className="content dot">Retail</span>
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={website_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal m-0">Website</h4>
                  <p className="content blk-content">
                    We design and develop tailored websites that can turn the
                    wind in favor of you. The website will bear your brand’s
                    identity. Make it splendid with unique features defining
                    your reliability, credibility, and commitment. Convert users
                    into customers displaying your elegance through creatively
                    designed websites.
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={serviceExtn_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal m-0">Service Extension</h4>
                  <p className="content blk-content">
                    Service extension from MBME is a fully integrated payment
                    solution that simplifies the process of integrating n number
                    of services readily available to connect your platforms and
                    provides your customers with the opportunity to pay their
                    way. Securely reconcile all payment and transaction data
                    within the day – allowing you to manage orders and payments
                    in one place while delivering a seamless checkout. Developed
                    and supported by MBME, you will have access to services that
                    are up-to-date, safe, and stable. By selecting the services
                    - gain peace of mind, optimize your eCommerce business, and
                    free up valuable time to invest in sustainable growth.
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={pos_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal m-0">POS Machine</h4>
                  <p className="content blk-content">
                    The POS allow customers to purchase services from the
                    retailers. The retailers or corporates can place these POS
                    Machines at their branch locations with the benefit of
                    almost no space and will receive profits in terms of
                    commercials offered on monthly sales volume from the POS
                    Machines. The POS Machine also offers the benefit of easy
                    customization with any corporates to offer their own
                    products or services with convenient reconciliation and
                    settlement process. 
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block mg-bottom-32-perc" >
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={web_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 ">
                    Customer Registration Kiosk
                  </h4>
                  <div className="styled-ul kiosk-features">
                    <span className="content dot">
                      Customer Authentication, Registration and Customer
                      Services
                    </span>
                    <span className="content dot">Hotel check-in</span>
                    <span className="content dot">KYC</span>
                    <span className="content dot">HR Management</span>
                    <span className="content dot">Customer Services</span>
                    <span className="content dot">Feedback & Survey</span>
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                <h4 className="head-medium-normal mt-0 ">Best For</h4>
                  <div className="styled-ul kiosk-applications">
                    <span className="content dot">Retail</span>
                    <span className="content dot">Entertainment</span>
                    <span className="content dot">Hospitality</span>
                    <span className="content dot">Corporate</span>
                    <span className="content dot">Government</span>
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={mobile_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 ">Mobile App</h4>
                 <p className="content blk-content">
                  Paying bills through MBME has fabulously simplified, so you
                    don’t waste time in queues or on individual utility
                    websites. We have combined all your utility payments under
                    MBME, so you don’t spend more than a few seconds paying
                    bills. No more wasting time trying to pay your bills
                    individually or struggling to keep track of bill payments.
                    If you’re looking to launch a mobile app or grow your existing mobile app the
                    only platform you will ever need to help run your business,
                    integrated apps, kept simple, and loved by millions of happy
                    users.{" "}
                  </p>
                  <div className="styled-ul kiosk-features"></div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 "></h4>
                  <div className="styled-ul kiosk-applications">
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
            <div className="card-block mg-bottom-32-perc">
              <div className="each-card-block">
                <div className="block-img">
                  <Image src={web_icon} className="ico-img" />
                </div>
                <div className="my-4">
                  <h4 className="head-medium-normal mt-0 ">
                    Entertainment and Access Kiosk
                  </h4>
                  <div className="styled-ul kiosk-features">
                    <span className="content dot">Ticketing and Parking</span>
                    <span className="content dot">Car Park Kiosk</span>
                    <span className="content dot">
                      TVM – Ticket Vending Machines
                    </span>
                    <span className="content dot">
                      Bus & Train Tickets issuing Kiosk
                    </span>
                    <span className="content dot">
                      Movie tickets issuing Kiosk
                    </span>
                  </div>
                </div>
                <div className="my-4 mt-0 pb-4 bord-bottom">
                  <h4 className="head-medium-normal mt-0 ">Best For</h4>
                  <div className="styled-ul kiosk-applications">
                    <span className="content dot">Entertainment & Events</span>
                    <span className="content dot">Cinema</span>
                    <span className="content dot">Parks</span>
                    <span className="content dot">Transport</span>
                    <span className="content dot">Shopping Malls</span>
                  </div>
                </div>
                {/* <div
                  className="loadmore px-3"
                  onClick={() => this.hadleButtonClick("three")}
                >
                  <span>Learn More</span>
                </div> */}
              </div>
            </div>
          </InnerSlider>
        </div>

        {/* <div className="each-block field-padd-3">
                    <h4 className="head-medium-block">PARTNER INSIGHT</h4>
                    <span className="content"> Our clients tell us like it is </span>
                    <div className="partner-slider mt-5">
                        <InnerSlider {...settings}>
                            <div className="each-partner">
                                <div className="items">
                                    <div className="text-center">
                                        <div className="logo-part">
                                            <Image src={partner1} fluid alt="partner 1" className="part-img-1" />
                                        </div>
                                        <h4 className="head-medium-normal mt-0 ">Hamad Al Kaabi - MASDAR</h4>
                                        <p className="content-oneline m-0">Team Leader - Client Relations</p>
                                    </div>
                                    <div>
                                        <p className="m-0 operator">"</p>
                                        <p className="content m-0 my-3 text-center">MBME Pay has helped us differentiate and dominate the changing market. Their team has ensured our business is provided with strong competitive advantages and unique selling propositions to help us run more efficiently.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="each-partner">
                                <div className="items">
                                    <div className="text-center">
                                        <div className="logo-part">
                                            <Image src={partner2} fluid alt="partner 1" className="part-img-1" />
                                        </div>
                                        <h4 className="head-medium-normal mt-0 ">Issa Al Sinani – Etihad Airways</h4>
                                        <p className="content-oneline m-0">Manager HR Customer  Service - People Services</p>
                                    </div>
                                    <div>
                                        <p className="m-0 operator">"</p>
                                        <p className="content m-0 my-3 text-center">MBME Pay are experts in their field, they understand our business needs and have helped produce incremental revenue on a consistent basis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="each-partner">
                                <div className="items">
                                    <div className="text-center">
                                        <div className="logo-part">
                                            <Image src={partner3} fluid alt="partner 1" className="part-img-3" />
                                        </div>
                                        <h4 className="head-medium-normal mt-0 ">Arif Alzarouni - RTA</h4>
                                        <p className="content-oneline m-0">Project Manager <br /> Intelligent Traffic Systems Department</p>
                                    </div>
                                    <div>
                                        <p className="m-0 operator">"</p>
                                        <p className="content m-0 my-3 text-center">MBME Pay has been supplying us with innovative & top-quality self-service and payment solutions for the last 5 years. Their kiosks are reliable, durable and have been tailored to our needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="each-partner">
                                <div className="items">
                                    <div className="text-center">
                                        <div className="logo-part">
                                            <Image src={partner4} fluid alt="partner 1" className="part-img-4" />
                                        </div>
                                        <h4 className="head-medium-normal mt-0 ">Junaid Shareef – Wall Street Exchange</h4>
                                        <p className="content-oneline m-0">Manager - Business Development</p>
                                    </div>
                                    <div>
                                        <p className="m-0 operator">"</p>
                                        <p className="content m-0 my-3 text-center">MBME Pay has been pivotal in the expansion of our business. They have aided us in increasing our customer touch points and have also made the expansion seamlessly by providing us with timely customer and tech support whenever needed.</p>
                                    </div>
                                </div>
                            </div>
                        </InnerSlider>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default SelfService;
