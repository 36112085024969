import React, { Component } from 'react';
import { Nav, Col, Row, Tab, Container } from 'react-bootstrap';
import SmartGovernment from './tabs/smart-govt';
import MBMESoftware from './tabs/software';
import Govera from './tabs/govera';
import ManagedServices from './tabs/service';
import SelfService from './tabs/self-service';
import Kiosk from './tabs/models';
import './solution.scss';

class SolutionsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'one'
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectTab = this.handleSelectTab.bind(this);
    }

    handleSelectTab(event) {
        this.props.parentCallback(event);
    }

    handleSelect(event) {
        this.setState({ key: event});
    }

    render() {
        return (
            <Tab.Container id="solution" activeKey={this.state.key} onSelect={(event) => this.handleSelect(event)}>
                <div className="field-padd-3 solution-wrapper">
                    <Container>
                        <Row>
                            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                <Nav variant="pills" className="sol-part-tab-header">
                                    <Nav.Item>
                                        <Nav.Link eventKey="one">Our Solutions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="two">Smart Government </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="three">Kiosk Models</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four"> MBME Pay Software </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="five"> Managed Services </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="six"> MBME Pay APP </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>

                    <div className="no-padd">
                        <Tab.Content>
                            <Tab.Pane eventKey="one">
                                <Container>
                                    <Row>
                                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                            <SelfService parentTabCallback={this.handleSelect} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="two">
                                <Container>
                                    <Row>
                                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                            <SmartGovernment />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="three">
                                <Kiosk />
                            </Tab.Pane>
                            <Tab.Pane eventKey="four">
                                <Container>
                                    <Row>
                                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                            <MBMESoftware />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="five">
                                <Container>
                                    <Row>
                                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                            <ManagedServices />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="six">
                                <Container>
                                    <Row>
                                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                            <Govera />
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        );
    }
}

export default SolutionsView;
