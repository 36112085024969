import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './about.scss';
import PageBanner from './banner';
import ClientSlider from '../common/client-slider';
import { about_our_mission,  lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';




class Mission extends Component {

 

  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner />
        <div className="position-block position-relative">
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <Container className="pageview">
            <Row>
            <Col  className="col-md-10 col-sm-12 col-12 offset-md-1 mgt-10">
                <Row className="img-with-data field-padd-2" id="two">
                  <Col md={7} xs={12} className="position-relative">
                    <Image src={about_our_mission} fluid alt="our-mission" />
                  </Col>

                  <Col md={5} xs={12} className="position-relative">
                    <h5 className="head-no-bold mb-0 mt-3">OUR</h5>
                    <h4 className="head-bold mb-4 mt-0">MISSION</h4>

                    <span className="quotes">"</span>
                    <p className="content vision-content">
                    Our mission at MBME Pay is to stay at the forefront of pioneering technological advancements and to always provide our clients with class-leading solutions for optimal convenience, efficiency, security, and trust. We strive to always deliver above and beyond our customers’ expectations. 
                            </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <ClientSlider />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Mission;
