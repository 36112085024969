import React, { Component } from 'react';
import { Button, Col, Form, Row, Image, Spinner } from 'react-bootstrap';
import { sendmsgblack, sad, smile } from '../../../images/index';
import API from '../../../api';
import '../retailer.scss';

class BecomeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: false,
			message: 'hide',
			responseMessage: '',
			popup: '',
			fields: {},
			errors: {},
			fieldDisable: true
		}
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["firstName"]) {
			formIsValid = false;
			errors["firstName"] = "* First Name cannot be empty";
		}
		if (typeof fields["firstName"] !== "undefined") {
			if (!fields["firstName"].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors["firstName"] = "* Only letters";
			}
		}

		if (!fields["lastName"]) {
			formIsValid = false;
			errors["lastName"] = "* Last Name cannot be empty";
		}
		if (typeof fields["lastName"] !== "undefined") {
			if (!fields["lastName"].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors["lastName"] = "* Only letters";
			}
		}

		if (!fields["company"]) {
			formIsValid = false;
			errors["company"] = "* Company Name cannot be empty";
		}
		if (typeof fields["company"] !== "undefined") {
			if (!fields["company"].match(/^[a-zA-Z]+$/)) {
				formIsValid = false;
				errors["company"] = "* Only letters";
			}
		}

		if (!fields["message"]) {
			formIsValid = false;
			errors["message"] = "* Message cannot be empty";
		}
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "* Email address Cannot be empty";
		}
		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');
			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["email"] = "* Email is not valid";
			}
		}

		console.log(errors);
		this.setState({
			errors: errors
		});

		return formIsValid;
	}

	contactSubmit(e) {
		e.preventDefault();

		this.setState({
			loader: true,
		});

		if (this.handleValidation()) {
			this.state.fields.type = 3;
			console.log(this.state.fields);

			API.post(`/setEnquiry`, this.state.fields)
				.then(res => {
					this.setState({
						loader: false,
						responseMessage: res.data.message,
						message: 'show',
						popup: 'success'
					});
					this.resetForm();
				}, (error) => {
					this.setState({
						loader: false,
						responseMessage: error.message,
						message: 'show',
						popup: 'error'
					});
					this.resetForm();
				});
		} else {
			console.log('error');
			setTimeout(() => {
				this.setState({
					loader: false
				})
			}, 1000);
		}
	}

	onHidePopup = () => {
		this.setState({
			message: 'hide'
		});
	}

	resetForm = () => {
		this.refs.firstName.value = '';
		this.refs.email.value = '';
		this.refs.lastName.value = '';
		this.refs.category.value = '';
		this.refs.other.value = '';
		this.refs.message.value = '';
		this.setState({
			fields: {}
		})
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		if (e.target.value === 'Other') {
			this.setState({
				fieldDisable: false
			});
		}
		fields[field] = e.target.value;
		this.setState({
			fields
		});
	}

	render() {
		return (
			<div>
				<div>
					<div className={"popup " + this.state.message + " " + this.state.popup}>
						<div className="pop-inner">
							<Image src={this.state.popup === 'success' ? smile : sad} />
							<h5>{this.state.popup === 'success' ? 'success' : 'error'}</h5>
							<p>{this.state.popup === 'success' ? this.state.responseMessage : 'Go Back to Resubmit'}</p>
							<Button onClick={this.onHidePopup} className={this.state.popup}>Ok</Button>
						</div>
					</div>
					<div className={"overlay " + this.state.message} onClick={this.onHidePopup}></div>
				</div>

				<div className="tabData self-service">
					<div className="each-block">
						<Row className="data-card">
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block">
								<h4 className="head-medium-normal u-case"> BECOME A PART OF THE MBME Pay NETWORK. </h4>
								<div className="content">MBME Pay is the largest payment and government services platform in the UAE, our current network exists of 1,000+ locations. By joining our network, you will be able to offer your customers a more engaging relationship and generate more revenue for your business.</div>
								<div className="content">MBME Pay smart kiosks is a revolutionary, one-stop, unique self-service platform. The only kiosk offering more than 30 services at one point – from transport, telecoms, charity and payment services – making the lives of your customer simpler. </div>
							</Col>
						</Row>


						<Form className="mt-5 form-block" onSubmit={this.contactSubmit.bind(this)}>
							<h4 className="head-medium-normal m-0"> Become MBME Pay Retailer </h4>
							<div className="content">Fill out the form and our team will contact you directly.</div>
							<Row className="mbme-form">
								<Col xl={6} lg={6} md={6} sm={12} xs={12}>
									<Form.Group controlId="contactForm.FirstName">
										<Form.Control type="text" ref="firstName" placeholder="First Name" onChange={this.handleChange.bind(this, "firstName")} value={this.state.fields["firstName"] || ""} />
										<span className="error">{this.state.errors["firstName"]}</span>
									</Form.Group>
									<Form.Group controlId="contactForm.LastName">
										<Form.Control type="text" placeholder="Last Name" ref="lastName" onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"] || ""} />
										<span className="error">{this.state.errors["lastName"]}</span>
									</Form.Group>
									<Form.Group controlId="contactForm.EmailAddress">
										<Form.Control type="text" placeholder="Email address" ref="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"] || ""} />
										<span className="error">{this.state.errors["email"]}</span>
									</Form.Group>
									<Form.Group controlId="contactForm.Title">
										<Form.Control type="text" placeholder="Title" ref="title" onChange={this.handleChange.bind(this, "title")} value={this.state.fields["title"] || ""} />
										<span className="error">{this.state.errors["title"]}</span>
									</Form.Group>
									<Form.Group controlId="contactForm.CompanyName">
										<Form.Control type="text" placeholder="Company Name" ref="company" onChange={this.handleChange.bind(this, "company")} value={this.state.fields["company"] || ""} />
										<span className="error">{this.state.errors["company"]}</span>
									</Form.Group>

								</Col>
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="form-block position-relative">
									<Form.Group controlId="contactForm.Category" >
										<Form.Control as="select" ref="category" onChange={this.handleChange.bind(this, "category")} value={this.state.fields["category"] || ""} disabled={this.state.fieldDisable === true ? false : true}>
											<option>Select a category</option>
											<option value="Mini-Mart">Mini-Mart</option>
											<option value="Grocery">Grocery</option>
											<option value="Shops">Shops</option>
											<option value="Restaurant">Restaurant</option>
											<option value="Mobile Shop">Mobile Shop</option>
											<option value="Cafe">Cafe</option>
											<option value="Other">Other</option>
										</Form.Control>
										<span className="error">{this.state.errors["category"]}</span>
									</Form.Group>

									<Form.Group controlId="contactForm.category" className={this.state.fieldDisable === true ? 'hidden' : 'block'}>
										<Form.Control type="text" ref="other" placeholder=" Other Category" onChange={this.handleChange.bind(this, "other")} value={this.state.fields["other"] || ""} disabled={this.state.fieldDisable === true ? true : false} />
										<span className="error">{this.state.errors["other"]}</span>
									</Form.Group>

									<Form.Group controlId="contactForm.Message" className="position-relative">
										<Form.Control as="textarea" ref="message" rows="3" placeholder="Message" onChange={this.handleChange.bind(this, "message")} value={this.state.fields["message"] || ""} />
										<span className="error">{this.state.errors["message"]}</span>
										<span className="send-button">
										<button>
											<Image src={sendmsgblack} fluid alt="send msg" className="sendico" />
										</button>
									</span>
									</Form.Group>
									{this.state.loader === true ? (<Spinner animation="border" size="sm" />) : ('')}
								</Col>
							</Row>
						</Form>

					</div>
				</div>
			</div>
		);
	}
}

export default BecomeForm;
