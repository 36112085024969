import React, { Component } from 'react';
import InnerSlider from "react-slick";
import { Row, Col, Image } from 'react-bootstrap';
import {
  Offer_plane,
  offer_yea,
  Offer_Charity,
  Offer_Entertainment,
  Offer_Government,
  Offer_money,
  Offer_topup,
  Offer_Transport,
  Offer_Utility,
  Offer_Education,
  prev_arrow,
  next_arrow
} from '../../images/index';

class Weoffer extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      className: "center",
      infinite: true,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        }
      ]
    };
    return (
      <div>
        <Row>
          <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
            <h5 className="space-letter wow bounceInUp" data-wow-delay="0.15s">WE OFFER</h5>
          </Col>
        </Row>

        <Row className="justify-content-end ipadrow">
          <Col md={4} xs={12} className="pl-0">
            <h4 className="my-4" data-aos="fade-right" data-aos-duration="1000">
              <p className="gra-uline position-relative"> 13+ Years of </p>
              <p> Industry experience </p>
            </h4>
            <p className="content" data-aos="fade-left" data-aos-duration="1000">We’ve worked with a number of diverse industries for many years, allowing us to deliver bespoke services for your unique business. Here’s what we offer: </p>
          </Col>
          <Col md={7} xs={12} className="offer-slider">
            <div className="d-flex justify-content-end align-items-center">
              <span className="slickbtn" onClick={this.previous}>
                <i className="arrow left"></i>
              </span>
              <span className="slickbtn" onClick={this.next}>
                <i className="arrow right"></i>
              </span>
            </div>
            <InnerSlider ref={c => (this.slider = c)} {...settings}>
              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_plane} alt="plane" />
                </div>
                <h4 className="head-medium">Airline</h4>
                <p className="content-hash ">Travel is now more accessible than ever with MBME Pay airline ticket payment services.</p>
              </div>
              <div className="off-card offer-card-yea">
                <div className="pathline-plane">
                  <Image fluid src={offer_yea} alt="a" />
                </div>
                <h4 className="head-medium">Telecom</h4>
                <p className="content-hash ">Communicating has never been this easy with the MBME Pay telecom services provider.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Entertainment} alt="plane" />
                </div>
                <h4 className="head-medium">Entertainment</h4>
                <p className="content-hash ">Seamless ticketing for entertainment so your customers can skip the queues. </p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Utility} alt="plane" />
                </div>
                <h4 className="head-medium">Utility</h4>
                <p className="content-hash ">Pay your utilities easily, effortlessly, and without a glitch.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Education} alt="plane" />
                </div>
                <h4 className="head-medium">Education</h4>
                <p className="content-hash ">Our state-of-the-art technology sparks industry-leading solutions for the education industry.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Charity} alt="plane" />
                </div>
                <h4 className="head-medium">Charity</h4>
                <p className="content-hash ">Want to help those in need? MBME Pay is the perfect solution for secure money transfers to charities.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_money} alt="plane" />
                </div>
                <h4 className="head-medium">Money & Transfer</h4>
                <p className="content-hash ">Safely manage your funds and transfer money using our secure MBME Pay system.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Government} alt="plane" />
                </div>
                <h4 className="head-medium">Government</h4>
                <p className="content-hash ">In collaboration with the UAE government, MBME Pay enhances and improves processes while decreasing request turnaround times.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_Transport} alt="plane" />
                </div>
                <h4 className="head-medium">Transport</h4>
                <p className="content-hash ">MBME Pay is your one-stop ticketing and transaction solution to make transport an accessible, user-friendly experience for you and your customers.</p>
              </div>

              <div className="off-card offer-card-plane">
                <div className="pathline-plane">
                  <Image fluid src={Offer_topup} alt="plane" />
                </div>
                <h4 className="head-medium">International Top Up</h4>
                <p className="content-hash ">Make international transactions easy and affordable with MBME Pay.</p>
              </div>

            </InnerSlider>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Weoffer;
