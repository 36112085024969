import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { utility_1, utility_2, utility_3, utility_4, utility_5, utility_6, utility_7 } from '../../../images/index';
import cImg from '../../../images/billers-logos/c.png';
import '../partner.scss';

class Utility extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0"> UTILITIES </h4>
            <p className="content">The Easiest Way to Pay Bills</p>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image style={{width: "7%"}} src={utility_1} alt="utility 1" fluid className="utility-one mr-5"/>
              <Image style={{width: "7%"}} src={utility_2} alt="utility 2" fluid className="utility-two mr-5"/>
              <Image style={{width: "7%"}} src={utility_3} alt="utility 3" fluid className="utility-three mr-5"/>
              <Image style={{width: "7%"}} src={utility_4} alt="utility 4" fluid className="utility-four mr-5"/>
              <Image style={{width: "7%"}} src={cImg} alt="utility 5" fluid className="utility-five mr-5"/>
              <Image style={{width: "7%"}} src={utility_6} alt="utility 6" fluid className="utility-six mr-5"/>              
              <Image style={{width: "7%"}} src={utility_7} alt="utility 7" fluid className="utility-seven mr-5"/>
            </div>
            <p className="content">
            Utility payments are an inevitable and necessary part of our daily lives. However, opening hours, parking difficulties, and office locations can make it challenging to make the necessary payments. That is until the MBME Pay utility payment services! We combine the speed of automation with convenient and accessible locations to provide you with a utility payment solution. 
            </p>
            <p className="content">
            Now, billing offices can focus on customer services and issues without worrying about processing payments. By allowing full bill payments and fines, our MBME Pay kiosks act as automated extensions of billing office departments with the convenience of self-service.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Utility;
