import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { money_1, money_2, money_3, money_4 } from '../../../images/index';
import edirham from  '../../../images/billers-logos/edirham.png';
import ajmanPay from  '../../../images/billers-logos/ajman-pay.png';
import eWallet from  '../../../images/billers-logos/e-wallet.png';
import rImg from  '../../../images/billers-logos/r.png';
import kLogo from  '../../../images/billers-logos/k.png';
import dcCash from  '../../../images/billers-logos/dcCash.png';
import nbcLogo from  '../../../images/billers-logos/nbc.png';
import '../partner.scss';

class Money extends Component {

  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case"> MONEY & TRANSFER</h4>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image src={kLogo} alt="transport 1" fluid className="money-one mr-4" />
              <Image src={dcCash} alt="transport 2" fluid className="money-two mr-4" />
              <Image src={nbcLogo} style={{maxHeight: '80px'}} alt="transport 1" fluid className="money-three mr-4" />
              <Image src={edirham} style={{maxHeight: '80px'}} alt="transport 2" fluid className="mr-4"/>
              <Image src={ajmanPay} style={{maxHeight: '80px'}} alt="transport 2" fluid className="mr-4" />
              <Image src={eWallet} style={{width: '10%'}} alt="transport 2" fluid className="mr-4" />
              <Image src={rImg} alt="transport 2" fluid className="mr-4" />
            </div>
            <p className="content">
            Registered customers now have a convenient way to remit transactions directly from the kiosk. All you need to do is visit your chosen exchange house branch once to register your Know Your Customer (KYC) data along with the required beneficiaries. Once this is completed, you can easily access your remittance services from any of our kiosks.
            </p>
            <p className="content">
            Managing and transferring funds is now easier, more effective, and completely safe with MBME Pay. 
             </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Money;
