import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollIntoView from './components/common/ScrollIntoView';
import Header from './components/common-layout/header';
import Footer from './components/common-layout/footer';
import ErrorPage from './components/common-layout/pagenotfound';
import ComingSoon from './components/common-layout/comingsoon';
import Home from './components/home/index';
import Solutions from './components/solutions/index';
import Partners from './components/partners/index';
import Retailers from './components/retailers/index';
import Contact from './components/contact-us/index';
import Location from './components/location/index';
import Careers from './components/careers/index';
import PrivacyPolicy from './components/privacy-policy/index';
import TermsCondition from './components/terms-condition/index';
import AboutUs from './components/about-us';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { loadReCaptcha } from 'react-recaptcha-v3';
import AdminLogin from './components/admin/login';
import Newsletter from './components/admin/newsletter';
import Enquiry from './components/admin/enquiry';
import Partner from './components/admin/partner';
import Retailer from './components/admin/retailer';
import ChangePassword from './components/admin/settings';
import Help from './components/help';
import Confirm from './components/common-layout/confirm';
import Vision from './components/about-us/vision';
import Mission from './components/about-us/mission';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => (localStorage.getItem("usertoken") ? <Component {...props} /> : <Redirect to='/admin-login' />)} />
)

class App extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			className: 'header-bg-transparent',
		};
	};

	componentDidMount() {
		window.addEventListener('scroll', this.listenScrollEvent);
		loadReCaptcha('6LdMJrMZAAAAAEXIqyO9l86930NmPqiUd-6crPNu', () => {
		});
	}

	listenScrollEvent = e => {
		if (window.scrollY > 100) {
			this.setState({ className: 'header-bg-white' })
		} else {
			this.setState({ className: 'header-bg-transparent' })
		}
	}

	render() {
		const history = createBrowserHistory();
		const pagename = history.location.pathname.replace(/\//g, "");

		return (
			<div className={pagename}>
				<Router history={history}>
					<ScrollIntoView>

						{(pagename !== 'confirmnewsletter' ? <div className={"header-block " + this.state.className}> <Header /></div> : "")}

						<div className="wrapper">
							<Switch>
								<Route path={"/"} exact component={Home} />
								<Route path={"/solutions"} component={Solutions} />
								<Route path={"/partners"} component={Partners} />
								<Route path={"/retailers"} component={Retailers} />
								<Route path={"/contact-us"} component={Contact} />
								<Route path={"/location"} component={Location} />
								<Route path={"/careers"} component={Careers} />
								<Route path={"/privacy-policy"} component={PrivacyPolicy} />
								<Route path={"/terms-condition"} component={TermsCondition} />
								<Route path={"/about-us"} component={AboutUs} />
								<Route path={"/help"} component={Help} />
								<Route path={"/about-us/:mission"} component={AboutUs} />
								<Route path={"/about-us/:vission"} component={AboutUs} />
								<Route path={"/vision"} component={Vision} />
								<Route path={"/mission"} component={Mission} />
								<Route path={"/confirm/newsletter"} component={Confirm} />
								<Route path={"/coming-soon"} component={ComingSoon} />
								<Route path={"/admin-login"} component={AdminLogin} />
								<PrivateRoute path={"/admin/newsletter"} component={Newsletter} />
								<PrivateRoute path={"/admin/contact"} component={Enquiry} />
								<PrivateRoute path={"/admin/retailer"} component={Retailer} />
								<PrivateRoute path={"/admin/partner"} component={Partner} />
								<PrivateRoute path={"/admin/change/password"} component={ChangePassword} />
								{(pagename !== 'confirmnewsletter' ? <Route component={ErrorPage} /> : "")}
							</Switch>
						</div>

						{(pagename !== 'confirmnewsletter' ? <div className="footer-main"><Footer /></div> : "")}

					</ScrollIntoView>
				</Router>
			</div>
		);
	}
}

export default App;
