
import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import './timeline.scss';
import {
    circleborder, milestone_1, milestone_2,
    milestone_2007,
    milestone_2008,
    milestone_2009,
    milestone_2010,
    milestone_2011,
    milestone_2012,
    milestone_2013,
    milestone_2014,
    milestone_2015,
    milestone_2016,
    milestone_2017,
    milestone_2019,
    milestone_2020,
    milestone_3,
    milestone_4,
    milestone_5,
} from '../../images/index';


class Timeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentYear: 2007,
            activeLink: null,
            year: [
                { year: 2007, className: "year-btn", id: "1" },
                { year: 2008, className: "year-btn", id: "2" },
                { year: 2009, className: "year-btn", id: "3" },
                { year: 2010, className: "year-btn", id: "4" },
                { year: 2011, className: "year-btn", id: "6" },
                { year: 2012, className: "year-btn", id: "7" },
                { year: 2013, className: "year-btn", id: "8" },
                { year: 2014, className: "year-btn", id: "9" },
                { year: 2015, className: "year-btn", id: "10" },
                { year: 2016, className: "year-btn", id: "11" },
                { year: 2017, className: "year-btn", id: "12" },
                { year: 2018, className: "year-btn", id: "13" },
                { year: 2019, className: "year-btn", id: "14" },
                { year: 2020, className: "year-btn", id: "15" },
                { year: 2021, className: "year-btn", id: "16" },
            ]
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(data) {
        this.setState({
            currentYear: data.year,
            activeLink: data.id
        });
    }

    render() {

        if (this.state.year.length !== 0) {
            var yearButtons = this.state.year.map((data, i) => {
                return <div className="yearButtons" key={data.id}>  <button className={data.className + (data.id === this.state.activeLink ? " active_item" : "")} onClick={() => this.handleClick(data)} onMouseEnter={() => this.handleClick(data)} onMouseLeave={() => this.handleClick(data)}>{data.year}</button> <span className={data.id === this.state.activeLink ? " pulse" : "hidden"}></span>
                </div>
            });
        }

        return (
            <Container> 
            <Row className="milestone-wrapper">
                <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <div className="main-head position-relative">
                    <h2>Milestones</h2>
                    <span>Milestones</span>
                </div>
                <Row className="align-items-center justify-content-between my-5">
                    <Col md={6}>
                    <div className="circleblock position-relative">
                        <Image className="border_circle" src={circleborder} alt="" fluid />
                        <span className="inner_circle">
                        <p>{this.state.currentYear}</p>
                        </span>
                        <div className="cirlce_buttons">
                        <div className="year-click-btn">
                            {yearButtons}
                        </div>
                        </div>
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="milestone-card">
                        {(
                        this.state.currentYear === 2007 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2007} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            Max Box Middle East is founded in Abu Dhabi.
                        </p>
                        </div>
                        : this.state.currentYear === 2008 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2008} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            FEWA added to the platform allowing customers to pay their utility bills.
                        </p>
                        </div>
                        : this.state.currentYear === 2009 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2009} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME added its 100th kiosk to its network, bringing customers more convenience.
                        </p>
                        </div>
                        : this.state.currentYear === 2010 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2010} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME participates and showcases at Cards and Payments, the leading financial and payment event in the Middle East.
                        </p>
                        </div>
                        : this.state.currentYear === 2011 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2011} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME adds its 15th payment biller to its platform to become the preferred choice for self-service payments.
                        </p>
                        </div>
                        : this.state.currentYear === 2012 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2012} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME extends it reach to Fujairah and other remote, broadening its reach and making it easier for businesses to connect with their customers.
                        </p>
                        </div>
                        : this.state.currentYear === 2013 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2013} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            Etisalat is added to the network, allowing customers to top up and pay bills.
                        </p>
                        </div>
                        : this.state.currentYear === 2014 ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2014} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME produces kiosks with SIM vending for businesses to use.
                        </p>
                        </div>
                        : this.state.currentYear === 2015
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2015} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME completes integration with leading credit card networks VISA and MasterCard, to offer customers card payments.
                        </p>
                        </div>
                        : this.state.currentYear === 2016
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2016} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME launches the 1st entertainment ticketing kiosk for Ferrari World.
                        </p>
                        </div>
                        : this.state.currentYear === 2017
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2017} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME launches its 800th machine. Trusted by millions for all payments, MBME offers secure, convenient and easy payments across the UAE.
                        </p>
                        </div>
                        : this.state.currentYear === 2018
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="img-row">
                            <Image src={milestone_1} fluid alt="milestone 1" />
                            <Image src={milestone_2} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            MBME Pay proudly announce our collaboration with USE PASS and Smart Pass
                        </p>
                        </div>
                        : this.state.currentYear === 2019
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2019} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                            Live on all our fleet of kiosk
                        </p>
                        </div>
                        : this.state.currentYear === 2020
                        ? 
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="timeline-logo">
                            <Image src={milestone_2020} fluid alt="milestone 1" />
                        </div>
                        {/* <p className="content">
                            MBME PAY launched Govera Payments Application
                        </p> */}
                        </div>
                        : this.state.currentYear === 2021
                        ?
                        <div>
                        <h5 className="curryear">{this.state.currentYear}</h5>
                        <div className="img-row">
                            <Image src={milestone_3} fluid alt="milestone 1" />
                            <Image src={milestone_5} fluid alt="milestone 1" />
                        </div>
                        <p className="content">
                        MBME Pay proudly announcing the partnership with CBD and FAB to enable eDirham cards top-up through our fleet of Kiosks.</p>
                        <p className="content">MBME Pay delighted to have SEWA Bill Payment services now on board.</p>
                        
                        </div>
                        :""
                        )}
                    </div>
                    </Col>
                </Row>
                </Col>
            </Row>
            </Container>
        );
    }
}

export default Timeline;
