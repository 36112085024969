import React, { Component } from 'react';
import '../solution.scss';

class SmartGovernment extends Component {
    render() {
        return (
            <div className="tabData">
                <h4 className="head-medium-block">SMART GOVERNMENT SOLUTIONS</h4>
                <span className="content">The Smart Choice for Government Operations </span>
                <p className="content my-4">
                In partnership with the UAE government, they’re now able to utilize modern tools efficiently and across all spheres. MBME Pay takes the government into the future with solutions and collaboration that sets the course for prosperity and sustainable development. 
                </p>
                <p className="content">
                Through long-term planning, creative initiatives, and ground-breaking projects, the MBME Pay can effectively cater to governmental needs. This enhances and improves processes while streamlining turnaround times for requests. 
                </p>
                <p className="content">
                The result? With the latest technologies and collaboration with MBME Pay, the UAE government is an attractive model for progress seekers. 
                </p>
            </div>
        );
    }
}

export default SmartGovernment;
