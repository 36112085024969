import React, { Component } from 'react';
import Slider from "react-slick";
import { Row, Col, Image } from 'react-bootstrap';
import { machine, machinepic_two,machinepic, machine_three, right_line,ultraWhiteKiosk, KioskCompactWht, KioskAmericanoWhite } from '../../images/index';


class Soution extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 500,
            pauseOnHover: false,
            pauseOnFocus: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 3,
                        initialSlide: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 3
                    }
                }
            ]
        };
        return (
            <div>
                <Row>
                    <Col className="text-center" data-aos="fade-up" data-aos-duration="2800">
                        <h4 className="my-4" >
                            <p className="gra-uline position-relative">Providing a Bespoke, Customizable Solution</p>
                        </h4>
                        <p className="content">Get an extensive portfolio of reliable and adaptable kiosk solutions for your business. Our kiosks are extensively tested, robust, and ready to make your business operations easier. With an impressive track record, reliability, and mission-critical field service performance, MBME Pay already supports millions of monthly transactions in the UAE. Furthermore, MBME Pay offers bespoke solutions that include state-of-the-art generic kiosks, customized kiosks, service extensions, and app development to ensure customers enjoy efficient payment solutions wherever they are. </p>
                    </Col>
                </Row>
                <Row className="content-section">
                    <Col md={12} xs={12} className="machine-blk py-5">
                        <div className="sol-data">
                            <h4 data-aos="fade-right" data-aos-duration="2800">
                                <p>Our solutions offer design choices</p>
                                <p>and full feature bill payment software.</p>
                            </h4>

                        </div>
                        
                        <div className="mach-sec text-center position-relative">
                        <Slider ref={c => (this.slider = c)} {...settings}>
                            <div className="kioskImage">
                            <Image src={ultraWhiteKiosk} alt="machine" fluid />
                            </div>
                            <div className="machine3">
                            <Image  src={KioskCompactWht} alt="machine" fluid />
                            </div>

                            <div className="kioskImage">
                            <Image  src={KioskAmericanoWhite} alt="machine" fluid />
                            </div>

                        </Slider>
                        </div>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default Soution;
