import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../partner.scss';

class More extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case">  Information and services</h4>
            <p className="content"> You want to give the best possible service to your customers, but it takes a lot of resources to answer each query individually. With MBME Pay’s self-service kiosks, you can save time and money on resources and instead provide customers with a self-service source of information.  </p>
            <p className="content">All our self-service kiosks can be updated remotely and offer simple, user-friendly interfaces for your customers. This is not only more convenient for you but is also a very attractive option for new customers who want easy, accessible information.</p>
            <p className="content">Each kiosk is electronically powered and controlled with touch screen interaction, giving customers access to a wide variety of information from transportation tables to local services. </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case">  Extended range </h4>
            <p className="content"> Have a small space? Don’t have the capacity to showcase your entire range? MBME Pay kiosks offer an innovative and space-friendly way for customers to interact with and view your entire range. What’s more, if you have an exciting demo you want to showcase, our kiosks have built-in media players that allow you to do just that! You can even add payment services to your customized kiosk, offering customers end-to-end services – from browsing to buying.  </p>
            <p className="content"> We create a customizable interface linked to your website or product catalogue, ensuring your kiosk is unique to your business theme. Have more than one store? MBME Pay can replicate each kiosk to be uniform in all your retail stores.</p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case"> Issuance </h4>
            <p className="content"> Want to take customer service to the next level? Enter the latest generation of Smart kiosks, equipped with instant issuance capabilities allowing instant and personalized products. What’s more, these kiosks have control of full Know Your Customer (KYC) data including biometrics, signature pads, camera, passport, and document scanners. </p>
            <p className="content">These kiosks can produce output options including SIM cards, customized packs, plastic cards, entry armbands, and more with a wide range of designs stored within the secured peripherals within the kiosk itself. </p>
            <p className="content">Elevate your business to the max with a new level of self-service. </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case"> Loyalty </h4>
            <p className="content"> Want to give your loyal customers a convenient way to access rewards? Enhance your customer loyalty scheme with instant access to redeemable reward plans to all cardholders. Our point system captures valuable insight into shopping habits, which customizes customer’s shopping and reward experience. </p>
            <p className="content"> Now, you can have a dedicated kiosk where customers can check their points balance, transaction history, and more. You can even create questionnaires for valuable feedback from your valued customers.</p>
            <p className="content"> 
            The best part? These services are directly integrated into your back-end system, allowing regular content updates.</p>
          </Col>
        </Row>
       </div> 
    );
  }
}

export default More;
