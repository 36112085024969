import React, { Component } from 'react';
import API from '../../api';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { sendmsg } from '../../images/index';
import { sad, smile } from '../../images/index';

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            message: 'hide',
            responseMessage: '',
            popup: '',
            fields: {},
            errors: {}
        }
    }

    validate() {
        let fields = this.state.fields;
        let errors = {};
        let isFormValid = true;

        if (!fields["email"]) {
            isFormValid = false;
            errors["email"] = "* Email address can not be empty";
        }
        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                isFormValid = false;
                errors["email"] = "* Email is not valid";
            }
        }
        this.setState({
            errors: errors
        });
        return isFormValid;
    }

    addNewsletter(e) {
        e.preventDefault();
        if (this.validate()) {
            this.setState({
                loader: true,
            });
        API.post(`/setSubscription`, this.state.fields)
            .then(res => {
                this.setState({
                    loader: false,
                    responseMessage: res.data.message,
                    popup: 'success',
                    message: 'show'
                });
                this.resetForm();
            }, (error) => {
                this.setState({
                    loader: false,
                    responseMessage: error.message,
                    popup: 'error',
                    message: 'show'
                });
                this.resetForm();
            });
        }
    }

    resetForm = () => { 
        this.refs.email.value = '';
        this.setState({
            fields: {}
        })
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            fields
        });
    }

    onHidePopup = () => {
        this.setState({
            message: 'hide'
        });
    }

    render() {
        return (
            <div>
                <div>
                    <div className={"popup " + this.state.message + " " + this.state.popup}>
                        <div className="pop-inner">
                            <Image src={this.state.popup === 'success' ? smile : sad} />
                            <h5>{this.state.popup === 'success' ? 'success' : 'error'}</h5>
                            <p>{this.state.popup === 'success' ? this.state.responseMessage : 'Go Back to Resubmit'}</p>
                            <Button onClick={this.onHidePopup} className={this.state.popup}>Ok</Button>
                        </div>
                    </div>
                    <div className={"overlay " + this.state.message} onClick={this.onHidePopup}></div>
                </div>

                <Row>
                    <Col md={{ span: 10, offset: 1 }} className="news-letter text-center">
                        <h4>Subscribe to Newsletter</h4>
                        <p className="content">Enter your email address to receive exclusive offers, updates, and more! </p>
                        <div className="ip-field">

                            <Form id="newsletter" className="mbme-form mt-4 position-relative" onSubmit={this.addNewsletter.bind(this)}>
                                <Form.Group controlId="Newsletter.EmailAddress">
                                    <Form.Control type="text" placeholder="Email email address" ref="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                    <span className="error">{this.state.errors["email"] || ""}</span>
                                </Form.Group>
                                <div className="send-btn">
                                    <button className="btn btn-lg pro hidd-xs" id="submit" value="Submit">
                                        <Image fluid src={sendmsg} alt="sendmsg" />
                                    </button>
                                    <button className="btn btn-lg pro hidd-larg" id="submit" value="Submit">
                                        SUBMIT
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Newsletter;
