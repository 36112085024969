
import React, { Component } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import './careers.scss';
import { career_pic } from '../../images/index';

class PageBanner extends Component {
  render() {
    return (
      <div className="banner-bg position-relative">
        <Image fluid src={career_pic} alt="page-banner" />
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="banner-content-align">
              <h3 className="page-title">Careers</h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PageBanner;
