
import React, { Component } from 'react';
import { Container, Image, Col, Row } from 'react-bootstrap';
import './about.scss';
import { about_us_banner } from '../../images/index';

class PageBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {bannerTitle:"About Us"}
   
  }

  componentDidMount() {
    let urlName = window.location.pathname;
    this.setState({ bannerTitle: (urlName==='/vision') ? "Our Vision" : (urlName==='/mission') ? "Our Mission" : "About Us" });
  }

  render() {
    return (
      <div className="banner-bg position-relative">
        <Image fluid src={about_us_banner} alt="page-banner" />
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="banner-content-align">
              <h3 className="page-title">{ this.state.bannerTitle}</h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PageBanner;
