import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { pay_partner, options_partner, brand_partner, cash_partner } from '../../../images/index';
import '../partner.scss';

class Benifit extends Component {

  render() {
    return (
      <div className="tabData self-service">
        <div className="each-block">
          <h4 className="head-medium-normal"> YOU & YOUR BUSINESS WILL BENEFIT FROM</h4>
          <Row className="mt-3 data-card">
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={cash_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">BETTER CASH FLOW</h4>
                  <p className="content m-0">Don’t worry about opening another location. Get paid faster and extend your business as your bill payments are directly deposited into your account and cleared the very next day. </p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={options_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">Additional Options For Customers</h4>
                  <p className="content m-0">Give your customers a more convenient payment option with over 1000 touch points that are also available at remote areas where payment channels are limited. </p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={pay_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">More efficient cash collection</h4>
                  <p className="content m-0">Don’t worry about chasing bills any longer. Now, you can concentrate on your core business responsibilities while our services take care of the rest.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={brand_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">Increase your brand presence</h4>
                  <p className="content m-0">You can increase your brand awareness and information across more than 1000 locations, giving you the competitive edge you need to succeed. </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Benifit;
