import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { charity_1, charity_2, charity_3, charity_4 } from '../../../images/index';
import awqaf from '../../../images/billers-logos/awqaf.png';
import armImg from '../../../images/billers-logos/amr.png';
import mImg from '../../../images/billers-logos/m.png';
import bImg from '../../../images/billers-logos/b.png';
import beitImg from '../../../images/billers-logos/beit.png';
import '../partner.scss';


class Charity extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0"> CHARITIES </h4>
            <p className="content">An Easier Way to Support Those in Need</p>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image src={mImg} style={{maxHeight: '80px'}} alt="charity 1" fluid className="charity-one mr-4"/>
              <Image src={awqaf} style={{maxHeight: '80px'}} alt="charity 2" fluid className="charity-two mr-4"/>
              <Image src={beitImg} style={{maxHeight: '80px'}} alt="charity 3" fluid className="charity-two mr-4"/>
              <Image src={bImg} style={{maxHeight: '80px'}} alt="charity 4" fluid className="charity-two mr-4"/>
              <Image src={armImg} style={{maxHeight: '80px'}} alt="charity 4" fluid className="charity-two mr-4"/>
            </div>
            <p className="content">
            The Cube now offers an easier and instant way to make charitable donations. Directly contribute to any given charity from one of our kiosks. 
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Charity;
