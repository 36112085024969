import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import '../partner.scss';
import dImg from '../../../images/billers-logos/d.png';
import du from '../../../images/billers-logos/du.png';
import fiveImg from '../../../images/billers-logos/five-mobile.png'
import helloImg from '../../../images/billers-logos/hello.png'

class Telecom extends Component {

  render() {
    return (
      <div className="inner-tab-data">
         <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case"> TELECOM</h4>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image src={dImg} style={{width: '100px', maxHeight: '80px'}} alt="transport 1" fluid className="money-one mr-4" />
              <Image src={du} style={{width: '100px', maxHeight: '80px'}} alt="transport 2" fluid className="money-two mr-4" />
              <Image src={fiveImg} style={{width: '100px', maxHeight: '80px'}} alt="transport 1" fluid className="money-three mr-4" />
              <Image src={helloImg} style={{width: '100px', maxHeight: '80px'}} alt="transport 1" fluid className="money-three mr-4" />
            </div>
            <p className="content">
            Registered customers now have a convenient way to remit transactions directly from the kiosk. All you need to do is visit your chosen exchange house branch once to register your Know Your Customer (KYC) data along with the required beneficiaries. Once this is completed, you can easily access your remittance services from any of our kiosks.
            </p>
            <p className="content">
            Managing and transferring funds is now easier, more effective, and completely safe with MBME Pay. 
             </p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className="head-medium-normal mt-0 mb-0">SIMPLIFY YOUR BUSINESS WITH MBMEPAY.</h4>
            <div className="content"> The global demand for mobile communication, telecoms, and network operations is constantly rising. As the demand continues to grow, we are constantly developing and adapting to new ways of improving customer service in an effort to attract new clientele while retaining existing loyalty. </div>
            <p className="content"> Our retail locations and out-of-store placements enable us to increase efficiency and improve your customer experience like never before! </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className="head-medium-normal mb-0">Mobile recharge</h4>
            <div className="content">Prepaid mobile customers can seamlessly recharge mobile accounts in three simple steps using our MBME Pay mobile recharge services at one of our kiosks: </div>
            <div className="content"> 1. Select the network and recharge amount </div>
            <div className="content"> 2. Select a payment method</div>
            <div className="content"> 3. Receive a receipt with a recharge pin or an instant pin-less recharge.</div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className="head-medium-normal mb-0">Sim Issuance & Validation</h4>
            <div className="content"> Whether customers are in or out of the store, they can easily issue a SIM directly from the MBME Pay kiosk via customer detail registration and activation without the need for teller interaction. </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className="head-medium-normal mb-0">Paying Bills</h4>
            <div className="content"> Whether customers need to pay their monthly mobile, TV, internet, or landline bills, they can do all of that and more with real-time account balance information using the MBME Pay kiosk. </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block my-3">
              <h4 className="head-medium-normal mb-0 mt-0">Telecoms service benefits</h4>
              <div className="styled-ul kiosk-features" style={{height: 'auto'}}>
                <span className="content dot my-2">
                Saves time, money, and resources: customer service staff can focus on high margin sales without worrying about manual intervention </span>
                <span className="content dot my-2"> Cash and card payments </span>
                <span className="content dot my-2"> Fully integrates directly with telecom network </span>
                <span className="content dot my-2"> Self-service kiosk enables unattended service </span>
                <span className="content dot my-2"> RIO from reduced staff interaction </span>
                <span className="content dot my-2"> Brand awareness from customized and branded kiosk </span>
                <span className="content dot my-2"> Additional self-service attracts new customers  </span>
                <span className="content dot my-2"> Transport locations can offer international networks for tourists </span>
                <span className="content dot my-2"> Significantly reduces queues </span>
                <span className="content dot my-2"> Web banners enable more profitable opportunities </span>
                <span className="content dot my-2"> Statement messaging </span>
                <span className="content dot my-2"> Social media & newsletter assets </span>
              </div>

            </Col>
        </Row>
      </div>
    );
  }
}

export default Telecom;
