export const logoImg = require('./logo_new.png');
export const womenbg = require('./women-banner-1.png');

export const notFound = require('./404.png');


export const client1 = require('./clients/careem.jpg');
export const client2 = require('./clients/playstation.png');
export const client3 = require('./clients/fly-dubai.jpg');
export const client4 = require('./clients/xbox.jpg');
export const client5 = require('./clients/denarii.jpg');
export const client6 = require('./clients/sergas');
export const client7 = require('./client7.png');
export const client8 = require('./clients/du.jpg');
export const client9 = require('./clients/Dubai-Police.jpg');
export const client10 = require('./clients/E-Dirhams.jpg');
export const client11 = require('./clients/fewa.jpg');
export const client12 = require('./clients/etisalat.jpg');
export const client13 = require('./clients/ISTA.jpg');
export const client14 = require('./clients/AADC.jpg');
export const client15 = require('./clients/ADPC.jpg');
export const client16 = require('./clients/air-arabia.jpg');
export const client17 = require('./clients/MAWAQIF.jpg');
export const client19 = require('./clients/Sharjah-Int-Charity.jpg');
export const client18 = require('./clients/Sharjah-Municipality.jpg');
export const client21 = require('./clients/itunes.jpg');
export const client22 = require('./clients/salik.jpg');
export const client23 = require('./clients/five.png');
export const client24 = require('./clients/test-rak.jpg');
export const client25 = require('./clients/paysafe.jpg');
export const client26 = require('./clients/pubg.jpg');
export const client27 = require('./clients/Zakat-Fund.jpg');
export const client28 = require('./clients/uae pass.jpg');
export const client29 = require('./clients/smartpass.png');
export const client30 = require('./clients/nol.jpg');
export const client31 = require('./clients/maf.jpg');
export const client32 = require('./clients/national-bonds.jpg');
export const client33 = require('./clients/lootah.jpg');

export const client333 = require('./clients/new/1024px-Roblox_logo.svg.png');
export const client34 = require('./clients/new/aec53dc9a104c98f334f6aa7424b6441.png');
export const client35 = require('./clients/new/amazon-logo-png.png');
export const client36 = require('./clients/new/Coin_RZR Gold_White_dark-background.png');
export const client37 = require('./clients/new/d74ac7338668caa0cbbbc85a06dfd24f.png');
export const client38 = require('./clients/new/E wallet.png');
export const client39 = require('./clients/new/free-fire-logo.png');
export const client40 = require('./clients/new/Imvu-logo.png');
export const client41 = require('./clients/new/logo-mobile-legend-31251.png');
export const client42 = require('./clients/new/logoo.png');
export const client43 = require('./clients/new/Lootah-BC-Gas_logo.gif');
export const client44 = require('./clients/new/Lords_Mobile_logo.png');
export const client45 = require('./clients/new/McAfee-Logo.png');
export const client46 = require('./clients/new/Minecraft-Logo.png');
export const client47 = require('./clients/new/NBCLogo.png');
export const client48 = require('./clients/new/Netflix-Logo.png');
export const client49 = require('./clients/new/Nintendo-Logo.png');
export const client50 = require('./clients/new/nol.jpg');
export const client51 = require('./clients/new/Spotify-Logo.png');
export const client52 = require('./clients/new/tranglo-logo.png');
export const client53 = require('./clients/new/Trriple_logo.png');
export const client54 = require('./clients/new/vip.png');


export const Offer_plane = require('./we_offer/home plane.png');
export const offer_yea = require('./we_offer/Home tower.png');

export const Offer_Charity = require('./we_offer/Charity.png');
export const Offer_Entertainment = require('./we_offer/Entertainment.png');
export const Offer_Government = require('./we_offer/Government.png');
export const Offer_money = require('./we_offer/money.png');
export const Offer_topup = require('./we_offer/topup.png');
export const Offer_Transport = require('./we_offer/Transport.png');
export const Offer_Utility = require('./we_offer/Utility.png');
export const Offer_Education = require('./we_offer/Education.png');

export const about_triangle_1 = require('./hold-triangle-1.svg');
export const about_triangle_2 = require('./hold-triangle-2.svg');

export const groupapp = require('./Group_play_app.png');
export const mobilepic2 = require('./mbme_app.png');
export const machine = require('./img-customize.png');
export const shadow_path = require('./Path 12.svg');
export const govera = require('./govera-icon.png');
export const appicon = require('./Group 18.png');
export const mobile = require('./mbme_app.png');
export const customerbg = require('./Rectangle 20.svg');
export const hand = require('./Icon ionic-ios-hand.svg');
export const dottedrepeat = require('./Repeat Grid 6.svg');
export const gray_right = require('./gray-right.svg');
export const vilolet_right = require('./light-violet-1.svg');
export const arrow_right = require('./Group 1276.svg');
export const about_banner = require('./Group 1702.png');
export const left_triangle = require('./Group 1279.svg');
export const sendmsg = require('./paper-plane-solid.svg');
export const sendmsgblack = require('./paper-plane-solid-black.svg');
export const sendBg = require('./gradient-bg.svg');
export const dark_hash_left = require('./dark-hash-left-1.svg');
export const light_hash_left = require('./light-hash-left-1.svg');
export const light_hash_right = require('./light-hash-right-1.svg');
export const dark_hash_right = require('./dark-hash-right-1.svg');
export const color_dark_right = require('./dark-right.svg');
export const payment_svg = require('./payment-method.svg');
export const page_banner = require('./Rectangle 31.svg');
export const contact_banner = require('./contact_banner.png');
export const privacy_banner = require('./privacy_banner.png');
export const solutions_banner = require('./solutions_banner.png');
export const partner_banner = require('./partner_nanner.png');
export const retailer_banner = require('./retailer_baner.png');
export const career_pic = require('./carrer-group.png');
export const map_marker = require('./marker.svg');
export const about_us_banner = require('./about_banner.png');
export const about_our_vision = require('./our_vision.png');
export const about_our_mission = require('./our_mission.png');
export const partner1 = require('./partner-1.png');
export const partner2 = require('./partner-2.png');
export const partner3 = require('./partner-3.png');
export const partner4 = require('./partner-4.png');
export const facebook = require('../images/facebook.png');
export const linkedin = require('../images/linkedin.png');
export const instagram = require('../images/instagram.png');
export const bell = require('../images/bell.png');
export const watsapp = require('../images/watsapp.png');
export const credit_icon = require('../images/credit-ico.svg');
export const info_icon = require('../images/info-ico.svg');
export const kiosk_icon = require('../images/kiosk-ico.svg');
export const web_icon = require('../images/web-ico.svg');
export const crm_icon = require('../images/crm.png');
export const mediap_icon = require('../images/media-player.png');
export const webportl_icon = require('../images/web-portal.png');
export const serviceExtn_icon = require('../images/service-extn.png')
export const pos_icon = require('../images/pos.png')
export const mobile_icon = require('../images/mobile-app.png')
export const website_icon = require('../images/website.png')
export const ms_2 = require('../images/ms_2.svg');
export const ms_3 = require('../images/ms_3.svg');
export const ms_4 = require('../images/ms_4.svg');
export const ms_5 = require('../images/ms_5.png');
export const ms_6 = require('../images/ms_6.svg');
export const ms_7 = require('../images/ms_7.svg');
export const ms_8 = require('../images/ms_8.svg');
export const govera_icon_1 = require('../images/utility.png');
export const govera_icon_2 = require('../images/teleconm_icon.png');
export const govera_icon_3 = require('../images/govermnt.png');
export const govera_icon_4 = require('../images/vouchers.png');
export const govera_icon_5 = require('../images/property.png');
export const govera_icon_6 = require('../images/transport.png');
export const left_line = require('../images/left-line.svg');
export const right_line = require('../images/right-line.svg');

export const platform = require('../images/platform2.png');
export const experience = require('../images/experience2.png');
export const portfolio = require('../images/portfolio2.png');

export const machinepic = require('../images/machine.png');
export const ultraWhiteKiosk = require('../images/Kiosk-Ultra-wht.png');
export const KioskAmericanoWhite = require('../images/Kiosk-Americano-wht.png');
export const KioskCompactWht = require('../images/Kiosk-Compact-wht.png');
export const machinepic_two = require('../images/machine-two.png');
export const machine_three = require('../images/machine-three.png');
export const rose_bg = require('../images/rose-bg.svg');
export const white_circle = require('../images/white-circle.svg');
export const pay_partner = require('../images/payment-method-1.svg');
export const options_partner = require('../images/options-1.svg');
export const brand_partner = require('../images/brand-1.svg');
export const cash_partner = require('../images/cash-flow-1.svg');
export const interactive_1 = require('../images/interactive-1.svg');
export const invoice_1 = require('../images/invoice-1.svg');
export const speech_bubble_1 = require('../images/speech-bubble-1.svg');
export const megaphone_1 = require('../images/megaphone-1.svg');
export const case_1 = require('../images/case-1.png');
export const case_2 = require('../images/case-2.png');

export const uaePassImg = require('../images/dxb.jpg');

export const category_1 = require('../images/retail_categories/black/category1.png');
export const category_2 = require('../images/retail_categories/black/shopping-cart.png');
export const category_3 = require('../images/retail_categories/black/mall.png');
export const category_4 = require('../images/retail_categories/black/shopping.png');
export const category_5 = require('../images/retail_categories/black/residence.png');
export const category_6 = require('../images/retail_categories/black/outline.png');
export const category_7 = require('../images/retail_categories/black/city-hall.png');
export const category_8 = require('../images/retail_categories/black/hospital.png');
export const category_9 = require('../images/retail_categories/black/education.png');
export const category_10 = require('../images/retail_categories/black/clapperboard.png');
export const category_11 = require('../images/RetailBenefits/black/airplane.png');
export const category_12 = require('../images/RetailBenefits/black/Ecommerce-transfer money-buy-smartphone-hands.png');
export const category_13 = require('../images/RetailBenefits/black/shop-bag.png');
export const category_14 = require('../images/RetailBenefits/black/outline.png');

export const category_1_white = require('../images/retail_categories/white/escalator.png');
export const category_2_white = require('../images/retail_categories/white/shopping-cart (3).png');
export const category_3_white = require('../images/retail_categories/white/mall.png');
export const category_4_white = require('../images/retail_categories/white/shopping.png');
export const category_5_white = require('../images/retail_categories/white/residence.png');
export const category_6_white = require('../images/retail_categories/white/outline.png');
export const category_7_white = require('../images/retail_categories/white/city-hall.png');
export const category_8_white = require('../images/retail_categories/white/hospital.png');
export const category_9_white = require('../images/retail_categories/white/graduation-cap (4).png');
export const category_10_white = require('../images/retail_categories/white/clapperboard.png');
export const category_11_white = require('../images/RetailBenefits/white/airplane.png');
export const category_12_white = require('../images/RetailBenefits/white/Ecommerce-transfer money-buy-smartphone-hands.png');
export const category_13_white = require('../images/RetailBenefits/white/shop-bag.png');
export const category_14_white = require('../images/RetailBenefits/white/outline.png');


export const telecommunications_icon = require('../images/telecommunications-icon.svg');
export const telecommunications_white_icon = require('../images/telecommunications_white.svg');
export const topup_white_icon = require('../images/topup_white.png');

export const data_icon = require('../images/data-icon.svg');
export const coin_icon = require('../images/coin-icon.svg');
export const love_icon = require('../images/love-icon.svg');
export const unity_icon = require('../images/unity-icon.svg');

export const money_white = require('../images/money_white.png');
export const charity_white = require('../images/charity_white.png');
export const unity_white = require('../images/unity_white.png');
export const government_white = require('../images/government_white.png');
export const car_white = require('../images/car_white.png');
export const airplane_white = require('../images/airplane_white.png');
export const television_white = require('../images/television_white.png');
export const plus_white = require('../images/plus_white.png');

export const government_icon = require('../images/government-icon.svg');
export const car_icon = require('../images/car-icon.svg');
export const airplane_icon = require('../images/airplane-icon.svg');
export const television_icon = require('../images/television-icon.svg');
export const plus_icon = require('../images/plus.png');
export const transport_1 = require('../images/transport-1.png');
export const transport_2 = require('../images/transport-2.png');
export const transport_3 = require('../images/transport-3.png');
export const transport_4 = require('../images/transport-4.png');
export const transport_5 = require('../images/transport-5.png');
export const transport_6 = require('../images/transport-6.png');
export const transport_7 = require('../images/transport-7.png');
export const airline_1 = require('../images/airline-1.png');
export const airline_2 = require('../images/airline-2.png');
export const entertainment_1 = require('../images/entertainment-1.png');
export const entertainment_2 = require('../images/entertainment-2.png');
export const entertainment_3 = require('../images/entertainment-3.png');
export const entertainment_4 = require('../images/entertainment-4.png');
export const money_1 = require('../images/money-1.png');
export const money_2 = require('../images/money-2.png');
export const money_3 = require('../images/money-3.png');
export const money_4 = require('../images/money-4.png');
export const charity_1 = require('../images/charity-1.png');
export const charity_2 = require('../images/charity-2.png');
export const charity_3 = require('../images/charity-3.png');
export const charity_4 = require('../images/charity-4.png');
export const utility_1 = require('../images/utility-1.png');
export const utility_2 = require('../images/utility-2.png');
export const utility_3 = require('../images/utility-3.png');
export const utility_4 = require('../images/utility-4.png');
export const utility_5 = require('../images/utility-5.png');
export const utility_6 = require('../images/utility-6.png');
export const utility_7 = require('../images/utility-7.png');
export const gov_1 = require('../images/gov-1.png');
export const gov_2 = require('../images/gov-2.png');
export const gov_3 = require('../images/gov-3.png');
export const gov_4 = require('../images/gov-4.png');
export const gov_5 = require('../images/gov-5.png');
export const country_1 = require('../images/country-1.png');
export const country_2 = require('../images/country-2.png');
export const country_3 = require('../images/country-3.png');
export const country_4 = require('../images/country-4.png');
export const country_5 = require('../images/country-5.png');
export const country_6 = require('../images/country-6.png');
export const country_7 = require('../images/country-7.png');
export const country_8 = require('../images/country-8.png');
export const country_9 = require('../images/country-9.png');

export const sad = require('../images/sad.svg');
export const smile = require('../images/smile.svg');
export const lf_dark_triangle = require('../images/lf-dark-triange.svg');
export const lf_light_triangle = require('../images/lf-light-triange.svg');
export const rg_dark_triange = require('../images/rg-dark-triange.svg');
export const rg_light_triangle = require('../images/rg-light-triange.svg');
export const circleborder = require('../images/Ellipse 70.svg');

export const milestone_1 = require('../images/milestone-1-1.png');
export const milestone_2 = require('../images/milestone-1-2.png');
export const milestone_3 = require('../images/timeline/edirham.png');
export const milestone_4 = require('../images/timeline/mahzooz.png');
export const milestone_5 = require('../images/timeline/SEWA.png');
export const milestone_2007 = require('../images/timeline/2007.png');
export const milestone_2008 = require('../images/timeline/2008.png');
export const milestone_2009 = require('../images/timeline/2009.png');
export const milestone_2010 = require('../images/timeline/2010.png');
export const milestone_2011 = require('../images/timeline/2011.png');
export const milestone_2012 = require('../images/timeline/2012.png');
export const milestone_2013 = require('../images/timeline/2013.png');
export const milestone_2014 = require('../images/timeline/2014.png');
export const milestone_2015 = require('../images/timeline/2015.png');
export const milestone_2016 = require('../images/timeline/2016.png');
export const milestone_2017 = require('../images/timeline/2017.png');
export const milestone_2019 = require('../images/timeline/2019.png');
export const milestone_2020 = require('../images/timeline/2020.png');

export const mob_hamburger = require('../images/mobile/mobile-white-hamburger.svg');
export const mob_banner = require('../images/mobile/mobile-banner.png')
export const next_arrow = require('../images/next.png')
export const prev_arrow = require('../images/back.png')

export const mastercard = require('../images/payment-methods/mastercard.png');
export const mastercardsecurecode = require('../images/payment-methods/mastercardsecurecode.png')
export const verifiedVisa = require('../images/payment-methods/verifiedVisa.png')
export const visa = require('../images/payment-methods/visa.png')

