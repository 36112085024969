import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { interactive_1, invoice_1, speech_bubble_1, megaphone_1 } from '../../../images/index';
import '../partner.scss';

class Support extends Component {

  render() {
    return (
      <div className="tabData self-service">
        <div className="each-block">
          <div className="content mb-5"> Let your customers know about your new innovative payment services with an MBME Pay marketing campaign.  </div>
          <h4 className="head-medium-normal mt-0 mb-0">WHAT ITS WORKS</h4>
          <p className="content"> MBME Pay regularly runs marketing campaigns, giving us valuable insight into recommended marketing approaches, including: </p>
          <Row className="mt-4 data-card">
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={interactive_1} fluid alt="interactive_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Multi-channel</h4>
                  <p className="content m-0">We reach your existing clientele through various communication platforms, including emails, websites, and flyers</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={invoice_1} fluid alt="invoice_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Utilizing Bills</h4>
                  <p className="content m-0">Whether you release electronic or paper bills, we can use your billing method to give your customers an update</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={speech_bubble_1} fluid alt="speech_bubble_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Personalized Communications</h4>
                  <p className="content m-0">We take existing MBME Pay material and add personalized elements from your company.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={megaphone_1} fluid alt="megaphone_1" className="ico-img" />
                  <h4 className="head-medium-normal capital">Multiple Campaigns</h4>
                  <p className="content m-0">The more campaigns we run, the more likely customers are to receive the message. </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="data-card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block my-3">
              <h4 className="head-medium-normal"> OUR MARKETING MATERIAL</h4>
              <p className="content"> MBME Pay has a range of marketing materials related to our services that you can download and use for your own campaign. They key to successful marketing campaigns is utilizing a multichannel approach, which is why we have specific material for various channels, including: </p>
              <div className="styled-ul kiosk-features">
                <span className="content dot my-2"> Flyers </span>
                <span className="content dot my-2"> Posters </span>
                <span className="content dot my-2"> Web banners </span>
                <span className="content dot my-2"> Statement messaging </span>
                <span className="content dot my-2"> Social media assets </span>
                <span className="content dot my-2"> Newsletter assets </span>
                <span className="content dot my-2">Kiosk Upper Screens</span>
              </div>
            </Col>
          </Row>

          <Row className="data-card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="card-block">
              <h4 className="head-medium-normal">TERMS & CONDITIONS</h4>
              <div className="styled-ul kiosk-features">
                <span className="content dot my-2"> Please email us at marketing@mbmepay.ae to request marketing material downloads</span>
                <span className="content dot my-2"> Material marked as “Print Ready” can be directly printed or used on your website </span>
                <span className="content dot my-2"> Material marked as “Customizable” can be edited and personalized by our dedicated graphic designer. The cased text can also be altered. Please note - if any material is edited, it has to be approved by the MBME Pay marketing department first before distribution</span>
                <span className="content dot my-2"> Upon request of any downloadable material, please disclose your contact details so our marketing team can keep track of companies using our materials and provide information regarding relevant upcoming campaigning </span>
                <span className="content dot my-2"> Your business will be responsible for the printing of any of our supplied artworks </span>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default Support;
