import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import {  appicon, mobile,mobilepic2,groupapp } from '../../images/index';
import './home.scss';

class Cofort extends Component {
    render() {
        return (
            <Row>
                <Col md={{ span: 10, offset: 1 }} className="no-padd" >
                    <Row  className="align-items-center">
                        <Col md={6} xs={12} className="lf-blk">
                            <div className="wow bounceInLeft">
                                {/* <span className="icon">
                                    <Image src={govera} fluid alt="govera" />
                                    <h4 className="m-0"> Govera </h4>
                                </span> */}
                                <h4 className="bold-hash-head">MBME Pay app coming soon…</h4>
                                {/* <h4  data-aos="fade-right" data-aos-duration="3200">
                                <p>MBME Pay app coming soon…</p>
                                 </h4> */}
                                <Image fluid src={groupapp} alt="appicon" className="download" />
                            </div>
                        </Col>
                        <Col md={6} xs={12} className="rg-blk text-center"  data-aos="fade-up" data-aos-duration="3200">
                            <Image className="mobile-pic" src={mobilepic2} alt="mobile" fluid />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default Cofort;
