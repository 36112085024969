import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import './terms.scss';
import TermsBanner from './banner';
import TermsCondition from './terms';
import ClientSlider from '../common/client-slider';
import { lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';


class Contact extends Component {
  render() {
    return (
      <div className="page-banner-wrapper">
        <TermsBanner />
        <div className="position-block">
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <Container className="pageview">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <TermsCondition />
              </Col>
            </Row>
          </Container>
          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <ClientSlider />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Contact;
