import React, { Component } from 'react';
import './admin.scss';
import { Container, Col, Row, Form, Image, Button } from 'react-bootstrap';
import API from '../../api';
import { sad, smile } from '../../images/index';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'hide',
            responseMessage: '',
            popup: '',
            fields: {},
            errors: {}
        };
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "* New Password cannot be empty";
        }

        if (!fields["password2"]) {
            formIsValid = false;
            errors["password2"] = "* Confirm New Password cannot be empty";
        }

        if (!fields["current"]) {
            formIsValid = false;
            errors["current"] = "* Current Password cannot be empty";
        }

        if ((fields["password"] && fields["password2"]) && (fields["password"] !== fields["password2"])) {
            formIsValid = false;
            errors["password2"] = "* Confirm Password should be same as new password";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            fields
        });
    }

    onHidePopup = () => {
        this.setState({
            message: 'hide'
        });
    }

    resetForm = () => {
        this.refs.password.value = '';
        this.refs.password2.value = '';
        this.refs.current.value = '';
        this.setState({
            fields: {}
        })
    }

    submitPassword(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            const USER_TOKEN = localStorage.getItem('usertoken');
            const AuthStr = 'Bearer '.concat(USER_TOKEN);

            API.post('/admin/changePassword', this.state.fields, { headers: { Authorization: AuthStr } }).then(response => {
                console.log(response.data);
                let data = response.data;
                this.setState({
                    message: 'show',
                    popup: 'success',
                    responseMessage: data.message,
                });
                this.resetForm();
            }).catch((error) => {
                console.log(error);
                if (error.response) {
                    console.log('error ' + error.response.data.message);
                    this.setState({
                        message: 'show',
                        responseMessage: error.response.data.message,
                        popup: 'error'
                    });
                }
                else {
                    this.setState({
                        message: 'show',
                        responseMessage: 'Something went wrong. Please try again',
                        popup: 'error'
                    });
                }

                this.resetForm();
            });
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div className={"popup " + this.state.message + " " + this.state.popup}>
                        <div className="pop-inner">
                            <Image src={this.state.popup === 'success' ? smile : sad} />
                            <h5>{this.state.popup === 'success' ? 'success' : 'error'}</h5>
                            <p>{this.state.responseMessage}</p>
                            <Button onClick={this.onHidePopup} className={this.state.popup}>Ok</Button>
                        </div>
                    </div>
                    <div className={"overlay " + this.state.message} onClick={this.onHidePopup}></div>
                </div>

                <Container>
                    <Row>
                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="enquiry no-padd">
                            <h5 className="head-bold"> Change Password </h5>
                            <Row>
                                <Col md={6}>
                                    <div className="form-change-password">
                                        <Form className="mbme-form mt-4  position-relative" onSubmit={this.submitPassword.bind(this)}>
                                            <Form.Group controlId="chForm.current" className="mb-3">
                                                <Form.Control type="password" autoComplete="new-password" placeholder="Current Password" ref="current" onChange={this.handleChange.bind(this, "current")} value={this.state.fields["current"] || ""} />
                                                <span className="error">{this.state.errors["current"]}</span>
                                            </Form.Group>
                                            <Form.Group controlId="chForm.new" className="mb-3" >
                                                <Form.Control type="password" placeholder="New Password" ref="password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"] || ""} />
                                                <span className="error">{this.state.errors["password"]}</span>
                                            </Form.Group>
                                            <Form.Group controlId="chForm.new" className="mb-3" >
                                                <Form.Control type="password" placeholder="Confirm New Password" ref="password2" onChange={this.handleChange.bind(this, "password2")} value={this.state.fields["password2"] || ""} />
                                                <span className="error">{this.state.errors["password2"]}</span>
                                            </Form.Group>
                                            <div className="login-button">
                                                <button className="enable mbme-cbutton" id="submit" value="Submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ChangePassword;
