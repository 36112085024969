import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { mobile, govera, appicon, govera_icon_1,govera_icon_2,govera_icon_3,govera_icon_4,govera_icon_5,govera_icon_6,groupapp } from '../../../images/index';
import '../solution.scss';


class Govera extends Component {
    render() {
        return (
            <div>
                <Row className="field-padd">
                    <Col md={12}>
                        <div className="gov-block">
                            <Row className="align-items-center">
                                <Col md={5} className="lf-blk">
                                    <div>
                                        <span className="icon">
                                            {/* <Image src={govera} fluid alt="govera" /> */}
                                            <h4 className="m-0 bold-hash-head">MBME Pay</h4>
                                        </span>
                                        <h4 className="p-bold my-3">Comfort, Trusted and Hassle Free</h4>
                                        <h4> <p className="content gray font-normal  my-2"> Don’t have an effective way to manage your payments? The MBME Pay app offers easy payments and top-ups from the comfort of home. Whether you’re paying utilities or cashing in on vouchers, the MBME Pay app has you covered with solutions that include </p></h4>
                                    </div>
                                </Col>
                                <Col md={7} className="rg-blk">
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_1} fluid alt="payment" />
                                                    <p className="p-bold p-600">Utitlity</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_2} fluid alt="payment" />
                                                    <p className="p-bold p-600">Telecom</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_3} fluid alt="payment" />
                                                    <p className="p-bold p-600">Government</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_4} fluid alt="payment" />
                                                    <p className="p-bold p-600">Vouchers</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_5} fluid alt="payment" />
                                                    <p className="p-bold p-600">Properties</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={6}  xs={12} className="service-card-block my-2">
                                            <div className="ech-item position-relative">
                                                <div className="align-data text-center">
                                                    <Image src={govera_icon_6} fluid alt="payment" />
                                                    <p className="p-bold p-600">Transport</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row id="cofort">
                    <Col md={{ span: 10, offset: 1 }}>
                        <Row className="align-items-center">
                            <Col md={6} xs={12} className="lf-blk">
                                <div>
                                    {/* <span className="icon">
                                        <Image src={govera} fluid alt="govera" />
                                        <h4 className="m-0"> Govera </h4>
                                    </span> */}
                                <h4 className="bold-hash-head">MBME Pay app coming soon…</h4>
                                    {/* <h4> <p>Download the latest and fastest payment services app in the UAE </p></h4> */}
                                    <Image fluid src={groupapp} alt="appicon" className="download" />
                                </div>
                            </Col>
                            <Col md={6} xs={12} className="rg-blk text-center">
                                <Image className="mobile-pic" src={mobile} alt="mobile" fluid />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default Govera;