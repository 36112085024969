import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import {
  category_1_white, category_1,
  category_2_white,
  category_3_white,
  category_4_white,
  category_5_white,
  category_6_white,
  category_7_white,
  category_8_white,
  category_9_white,
  category_10_white,
  category_2, category_3, category_4, category_5, category_6, category_7, category_8, category_9, category_10
} from '../../../images/index';
import '../retailer.scss';

class Categories extends Component {

  render() {
    return (
      <div className="tabData">
        <div className="category">
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_1} alt="category_1_white" className="img-black" fluid />
                <Image src={category_1_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Shopping Mall</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_2} alt="category_1_white" className="img-black" fluid />
                <Image src={category_2_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Supermarket</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_3} alt="category_1_white" className="img-black" fluid />
                <Image src={category_3_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Hypermarket</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_4} alt="category_1_white" className="img-black" fluid />
                <Image src={category_4_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Grocery</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_5} alt="category_1_white" className="img-black" fluid />
                <Image src={category_5_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Residential Buildings</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk" >
                <Image src={category_6} alt="category_1_white" className="img-black" fluid />
                <Image src={category_6_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p style={{marginTop: "20px"}} className="content text-center">Commercial Towers</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_7} alt="category_1_white" className="img-black" fluid />
                <Image src={category_7_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Government</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_8} alt="category_1_white" className="img-black" fluid />
                <Image src={category_8_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Hospital</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_9} alt="category_1_white" className="img-black" fluid />
                <Image src={category_9_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Education</p>
            </div>
          </div>
          <div className="each-block position-relative">
            <div className="item">
              <div className="img-blk">
                <Image src={category_10} alt="category_1_white" className="img-black" fluid />
                <Image src={category_10_white} alt="category_1_white" className="img-white" fluid />
              </div>
              <p className="content m-0 text-center">Entertainment</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;
