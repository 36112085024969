import React, { Component } from 'react';
import { Container, Image, Col, Row } from 'react-bootstrap';
import './retailer.scss';
import { retailer_banner } from '../../images/index';

class PageBanner extends Component {

  render() {
    return (
      <div className="banner-bg position-relative">
        <Image fluid src={retailer_banner} alt="page-banner" />
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="banner-content-align">
              <div className="page-title">
                <h3>{this.props.title}</h3>
                <p> {this.props.description} </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PageBanner;
