import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { entertainment_1, entertainment_2, entertainment_3, entertainment_4 } from '../../../images/index';
import '../partner.scss';
import amazonLogo from '../../../images/billers-logos/amazon-logo-png.png';
import googlePlay from '../../../images/billers-logos/google-play.png';
import applemusic from '../../../images/billers-logos/apple-music.png';
import spotify from '../../../images/billers-logos/spotiry.png';
import freefirelogo from '../../../images/billers-logos/free-fire-logo.png';
import imvu from '../../../images/billers-logos/imvu.png';
import leageOfLegends from '../../../images/billers-logos/leageOfLegends.png';
import jImg from '../../../images/billers-logos/j.png';
import lImg from '../../../images/billers-logos/l.png';
import steam from '../../../images/billers-logos/steam.png';
import minecraft from '../../../images/billers-logos/minecraft.png';
import mobileLegends from '../../../images/billers-logos/mobileLegends.png';
import netflix from '../../../images/billers-logos/netflix.png';
import mcafee from '../../../images/billers-logos/mcafee.png';
import gImg from '../../../images/billers-logos/g.png';
import roblox from '../../../images/billers-logos/roblox.png';
import vip from '../../../images/billers-logos/vip.png';

class Entertainment extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case mb-0"> ENTERTAINMENT & GAMING </h4>
            <p className="content">Convenient Entertainment & Gaming</p>
            <div className=" mb-4 d-flex flex-wrap justify-content-start align-items-center">
              <Image style={{ maxHeight: '80px' }} src={amazonLogo} alt="transport 1" fluid className="enter-one mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={googlePlay} alt="transport 1" fluid className="enter-one mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={applemusic} alt="transport 2" fluid className="enter-two mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={spotify} alt="transport 1" fluid className="enter-three mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={freefirelogo} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={imvu} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={leageOfLegends} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={jImg} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={lImg} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={steam} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={minecraft} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={mobileLegends} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={netflix} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={mcafee} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={gImg} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={roblox} alt="transport 2" fluid className="enter-four mr-4"/>
              <Image style={{ maxHeight: '80px' }} src={vip} alt="transport 2" fluid className="enter-four mr-4"/>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <h4 className="head-medium-normal u-case mb-0"> Prepaid cards </h4>
            <p className="content">Ever wanted to make online purchases without using your bank account? Now, you can, with our prepaid card services! You can own a payment card that can be used wherever the Visa or MasterCard logos are displayed, including retail stores, ATMs, and online purchases. This is especially convenient if you don’t have a debit or credit card.</p>
            <p className="content">The MAF Finance’s MyNetCard is a virtual prepaid card with a onetime load that allows you to receive all the relevant card details necessary to make online payments, including a card number, expiry, and CVV number. All you need is a receipt from the kiosk, allowing instant use for all online purchases worldwide! </p>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0"> Access passes & ticketing </h4>
            <p className="content"> Complement your team with an MBME Pay self-service kiosk, providing an enhanced, automated sales force. Customers can now buy passes, tickets, or access bands for daily, weekly, monthly, and even annual use while paying with either cash or card. </p>
            <p className="content"> This service allows users to combine all port payments into one kiosk with fully managed 24-hour payment solutions, enabling reduced cash processing while offering daily cash reconciliation options. </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Entertainment;
