import React, { Component } from 'react';
import { Row, Col, Nav, Tab, Image } from 'react-bootstrap';
const { arrow_right, about_banner } =  require('../../images/index');


class About extends Component {
  render() {
    return (
      <div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="one">
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="text-center about-row">
              <Tab.Content>
                <Tab.Pane eventKey="one">
                  <h5 className="space-letter" data-aos="fade-up" data-aos-duration="800" >ABOUT US</h5>
                  <h4 className="my-4" data-aos="fade-left" data-aos-duration="600">
                    <p>Providing the best-known</p>
                    <p className="gra-uline position-relative"> services provider</p>
                  </h4>
                  <p className="content">
                  At MBME Pay, we’re constantly learning and adapting to the latest technologies. Our established client base in various market sectors allows us to deliver proven services specifically tailored to your business requirements. 
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="two">
                  <h5 className="space-letter" >Our Vision</h5>
                  <h4 className="my-4">
                    <p>We provide the best known</p>
                    <p className="gra-uline position-relative"> payment gateways</p>
                  </h4>
                  <p className="content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  <h5 className="space-letter">Our Mission</h5>
                  <h4 className="my-4">
                    <p>We provide the best known</p>
                    <p className="gra-uline position-relative"> payment gateways</p>
                  </h4>
                  <p className="content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                </Tab.Pane>
              </Tab.Content>
              <Row>
                <Col md={12} sm={12} xs={12} className="about-tab-button my-4">
                  <Nav variant="pills" className="flex-column">
                    <div className="tab-buttons">
                      <Nav.Item className="ech-btn" data-aos="fade-right" >
                        <Nav.Link href="/about-us" >
                          About US
                       <Image alt="arrow" fluid src={arrow_right} />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ech-btn" data-aos="fade-up" >
                        <Nav.Link href="/about-us/vision">
                          Our Vision
                   <Image alt="arrow" fluid src={arrow_right} />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="ech-btn" data-aos="fade-left" >
                        <Nav.Link href="/about-us/mission">
                          Our Mission
                   <Image alt="arrow" fluid src={arrow_right} /> 
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab.Container>

        <Row>
          <Col md={12} xs={12} className="about-banner position-relative my-4" data-aos="fade-up" data-aos-duration="1000">
            <Image src={about_banner} fluid alt="about-bann" />
          </Col>
        </Row>
      </div>
    );
  }
}

export default About;