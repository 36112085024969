
import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import './help.scss';

class PageBanner extends Component {
  render() {
    return (
      <div className="banner-bg position-relative">
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} >
              <div className="help-support-head">
                <h3 >Help and Support</h3>
                <h6>Customer Service 24/7</h6>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PageBanner;
