import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './about.scss';
import PageBanner from './banner';
import ClientSlider from '../common/client-slider';
import { about_our_vision, lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';




class Vision extends Component {


  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner />
        <div className="position-block position-relative">
          <div className="line-anim-right">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <Container className="pageview">
            <Row>
              <Col  className="col-md-10 col-sm-12 col-12 offset-md-1 mgt-10">
                <Row className="img-with-data field-padd-2 mt-2" id="one">

                  <Col md={5} xs={12} className="position-relative">
                    <h5 className="head-no-bold mb-0 mt-3">OUR</h5>
                    <h4 className="head-bold mb-4 mt-0">VISION</h4>

                    <span className="quotes">"</span>
                    <p className="content vision-content">
                    MBME Pay’s vision is to not only stay the largest payment services provider, bill aggregator, kiosk manufacturer, and software developer in the UAE, but to become a leader in payment services solutions and the first choice for all associations. Not only do we envision a more innovative future, but also a more convenient one for both businesses and consumers.
                            </p>
                  </Col>

                  <Col md={7} xs={12} className="position-relative">
                    <Image src={about_our_vision} fluid alt="our-vision" />
                  </Col>

                </Row>

              </Col>
            </Row>
          </Container>
          <Container className="field-padd">
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <ClientSlider />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Vision;
