import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle, notFound } from '../../images/index';

class ComingSoon extends Component {
  render() {
    return (
      <Container className="field-padd">
        <Row>
          <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
            <div className="text-center">
              <h1 className="coming-soon-head">Coming Soon... </h1>
              <p className="coming-soon-text"> <strong>We will be back soon.We are working on it,thank you for showing interest. </strong> </p>
            </div>
           
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ComingSoon;
