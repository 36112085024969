import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { payment_svg, ms_2, ms_3, ms_4, ms_5, ms_6, ms_7, ms_8 } from '../../../images/index';
import '../solution.scss';


class ManagedServices extends Component {
    render() {
        return (
            <div className="tabData">
                <div className="each-block">
                    <h4 className="head-medium-block">MANAGED SERVICES</h4>
                    <span className="content"> We’ll Do the Heavy Lifting While You Focus on Growing Your Business</span>
                </div>

                <Row className="managed-service">
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={payment_svg} fluid alt="payment" />
                                <p className="p-bold p-600">Timely Cash Collection</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_2} fluid alt="payment" />
                                <p className="p-bold p-600">Daily Reconciliation</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_3} fluid alt="payment" />
                                <p className="p-bold p-600">Monitoring</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_4} fluid alt="payment" />
                                <p className="p-bold p-600">Reporting</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_5} fluid alt="payment" />
                                <p className="p-bold p-600">PCI Compliant</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_6} fluid alt="payment" />
                                <p className="p-bold p-600">Business Manager</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_7} fluid alt="payment" />
                                <p className="p-bold p-600">Customer Care</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={6} xs={6} className="service-card-block">
                        <div className="ech-item position-relative">
                            <div className="align-data text-center">
                                <Image src={ms_8} fluid alt="payment" />
                                <p className="p-bold p-600">Technical Support</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ManagedServices;