import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import PageBanner from './banner';
import KioskLocations from './map';

class Contact extends Component {
  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner />
        <Container className="pageview">
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
              <Row>
                <div className="kiosk-locations">
                  <KioskLocations />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Contact;
