import React, { Component } from 'react';
import { Container, Image, Col, Row } from 'react-bootstrap';
import './terms.scss';
import { privacy_banner } from '../../images/index';

class PageBanner extends Component {
  render() {
    return (
      <div className="banner-bg position-relative">
        <Image fluid src={privacy_banner} alt="page-banner" />
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12} className="banner-content-align">
              <h3 className="page-title">Terms & Condition</h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PageBanner;
