import React, { Component } from 'react';
import { Container, Col, Row, Image, Tab, Nav } from 'react-bootstrap';
import { money_white, plus_white, television_white, airplane_white,topup_white_icon, car_white, unity_white, government_white, charity_white, telecommunications_icon, data_icon, coin_icon, love_icon, unity_icon, government_icon, car_icon, airplane_icon, television_icon, plus_icon, telecommunications_white_icon } from '../../../images/index';
import '../partner.scss';
import Airline from '../services/airline';
import Charity from '../services/charity';
import Entertainment from '../services/entertainment';
import Government from '../services/government';
import Money from '../services/money';
import More from '../services/more';
import Telecom from '../services/telecom';
import Topup from '../services/topup';
import Transport from '../services/transport';
import Utility from '../services/utility';


class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: '1'
    };
    this.handleSelectTab = this.handleSelectTab.bind(this);
  }
  handleSelectTab(event) {
    this.setState({
      tabActive: event,
    })
  }
  render() {
    return (
      <div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1" onSelect={(event) => this.handleSelectTab(event)}>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Nav variant="pills" className="partner-service-tab my-5">
                  <Nav.Item>
                    <Nav.Link eventKey="1">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '1' ? 'enable' : 'disable'} src={telecommunications_white_icon} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '1' ? 'enable' : 'disable'} src={telecommunications_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span className="content"> Telecom </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="2">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '2' ? 'enable' : 'disable'} src={topup_white_icon} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '2' ? 'enable' : 'disable'} src={data_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>International Top-Up</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="3">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '3' ? 'enable' : 'disable'} src={money_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '3' ? 'enable' : 'disable'} src={coin_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Money & Transfer</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '4' ? 'enable' : 'disable'} src={charity_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '4' ? 'enable' : 'disable'} src={love_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Charity</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '5' ? 'enable' : 'disable'} src={unity_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '5' ? 'enable' : 'disable'} src={unity_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Utility</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '6' ? 'enable' : 'disable'} src={government_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '6' ? 'enable' : 'disable'} src={government_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Government</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="7">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '7' ? 'enable' : 'disable'} src={car_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '7' ? 'enable' : 'disable'} src={car_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Transport </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="8">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '8' ? 'enable' : 'disable'} src={airplane_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '8' ? 'enable' : 'disable'} src={airplane_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Airtline</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="9">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '9' ? 'enable' : 'disable'} src={television_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '9' ? 'enable' : 'disable'} src={television_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>Entertainment</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="10">
                      <div className="tab-head-itm">
                        <div className="img-sec">
                          <Image className={this.state.tabActive !== '10' ? 'enable' : 'disable'} src={plus_white} alt="tab-head-imgs" fluid />
                          <Image className={this.state.tabActive === '10' ? 'enable' : 'disable'} src={plus_icon} alt="tab-head-imgs" fluid />
                        </div>
                        <span>More</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Container>

          <Tab.Content>
            <Tab.Pane eventKey="1">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Telecom />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <Row>
                <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                  <Topup />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Money />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Charity />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Utility />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="6">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Government />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="7">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Transport />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="8">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Airline />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="9">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <Entertainment />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="10">
              <Container>
                <Row>
                  <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                    <More />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

export default Service;
