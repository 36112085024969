import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { airline_1, airline_2 } from '../../../images/index';
import flyDubai from '../../../images/billers-logos/fly-dubai.png';
import airarabia from '../../../images/billers-logos/airarabia.png';
import '../partner.scss';

class Airline extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0"> AIRLINES </h4>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image src={flyDubai} style={{ marginRight: '10px', maxWidth: '135px'}} alt="transport 1" fluid className="one"/>
              <Image src={airarabia} style={{ marginRight: '10px', maxWidth: '135px'}} alt="transport 2" fluid className="two"/>
            </div>
            <p className="content">Travelling should be a fun and adventurous experience, which is why the MBME Pay airline ticket payment service is the ideal solution. Our goal is to make travel more accessible, which is why we’re expanding our payment channels through our network of self-service kiosks. Passengers now have a secure and convenient payment and ticketing method at their fingertips! </p>
            <p className="content">As airlines increase their route network, we aim to increase our payment routes across the country. </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Airline;
