import React, { Component } from 'react';
import { Nav, Col, Row, Tab, Container } from 'react-bootstrap';
import './partner.scss';
import BecomeForm from './tabs/become-form';
import CaseStudy from './tabs/case';
import Support from './tabs/support';
import Benifit from './tabs/benefits';
import Service from './tabs/service';



class PartnerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'one'
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectTab = this.handleSelectTab.bind(this);
    }

    handleSelectTab(event) {
        this.props.parentCallback(event);
    }

    handleSelect(event) {
        this.setState({
            key: event
        });
    }

    render() {
        return (
            <Tab.Container id="solution" defaultActiveKey={this.state.key} onSelect={(event) => this.handleSelectTab(event)}>
                <div className="field-padd-3 partner-wrapper">
                    <Container>
                        <Row>
                            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                <Nav variant="pills" className="sol-tab-header">
                                    <Nav.Item>
                                        <Nav.Link eventKey="one"> Become a partner </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="two"> Partner Services </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="three"> Partner Benefits </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four"> Marketing Support </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="five"> FAQs and Partnerships </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                    <Tab.Content>
                        <Tab.Pane eventKey="one">
                            <Container>
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                        <BecomeForm />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="two">
                            <Service />
                        </Tab.Pane>
                        <Tab.Pane eventKey="three">
                            <Container>
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                        <Benifit />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <Container>
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                        <Support />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="five">
                            <Container>
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                                        <CaseStudy />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        );
    }
}

export default PartnerView;
