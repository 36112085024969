import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './partner.scss';
import PageBanner from './banner';
import PartnerView from './partner';
import ClientSlider from '../common/client-slider';
import { lf_dark_triangle, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';
import Client from '../home/client';


class Partners extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      titleData: 'Expand your Horizons',
      titleDescription: 'Get 24/7 Customer Service! '
    };
  };

  callbackFunction = (childData) => {
    if (childData === 'one')
      this.setState({
        titleData: 'Expand your Horizons',
        titleDescription: 'Get 24/7 Customer Service! ',
      })
    if (childData === 'two')
      this.setState({
        titleData: 'Expand your Horizons',
        titleDescription: 'Get 24/7 Customer Service! ',
      })
    if (childData === 'three')
      this.setState({
        titleData: 'Expand your Horizons',
        titleDescription: 'Get 24/7 Customer Service! ',
      })
    if (childData === 'four')
      this.setState({
        titleData: 'Expand your Horizons',
        titleDescription: 'Get 24/7 Customer Service! ',
      })
    if (childData === 'five')
      this.setState({
        titleData: 'Expand your Horizons',
        titleDescription: 'Get 24/7 Customer Service! ',
      })
  }

  render() {
    return (
      <div className="page-banner-wrapper">
        <PageBanner title={this.state.titleData} description={this.state.titleDescription} />
        <div className="pageview position-relative">
          <div>
            <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
            <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
            <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
            <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
          </div>
          <PartnerView parentCallback={this.callbackFunction} />
        </div>
        <Container className="field-padd">
          <Row>
            <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
              <Client />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Partners;
