import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { gov_1, gov_2, gov_3, gov_4, gov_5 } from '../../../images/index';
import nImg from '../../../images/billers-logos/n.png';
import oImg from '../../../images/billers-logos/o.png';
import traImg from '../../../images/billers-logos/tra.png';
import uaepass from '../../../images/billers-logos/uaepass.png';
import adpc from '../../../images/billers-logos/adpc.jpg';
import '../partner.scss';

class Government extends Component {
  
  render() {
    return (
      <div className="inner-tab-data">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-5">
            <h4 className="head-medium-normal u-case mb-0"> GOVERNMENT </h4>
            <p className="content">Government Operations Made Simple</p>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Image src={adpc} style={{maxHeight: '80px'}} alt="gov 1" fluid className="gov-one mr-5"/>
              <Image src={nImg} style={{maxHeight: '80px'}}alt="gov 2" fluid className="gov-two mr-5"/>
              <Image src={traImg} style={{maxHeight: '80px'}} balt="gov 3" fluid className="gov-three mr-5"/>
              <Image src={uaepass} style={{maxHeight: '80px'}} alt="gov 4" fluid className="gov-four mr-5"/>
              <Image src={oImg} style={{maxHeight: '80px'}} alt="gov 5" fluid className="gov-five mr-5"/>       
            </div>

            <p className="content">
            MBME Pay is partnering with the UAE government to ensure all governmental processes run as fast, effectively, and convenient as possible. With innovative solutions and collaboration, our aim is to create a prosperous and sustainable future for the UAE. 
            </p>
            <p className="content">
            We do this through long-term planning, creative initiatives, and pioneering projects. This not only improves general operations, but also streamlines turnaround times for requests, projects, and overall efficiency. 
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Government;
