import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Nav, Navbar, Button, Row, Col, Image } from 'react-bootstrap'
import { logoImg, mob_hamburger, facebook, linkedin, instagram, bell, watsapp } from "../../images/index";
import './common-layout.scss';
import { withRouter } from 'react-router-dom';
import API from '../../api';


class Header extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            check: false,
            urlParams: '',
            mobilemenu: false
        };
        this.logout = this.logout.bind(this);
        this.mobileMenuOpen = this.mobileMenuOpen.bind(this);
        this.mobileMenuClose = this.mobileMenuClose.bind(this);
    };

    componentDidMount() {
        window.addEventListener('load', () => this.handleLoad());
    }

    mobileMenuOpen() {
        this.setState({
            mobilemenu: true,
        });
    }
    mobileMenuClose() {
        this.setState({
            mobilemenu: false,
        });
    }

    logout() {
        const USER_TOKEN = localStorage.getItem('usertoken');
        const AuthStr = 'Bearer '.concat(USER_TOKEN);
        const data = {}
        API.post('/admin/logout', data, { headers: { Authorization: AuthStr } })
            .then(response => {
                localStorage.clear();
                this.props.history.push("/admin-login");
            }).catch((error) => {
                console.log('error ' + error);
            });
    }

    handleLoad() {
        if (this.props.location.pathname === '/') {
            this.setState({
                check: false,
            });
        } else {
            this.setState({
                check: true,
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname !== '/')
                this.setState({
                    check: true,
                });
            if (this.props.location.pathname === '/')
                this.setState({
                    check: false,
                });
        }
    }

    render() {

        const currentPage = this.props.location.pathname;
        let header;
        if (currentPage === '/admin/change/password' || currentPage === '/admin/newsletter' || currentPage === '/admin/contact' || currentPage === '/admin/partner' || currentPage === '/admin/retailer' || currentPage === '/newsletter') {
            header = <Navbar className="no-padd" sticky="top" fixed="top" expand="lg">
                <Navbar.Brand href="#">
                    <div className="logo-img c-pointer"><img alt="mbme-logo" src={logoImg} /> </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="menu">
                        <NavLink to="/admin/partner">
                            Partners
                        </NavLink>
                        <NavLink to="/admin/retailer">
                            Retailers
                        </NavLink>
                        <NavLink to="/admin/contact">
                            Contacts
                        </NavLink>
                        <NavLink to="/admin/newsletter">
                            Newsletters
                        </NavLink>

                        <NavLink to="/admin/change/password">
                            Change Password
                        </NavLink>

                        <Button type="submit" className="mbme-menu-btn" onClick={this.logout}>Logout</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        } else {
            header = <div className="head-wrap-er" >
                <NavLink exact={true} to="/">
                <>
               <div className="logo">
                    <img src={logoImg} className="logo" />
                </div>
                </>
                </NavLink>
                <div className="menu-block" className={this.state.mobilemenu === true ? "menuopen" : "menuclose"}>
                    <div className="hamburgen hidd-larg">
                        <img className="hamb" src={mob_hamburger} alt="mobhamburgen" onClick={this.mobileMenuOpen} />
                    </div>
                    <div className="menuitems">
                        <div className="closemenu hidd-larg ">
                            <span className="close" onClick={this.mobileMenuClose}></span>
                        </div>
                        <div className="menu-list">
                            <Nav className="menu">
                                <NavLink exact={true} to="/" onClick={this.mobileMenuClose}>
                                    Home
                                    </NavLink>
                                    
                                <NavLink to="/solutions" onClick={this.mobileMenuClose}>
                                    Solutions
                                </NavLink>
                                <NavLink to="/partners" onClick={this.mobileMenuClose}>
                                    For Partners
                                </NavLink>
                                <NavLink to="/retailers" onClick={this.mobileMenuClose}>
                                    For Retailers
                                </NavLink>
                                <NavLink to="/about-us" onClick={this.mobileMenuClose} className="hidd-larg">
                                    About Us
                                </NavLink>
                                <NavLink to="/careers" onClick={this.mobileMenuClose} className="hidd-larg">
                                    Careers
                                </NavLink>
                                <NavLink  to="/contact-us" onClick={this.mobileMenuClose} className="hidd-larg">
                                    Contact Us
                                </NavLink>
                                {/* Old  <Button type="submit" className="mbme-menu-btn" onClick={event => window.open('https://preprod.mbme.org/auth?mode=signup', '_blank')}>Register</Button> */}
                                {/* <NavLink  to="/coming-soon" ><Button type="button" className="mbme-menu-btn" >Register </Button></NavLink> */}
                            </Nav>
                        </div>
                        <div className="hidd-larg">
                            <ul className="socialmedia">
                                <li>
                                    <a href="https://www.facebook.com/MBMEPAY.AE">
                                        <Image src={facebook} alt="facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mbmepayae/">
                                        <Image src={instagram} alt="instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/mbmepay/">
                                        <Image src={linkedin} alt="linkedin" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/971563993699">
                                        <Image src={watsapp} alt="watsapp" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.snapchat.com/add/mbmepayae">
                                        <Image src={bell} alt="bell" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mob-foot hidd-larg">

                    </div>
                </div>
            </div>
        }

        return (
            <div className={this.state.check ? 'otherpage' : 'Homepage'}>
                <Container className="no-padd">
                    <Row>
                        <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                            {header}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default withRouter(props => <Header {...props} />);;