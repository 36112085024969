import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import {
  category_11,
  category_12,
  category_13,
  category_14,
  category_11_white,
  category_12_white,
  category_13_white,
  category_14_white,
  pay_partner, category_7, category_7_white, options_partner, cash_partner, category_1, category_1_white
} from '../../../images/index';
import '../retailer.scss';

class Benifit extends Component {

  render() {
    return (
      <div className="tabData self-service">
        <div className="each-block">
          <h4 className="head-medium-normal">YOUR BUSINESS WILL BENEFIT FROM</h4>
          <Row className="mt-3 data-card">
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={cash_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal">BETTER CASH FLOW</h4>
                  <p className="content m-0">Get paid faster, with cleared funds from bill payments deposited to your account on the next day. Extend your business services without opening a new location.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={options_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">More choice for your customers</h4>
                  <p className="content m-0">Give your customers a wider choice of payment options and increase their convenience with UAE’s most popular payment service with over 1,000+ touch points, available also at remote areas where payment channels are limited.</p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="card-block my-3">
              <div className="ech-item position-relative">
                <div className="align-data">
                  <Image src={pay_partner} fluid alt="payment" className="ico-img" />
                  <h4 className="head-medium-normal capital">More efficient cash collection</h4>
                  <p className="content m-0">Reduce the time spent chasing bills, freeing you up to concentrate on core business activities.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="pay-trans">
          <h4 className="head-medium-normal">PAYMENT TRANSACTIONS</h4>
          <Row className="mt-3 data-card">
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_1} alt="category_1_white" className="img-black" fluid />
                    <Image src={category_1_white} alt="category_1_white" className="img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">Shopping Mall</p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_7} alt="category_1_white" className="img-black" fluid />
                    <Image src={category_7_white} alt="category_1_white" className="img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">Government</p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_11} alt="category_1_white" className="img-black" fluid />
                    <Image src={category_11_white} alt="category_1_white" className="img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">AirTime</p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_12} alt="category_1_white" className="img-black" fluid />
                    <Image src={category_12_white} alt="category_1_white" className="img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">Money & Transfer</p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_13} alt="category_1_white" className="img-black" fluid />
                    <Image src={category_13_white} alt="category_1_white" className="img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">Retail</p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6} className="card-block my-3">
              <div className="each-block position-relative">
                <div className="item">
                  <div className="img-blk">
                    <Image src={category_14} alt="category_1_white" className=" other-icon img-black" fluid />
                    <Image src={category_14_white} alt="category_1_white" className="other-icon img-white" fluid />
                  </div>
                  <p className="content m-0 text-center">Other</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default Benifit;
