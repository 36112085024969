import React, { Component } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './common-layout.scss';
import queryString from 'query-string';
import API from '../../api';
import { logoImg, lf_dark_triangle, smile, sad, lf_light_triangle, rg_dark_triange, rg_light_triangle } from '../../images/index';

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            responseMessage: ''
        }
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        if (params.token) {
            let data = params;
            API.post(`/verifySubscription`, data).then(res => {
                this.setState({
                    status: true,
                    responseMessage: res.data.message,
                });
            }, (error) => {
                console.log(error);

                this.setState({
                    status: false,
                    responseMessage: 'Please try again later!',
                });
            });
        } else {
            this.setState({
                status: false,
                responseMessage: 'Please try again later!',
            });
        }
    }

    render() {
        return (
            <Container className="field-padd">
                <Row>

                    <div>
                        <Image fluid src={lf_dark_triangle} className="lf_dark_triangle" alt="right triangle 1" />
                        <Image fluid src={lf_light_triangle} className="lf_light_triangle" alt="right triangle 2" />
                        <Image fluid src={rg_dark_triange} className="rg_dark_triange" alt="right triangle 1" />
                        <Image fluid src={rg_light_triangle} className="rg_light_triangle" alt="right triangle 2" />
                    </div>

                    <Col md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                        <Image fluid src={logoImg} className="mx-auto d-block" />
                        <div className="confirmPage">
                            <Image fluid src={this.state.status ? smile : sad} className="mx-auto d-block icon-feeling" />
                            <div className="news-message">
                                {this.state.responseMessage} <br></br>
                                <Button type="submit" className="mbme-btn button-big confirmButton" onClick={event => window.open('https://mbmepay.ae', '_blank')}> Go to Site </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Confirm;
